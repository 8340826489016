import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Form,
  Input,
  Select,
  Radio,
  Button,
  Table,
  Tooltip,
} from "antd";
import ReactExport from "react-export-excel";
import { MSJOK, MSJERROR } from "../Alerts";
import {
  GetDigitadorParam,
  GetDigitadorExport,
  GetDigitaciones,
} from "../entity/helpers";
import styles from "./css/form.module.css";
import {
  SearchOutlined,
  DownloadOutlined,
  ReloadOutlined,
} from "@ant-design/icons";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Reporte = () => {
  const columns = [
    {
      title: "Nombres",
      dataIndex: "nombre_digitador",
      key: "nombre_digitador",
    },
    {
      title: "# Formularios",
      dataIndex: "cantidad_formularios",
      key: "cantidad_formularios",
    },
  ];
  const dateToday = new Date();
  const [form, setForm] = useState(null);
  const [excel, setExcel] = useState([]);
  const [data, setData] = useState([]);
  const [fecha, setFecha] = useState("");

  const handleSearch = () => {
    if (form) {
      if (form.codigo) {
        GetDigitadorParam(form.codigo).then((response) => {
          if (response[0]) {
            setExcel(response);
            MSJOK("Informacion cargada y lista para procesar.");
          } else {
            setExcel([]);
            setForm(null);
            MSJERROR("No existe formulario con codigo: " + form.codigo);
          }
        });
      }
    }
  };

  const handleGetExportacion = () => {
    GetDigitadorExport().then((response) => {
      if (response[0]) {
        setExcel(response);
        MSJOK("Informacion cargada y lista para procesar.");
      } else {
        setExcel([]);
        setForm(null);
        MSJERROR("No existe formulario referente a: " + form.codigo);
      }
    });
  };

  const handleDigitaciones = () => {
    GetDigitaciones(fecha).then((response) => {
      if (response[0]) {
        setData(response);
      } else {
        setData([]);
      }
    });
  };

  useEffect(() => {
    handleDigitaciones();
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({
      ...form,
      [id]: value,
    });
  };

  const inputFecha = (e) => {
    const { id, value } = e.target;
    setFecha(value);
  };

  return (
    <>
      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="EXPORTACION"
        >
          <h1>Ingrese nombre o documento de identificacion del digitador:</h1>
          <Form name="basic1" initialValues={{ remember: true }}>
            <Form.Item label="Info Digitador:" name="codigo">
              <Input
                id="codigo"
                value={form ? (form.codigo ? form.codigo : "") : ""}
                style={{ width: "25%" }}
                onChange={handleChange}
              />
              <Button
                type="primary"
                disabled={form ? (form.codigo ? false : true) : true}
                shape="circle"
                onClick={handleSearch}
                icon={<SearchOutlined />}
              />
              {" ó "}
              <Button
                variant="contained"
                color="secondary"
                shape="circle"
                onClick={handleGetExportacion}
              >
                Todo
              </Button>
            </Form.Item>
          </Form>
          <ExcelFile
            element={
              <Button
                variant="contained"
                disabled={excel.length > 0 ? false : true}
                icon={<DownloadOutlined />}
                color="secondary"
                size="large"
              >
                Descargar
              </Button>
            }
            filename={"digitador-" + dateToday.toDateString()}
          >
            <ExcelSheet data={excel} name={"plantilla"}>

              <ExcelColumn label="N FORMULARIO" value="codigo_formulario" />

              <ExcelColumn label="FECHA CREACION" value="fecha_creacion" />

              <ExcelColumn label="NOMBRE" value="nombre_digitador" />
            </ExcelSheet>
          </ExcelFile>
        </Card>
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="Digitadores"
        >
          <Form.Item label="Filtrar por fecha">
            <input
              className="ant-input"
              style={{ width: "25%" }}
              value={fecha ? fecha : ""}
              id="fecha"
              type="date"
              onChange={(e) => inputFecha(e)}
            />
            <Button type="primary" onClick={() => setFecha("")}>
              Limpiar fecha
            </Button>
          </Form.Item>

          <Table dataSource={data} columns={columns} />
        </Card>
      </Row>
    </>
  );
};

export default Reporte;
