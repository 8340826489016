import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Switch,
  Select,
  DatePicker,
  Radio,
  Button,
} from "antd";
import { SaveOutlined, SearchOutlined, EditOutlined } from "@ant-design/icons";
import { MSJOK, MSJERROR } from "../Alerts";
import {
  SaveVulnerabilidad,
  GetVulnerabilidadCodigo,
  UpdateVulnerabilidad,
} from "../entity/helpers";
import styles from "./css/form.module.css";
import {
  comunidadLGBTs,
  religiones,
  comunidad_culturales,
} from "../helpers/helperforms";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const Vulnerabilidad = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  var personas = cookies.get("vulnerabilidad")
    ? cookies.get("vulnerabilidad")
    : [];
  const [form, setForm] = useState(formulario);
  const [comunidad_lbgt, setComunidadLBGT] = useState(-1);
  const [religion_bool, setReligionBool] = useState(-1);
  const [religion, setReligion] = useState(religiones[0]);
  const [otra_religion, setOtraReligion] = useState("");
  const [edicion, setEdicion] = useState(false);
  const [n_orden_search, setNOrdenSearch] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  function handleSave() {
    SaveVulnerabilidad(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
        personas = personas.filter(function (value, index, arr) {
          return value.id != form.n_orden;
        });
        cookies.remove("vulnerabilidad");
        cookies.set("vulnerabilidad", personas);
        cookies.set("migracion", cookies.get("personas"));
        setForm(formulario);
        setComunidadLBGT(-1);
        setReligionBool(-1);
        setReligion(religiones[0]);
        setOtraReligion("");
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleUpdate = () => {
    UpdateVulnerabilidad(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setComunidadLBGT(-1);
        setReligionBool(-1);
        setReligion(religiones[0]);
        setOtraReligion("");
        setNOrdenSearch(null);
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setCodigo(null);
    setEdicion(false);
    setNOrdenSearch(null);
    setComunidadLBGT(-1);
    setReligionBool(-1);
    setReligion(religiones[0]);
    setOtraReligion("");
    setForm(formulario);
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleOrden = (e) => {
    const { id, value } = e.target;
    setNOrdenSearch(value);
  };

  const handleSearch = () => {
    if (codigo && n_orden_search) {
      GetVulnerabilidadCodigo(codigo, n_orden_search).then((response) => {
        if (response[0]) {
          setForm(response[0]);
          setEdicion(true);
          if (response[0].comunidad_lgbt) {
            setComunidadLBGT(1);
          }
          if (response[0].religion) {
            if (parseInt(response[0].religion) === 0) {
              setReligionBool(0);
            } else {
              setReligionBool(1);
            }

            if (religiones.indexOf(response[0].religion) == -1) {
              setReligion(religiones[religiones.length - 1]);
              setOtraReligion(response[0].religion);
            } else {
              setReligion(response[0].religion);
            }
          }
        } else {
          setCodigo(null);
          setNOrdenSearch(null);
          setForm(formulario);
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  const radioComunidadLBGT = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      comunidad_lbgt: value,
    });
    setComunidadLBGT(value);
  };

  const radioReligionBool = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      religion: value,
    });
    setReligionBool(value);
  };

  const selectReligion = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      religion: value,
    });
    setReligion(value);
  };

  const inputReligion = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      religion: value,
    });
    setOtraReligion(value);
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (name === "comunidad_lgbt") {
      if (value == comunidad_lbgt) {
        setComunidadLBGT(-1);
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
    if (name === "religion") {
      if (value == religion_bool) {
        setReligionBool(-1);
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <>
      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title={
            <div>
              E. VULNERABILIDAD
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por: </label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Input
                    id="n_orden"
                    placeholder={"# Orden"}
                    onChange={handleOrden}
                    value={n_orden_search ? n_orden_search : ""}
                    style={{ width: "10%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
        >
          <h1>
            Usted Pertenece o hace parte de una de las siguientes comunidades
          </h1>
          <Row justify="center">
            <Col span={10}>
              <Form initialValues={{ remember: true }}>
                {!editBool ? (
                  <Form.Item label="Numero de Orden">
                    <select
                      className="ant-input"
                      name="n_orden"
                      value={form ? (form.n_orden ? form.n_orden : "") : ""}
                      onChange={handleChange}
                      placeholder="Seleccione una opción"
                      defaultValue={0}
                    >
                      {personas.map((num) => {
                        return <option value={num.id}>{num.value}</option>;
                      })}
                    </select>
                  </Form.Item>
                ) : null}

                <Form.Item label="1. Comundidad LGBTTTIQ">
                  <Radio.Group
                    name="comunidad_lgbt"
                    value={comunidad_lbgt}
                    onChange={radioComunidadLBGT}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {comunidad_lbgt > 0 ? (
                  <Form.Item>
                    <select
                      className="ant-input"
                      name="comunidad_lgbt"
                      value={
                        form
                          ? form.comunidad_lgbt
                            ? form.comunidad_lgbt
                            : ""
                          : ""
                      }
                      onChange={handleChange}
                      placeholder="Seleccione una opción"
                    >
                      {comunidadLGBTs.map((comunidadLGBT) => {
                        return (
                          <option value={comunidadLGBT}>{comunidadLGBT}</option>
                        );
                      })}
                    </select>
                  </Form.Item>
                ) : null}
              </Form>
            </Col>
            <Col offset={2} span={12}>
              <Form initialValues={{ remember: true }}>
                <Form.Item label="2. Comunidad Religiosa">
                  <Radio.Group
                    name="religion"
                    value={religion_bool >= 0 ? religion_bool : ""}
                    onChange={radioReligionBool}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {religion_bool > 0 ? (
                  <Form.Item>
                    <select
                      className="ant-input"
                      name="religion"
                      value={religion ? religion : ""}
                      onChange={selectReligion}
                      placeholder="Seleccione una opción"
                    >
                      {religiones.map((religion) => {
                        return <option value={religion}>{religion}</option>;
                      })}
                    </select>
                    {religion === religiones[religiones.length - 1] ||
                    religiones.indexOf(religion) == -1 ? (
                      <label>
                        Otra religión:{" "}
                        <Input
                          name="otro_religion"
                          value={otra_religion ? otra_religion : ""}
                          onChange={inputReligion}
                        />
                      </label>
                    ) : null}
                  </Form.Item>
                ) : null}

                <Form.Item label="3. Comunidad Cultural">
                  <Radio.Group
                    name="comunidad_cultural"
                    value={
                      form
                        ? parseInt(form.comunidad_cultural) >= 0
                          ? parseInt(form.comunidad_cultural)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
        {form ? (
          form.comunidad_cultural && parseInt(form.comunidad_cultural) > 0 ? (
            <Card
              className={styles.cardDashboardgeneral}
              size="large"
              title="Comunidades Culturales"
            >
              <Row justify="center">
                <Col span={10}>
                  <Form initialValues={{ remember: true }}>
                    <Form.Item label="Grupo de Baile">
                      <Radio.Group
                        name="grupo_baile"
                        value={
                          form
                            ? parseInt(form.grupo_baile) >= 0
                              ? parseInt(form.grupo_baile)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form>
                </Col>
                <Col offset={2} span={12}>
                  <Form initialValues={{ remember: true }}>
                    <Form.Item label="Grupo de Teatro">
                      <Radio.Group
                        name="grupo_teatro"
                        value={
                          form
                            ? parseInt(form.grupo_teatro) >= 0
                              ? parseInt(form.grupo_teatro)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Card>
          ) : null
        ) : null}
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="Usted padece de alguna de estas enfermedades o se encuentra en algunas de las siguientes condiciones?"
        >
          <Row justify="center">
            <Col span={10}>
              <Form
                // layout="vertical"
                name="basic1"
                initialValues={{ remember: true }}
                //   onFinish={onFinish}
                //   onFinishFailed={onFinishFailed}
              >
                <Form.Item label="4. Desplazado por violencia">
                  <Radio.Group
                    name="dezplazado"
                    value={
                      form
                        ? parseInt(form.dezplazado) >= 0
                          ? parseInt(form.dezplazado)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item label="5. Victima del Estado">
                  <Radio.Group
                    name="victima_estado"
                    value={
                      form
                        ? parseInt(form.victima_estado) >= 0
                          ? parseInt(form.victima_estado)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
              <Form.Item label="6. Minorias Politicas">
                <Radio.Group
                  name="minorias_politicas"
                  value={
                    form
                      ? parseInt(form.minorias_politicas) >= 0
                        ? parseInt(form.minorias_politicas)
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="7. Habitante de la Calle">
                <Radio.Group
                  name="habitante_calle"
                  value={
                    form
                      ? parseInt(form.habitante_calle) >= 0
                        ? parseInt(form.habitante_calle)
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="8. Mujeres Embarazadas">
                <Radio.Group
                  name="mujeres_embarazadas"
                  value={
                    form
                      ? parseInt(form.mujeres_embarazadas) >= 0
                        ? parseInt(form.mujeres_embarazadas)
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col offset={2} span={12}>
              <Form.Item label="9. Mujer y/o Hombre Cabeza de Hogar">
                <Radio.Group
                  name="cabeza_hogar"
                  value={
                    form
                      ? parseInt(form.cabeza_hogar) >= 0
                        ? parseInt(form.cabeza_hogar)
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="10. Personas con capacidades Cognitivas Especiales">
                <Radio.Group
                  name="personas_especiales"
                  value={
                    form
                      ? parseInt(form.personas_especiales) >= 0
                        ? parseInt(form.personas_especiales)
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="11. Discapacidad Congenita">
                <Radio.Group
                  name="discapacidad_cognitiva"
                  value={
                    form
                      ? parseInt(form.discapacidad_cognitiva) >= 0
                        ? parseInt(form.discapacidad_cognitiva)
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="12. Consumo de Sustancias Sicoaptivas">
                <Radio.Group
                  name="sustancias_sicoactivas"
                  value={
                    form
                      ? parseInt(form.sustancias_sicoactivas) >= 0
                        ? parseInt(form.sustancias_sicoactivas)
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="Usted padece de alguna de estas enfermedades o se encuentra en algunas de las siguientes condiciones?"
        >
          <Row justify="center">
            <Col span={15}>
              <Form name="basic1" initialValues={{ remember: true }}>
                <Form.Item label="13. Enfermo VIH">
                  <Radio.Group
                    name="vih"
                    value={
                      form
                        ? parseInt(form.vih) >= 0
                          ? parseInt(form.vih)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
            <Col offset={2} span={12}>
              <Form name="basic1" initialValues={{ remember: true }}>
                <Form.Item>
                  <label>
                    13.1. Actulamente recibe tratamiento médico por este
                    padecimiento?
                  </label>
                  <br></br>
                  <Radio.Group
                    name="tratamiento_vih"
                    onChange={handleChange}
                    value={
                      form
                        ? parseInt(form.vih) == 0
                        ? ""
                        : (parseInt(form.tratamiento_vih) >= 0
                          ? parseInt(form.tratamiento_vih)
                          : "")
                        : ""
                    }
                    disabled={form ? (parseInt(form.vih) ? false : true) : true}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    13.2. La Atención la recibe a través de qué regimen
                  </label>
                  <Radio.Group
                    name="regimen_vih"
                    onChange={handleChange}
                    value={
                      form
                        ? parseInt(form.vih) == 0
                          ? ""
                          : form.regimen_vih
                          ? form.regimen_vih
                          : ""
                        : ""
                    }
                    disabled={form ? (parseInt(form.vih) ? false : true) : true}
                  >
                    <Radio onClick={handleClick} value={"Contributivo"}>
                      Contributivo
                    </Radio>
                    <Radio onClick={handleClick} value={"Subsidiado"}>
                      Subsidiado
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    13.3.Recibe actualmente tratamiento antiretroviral por parte
                    de la EPS-S
                  </label>
                  <Radio.Group
                    name="vih_antiretroviral"
                    onChange={handleChange}
                    value={
                      form
                        ? parseInt(form.vih) == 0
                          ? ""
                          : parseInt(form.vih_antiretroviral) >= 0
                          ? parseInt(form.vih_antiretroviral)
                          : ""
                        : ""
                    }
                    disabled={form ? (parseInt(form.vih) ? false : true) : true}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="Usted padece de alguna de estas enfermedades o se encuentra en algunas de las siguientes condiciones?"
        >
          <Row justify="center">
            <Col span={15}>
              <Form name="basic1" initialValues={{ remember: true }}>
                <Form.Item label="14. Enfermos de Cancer">
                  <Radio.Group
                    name="cancer"
                    value={
                      form
                        ? parseInt(form.cancer) >= 0
                          ? parseInt(form.cancer)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
            <Col offset={2} span={12}>
              <Form initialValues={{ remember: true }}>
                <Form.Item>
                  <label>
                    14.1. Actulamente recibe tratamiento médico por este
                    padecimiento?
                  </label>
                  <br></br>
                  <Radio.Group
                    name="tratamiento_cancer"
                    onChange={handleChange}
                    value={
                      form
                        ? parseInt(form.cancer) == 0
                          ? ""
                          : parseInt(form.tratamiento_cancer) >= 0
                          ? parseInt(form.tratamiento_cancer)
                          : ""
                        : ""
                    }
                    disabled={
                      form ? (parseInt(form.cancer) ? false : true) : true
                    }
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    14.2. La Atención la recibe a través de qué regimen
                  </label>

                  <Radio.Group
                    name="regimen_cancer"
                    onChange={handleChange}
                    value={
                      form
                        ? parseInt(form.cancer) == 0
                          ? ""
                          : form.regimen_cancer
                          ? form.regimen_cancer
                          : ""
                        : ""
                    }
                    disabled={
                      form ? (parseInt(form.cancer) ? false : true) : true
                    }
                  >
                    <Radio onClick={handleClick} value={"Contributivo"}>
                      Contributivo
                    </Radio>
                    <Radio onClick={handleClick} value={"Subsidiado"}>
                      Subsidiado
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    14.3.Recibe actualmente tratamiento oncológico por parte de
                    la EPS-S
                  </label>
                  <Radio.Group
                    name="cancer_antiretroviral"
                    value={
                      form
                        ? parseInt(form.cancer) == 0
                          ? ""
                          : parseInt(form.cancer_antiretroviral) >= 0
                          ? parseInt(form.cancer_antiretroviral)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                    disabled={
                      form ? (parseInt(form.cancer) ? false : true) : true
                    }
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="Usted padece de alguna de estas enfermedades o se encuentra en algunas de las siguientes condiciones?"
        >
          <Row justify="center">
            <Col span={10}>
              <Form initialValues={{ remember: true }}>
                <Form.Item>
                  <label>15. Persona de Talla Baja o Acrondoplacia: </label>
                  <br></br>
                  <Radio.Group
                    name="talla_baja"
                    value={
                      form
                        ? parseInt(form.talla_baja) >= 0
                          ? parseInt(form.talla_baja)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item label="16. Persona con discapacidad Visual">
                  <Radio.Group
                    name="discapacidad_visual"
                    value={
                      form
                        ? parseInt(form.discapacidad_visual) >= 0
                          ? parseInt(form.discapacidad_visual)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
              <Form.Item label="17. Persona con discapacidad Auditiva">
                <Radio.Group
                  value={
                    form
                      ? parseInt(form.discapacidad_auditiva) >= 0
                        ? parseInt(form.discapacidad_auditiva)
                        : ""
                      : ""
                  }
                  name="discapacidad_auditiva"
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col offset={2} span={12}>
              <Form.Item label="18. Trabajadoras Sexuales">
                <Radio.Group
                  value={
                    form
                      ? parseInt(form.trabajadoras_sexuales) >= 0
                        ? parseInt(form.trabajadoras_sexuales)
                        : ""
                      : ""
                  }
                  name="trabajadoras_sexuales"
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item label="19. Habitantes de Zonas Marginales">
                <Radio.Group
                  value={
                    form
                      ? parseInt(form.zonas_marginales) >= 0
                        ? parseInt(form.zonas_marginales)
                        : ""
                      : ""
                  }
                  name="zonas_marginales"
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="danger"
                  disabled={
                    form ? (parseInt(form.n_orden) > 0 ? false : true) : true
                  }
                  icon={<SaveOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Vulnerabilidad;
