import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Radio,
  Button,
  Switch,
} from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import { SaveOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import {
  SaveOcupacion,
  UpdateOcupacion,
  GetOcupacionCodigo,
} from "../entity/helpers";
import styles from "./css/form.module.css";
import {
  actividades,
  actividades_laborales,
  tipo_contratos,
  tipo_contratos2,
  prestaciones,
  fondos_pensiones,
  afectaciones_covid,
  reactivaciones_economicas,
  sectores_economicos_covid,
} from "../helpers/helperforms";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const Ocupacion = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  var personas = cookies.get("ocupacion")
    ? cookies.get("ocupacion").filter(function (value, index, arr) {
        return value.edad >= 15 || value.id == 0;
      })
    : [];
  var desocupados = cookies.get("desocupados")
    ? cookies.get("desocupados").filter(function (value, index, arr) {
        return value.edad >= 15 || value.id == 0;
      })
    : [];
  const [form, setForm] = useState(formulario);
  const [actividad_laboral, setActividadLaboral] = useState(
    actividades_laborales[0]
  );
  const [actividad, setActividad] = useState(actividades[0]);
  const [tipo_contrato, setTipoContrato] = useState(tipo_contratos[0]);
  const [termino_contrato, setTerminoContrato] = useState(tipo_contratos2[0]);
  const [edicion, setEdicion] = useState(false);
  const [n_orden_search, setNOrdenSearch] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  function handleSave() {
    SaveOcupacion(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
        personas = personas.filter(function (value, index, arr) {
          return value.id != form.n_orden;
        });
        cookies.remove("ocupacion");
        cookies.set("ocupacion", personas);
        setForm(formulario);
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleUpdate = () => {
    UpdateOcupacion(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setNOrdenSearch(null);
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setCodigo(null);
    setEdicion(false);
    setNOrdenSearch(null);
    setForm(formulario);
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleOrden = (e) => {
    const { id, value } = e.target;
    setNOrdenSearch(value);
  };

  const handleSearch = () => {
    if (codigo && n_orden_search) {
      GetOcupacionCodigo(codigo, n_orden_search).then((response) => {
        if (response[0]) {
          setForm(response[0]);
          setEdicion(true);
          setActividad(response[0].actividad);
          setActividadLaboral(response[0].actividad_laboral);
          if (
            actividades.indexOf(response[0].actividad) == -1 &&
            response[0].actividad != null
          ) {
            setActividad(actividades[actividades.length - 1]);
          }
          if (
            actividades_laborales.indexOf(response[0].actividad_laboral) ==
              -1 &&
            response[0].actividad_laboral != null
          ) {
            setActividadLaboral(actividades_laborales[actividades.length - 1]);
          }
          if (response[0].tipo_contrato != null) {
            setTipoContrato(response[0].tipo_contrato.split(": ")[0]);
            if (response[0].tipo_contrato.split(": ").length > 0) {
              setTerminoContrato(response[0].tipo_contrato.split(": ")[1]);
            }
          }
        } else {
          setCodigo(null);
          setNOrdenSearch(null);
          setForm(formulario);
          setActividad(actividades[0]);
          setActividadLaboral(actividades_laborales[0]);
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  const inputActividad = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      actividad: value,
    });
  };

  const selectTipoContratos = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      tipo_contrato: value,
    });
    setTipoContrato(value);
  };

  const inputTipoContratos = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      tipo_contrato: form.tipo_contrato + ": " + value,
    });
    setTerminoContrato(value);
  };

  const selectActividad = (e) => {
    const { name, value } = e.target;
    const objIndex = desocupados.findIndex((obj) => obj.id == form.n_orden);
    if (objIndex != -1 && !editBool) {
      desocupados[objIndex].actividad = value;
      cookies.remove("desocupados");
      cookies.set("desocupados", desocupados);
    }
    setForm({
      ...form,
      actividad: value,
    });
    setActividad(value);
  };

  const inputActividadLaboral = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      actividad_laboral: value,
    });
  };

  const selectActividadLaboral = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      actividad_laboral: value,
    });
    setActividadLaboral(value);
  };

  const selectFondoPension = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      fondo_pension: value,
    });
  };

  const selectSectoresEconomicos = (value) => {
    setForm({
      ...form,
      sectores_economicos_covid: value.join(),
    });
  };

  const selectPrestaciones = (value) => {
    setForm({
      ...form,
      prestaciones: value.join(),
    });
  };

  const selectReactivacion = (value) => {
    setForm({
      ...form,
      reactivacion_economica: value.join(),
    });
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "otra_actividad") {
      const objIndex = desocupados.findIndex((obj) => obj.id == form.n_orden);
      if (objIndex != -1 && !editBool) {
        desocupados[objIndex].otra_actividad = value;
        cookies.remove("desocupados");
        cookies.set("desocupados", desocupados);
      }
    }
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <>
      <Row justify="center">
        <h1></h1>
      </Row>

      <Row gutter={24} justify="center">
        <Card
          title={
            <div>
              I. FUERZA DE TRABAJO (Para todas las personas de 15 años y más)
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por: </label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Input
                    id="n_orden"
                    placeholder={"# Orden"}
                    onChange={handleOrden}
                    value={n_orden_search ? n_orden_search : ""}
                    style={{ width: "10%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
          className={styles.cardDashboardgeneral}
          size="large"
        >
          <Row justify="center">
            <Col span={18}>
              <Form initialValues={{ remember: true }}>
                {!editBool ? (
                  <Form.Item label="">
                    <label>Numero de Orden: </label>
                    <select
                      className="ant-input"
                      name="n_orden"
                      style={{ width: "40%" }}
                      value={form ? (form.n_orden ? form.n_orden : "") : ""}
                      onChange={handleChange}
                      placeholder="Seleccione una opción"
                      defaultValue={0}
                    >
                      {personas.map((num) => {
                        return <option value={num.id}>{num.value}</option>;
                      })}
                    </select>
                  </Form.Item>
                ) : null}
                <Form.Item>
                  <label>
                    1. ¿En que actividad ocupa la mayor parte de su tiempo ?
                  </label>
                  <select
                    className="ant-input"
                    name="actividad"
                    value={actividad ? actividad : ""}
                    onChange={selectActividad}
                    disabled={form ? (form.n_orden ? false : true) : true}
                    placeholder="Seleccione una opción"
                  >
                    {actividades.map((actividad) => {
                      return <option value={actividad}>{actividad}</option>;
                    })}
                  </select>
                  {actividad === actividades[actividades.length - 1] ? (
                    <label>
                      Otra actividad:{" "}
                      <Input name="otro_actividad" onChange={inputActividad} />
                    </label>
                  ) : null}
                </Form.Item>
                <Form.Item>
                  <label>
                    2. Además de lo anterior, ¿realizó alguna actividad
                    remunerada?
                  </label>
                  <Radio.Group
                    name="otra_actividad"
                    value={
                      form
                        ? parseInt(form.otra_actividad) >= 0
                          ? parseInt(form.otra_actividad)
                          : ""
                        : ""
                    }
                    disabled={
                      form
                        ? form.actividad === actividades[1] ||
                          form.actividad === actividades[5]
                        : false
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {form ? (
                  ((form.actividad === actividades[2] ||
                    form.actividad === actividades[3] ||
                    form.actividad === actividades[4] ||
                    form.actividad === actividades[6]) &&
                    form.otra_actividad) ||
                  form.actividad === actividades[1] ? (
                    <>
                      <Form.Item>
                        <label>3. ¿Que hace en este trabajo?</label>
                        <Input
                          name="funcion"
                          value={form ? (form.funcion ? form.funcion : "") : ""}
                          onChange={handleChange}
                        />
                      </Form.Item>
                      <Form.Item>
                        <label>
                          4. ¿Cuál es el nombre de la empresa, negocio,
                          industria, oficina, firma o finca donde trabaja?
                        </label>
                        <Input
                          name="nombre_empresa"
                          value={
                            form
                              ? form.nombre_empresa
                                ? form.nombre_empresa
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        />
                      </Form.Item>
                      <Form.Item>
                        <label>5. Su actividad laboral es?</label>
                        <select
                          className="ant-input"
                          name="actividad_laboral"
                          value={actividad_laboral ? actividad_laboral : ""}
                          onChange={selectActividadLaboral}
                          placeholder="Seleccione una opción"
                        >
                          {actividades_laborales.map((actividad) => {
                            return (
                              <option value={actividad}>{actividad}</option>
                            );
                          })}
                        </select>
                        {actividad_laboral ===
                        actividades_laborales[
                          actividades_laborales.length - 1
                        ] ? (
                          <label>
                            Otro Actividad:{" "}
                            <Input
                              name="otro_actividad"
                              onChange={inputActividadLaboral}
                            />
                          </label>
                        ) : null}
                      </Form.Item>
                      <Form.Item>
                        <label>6. Tiene algún tipo de contrato </label>
                        <select
                          className="ant-input"
                          name="tipo_contrato"
                          value={tipo_contrato ? tipo_contrato : ""}
                          onChange={selectTipoContratos}
                          placeholder="Seleccione una opción"
                        >
                          {tipo_contratos.map((tipo_contrato) => {
                            return (
                              <option value={tipo_contrato}>
                                {tipo_contrato}
                              </option>
                            );
                          })}
                        </select>
                      </Form.Item>
                      {form ? (
                        form.tipo_contrato === tipo_contratos[2] ||
                        tipo_contratos.indexOf(form.tipo_contrato) == -1 ? (
                          <Form.Item>
                            <label>Que termino es: </label>
                            <select
                              className="ant-input"
                              name="tipo_contrato2"
                              value={termino_contrato ? termino_contrato : ""}
                              onChange={inputTipoContratos}
                              placeholder="Seleccione una opción"
                            >
                              {tipo_contratos2.map((tipo_contrato) => {
                                return (
                                  <option value={tipo_contrato}>
                                    {tipo_contrato}
                                  </option>
                                );
                              })}
                            </select>
                          </Form.Item>
                        ) : null
                      ) : null}
                      {form ? (
                        form.tipo_contrato !==
                        tipo_contratos[tipo_contratos.length - 1] ? (
                          <Form.Item>
                            <label>
                              7. Por su tipo de contrato recibe alguna de las
                              siguientes presentaciones
                            </label>
                            <Col offset={2}>
                              <Form.Item label="">
                                <label>a. Primas de Servicio: </label>
                                <Radio.Group
                                  name="primas_servicio"
                                  value={
                                    form
                                      ? parseInt(form.primas_servicio) >= 0
                                        ? parseInt(form.primas_servicio)
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>
                                    Si
                                  </Radio>
                                  <Radio onClick={handleClick} value={0}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item label="">
                                <label>b. Prima de Vacaciones: </label>
                                <Radio.Group
                                  name="prima_vacaciones"
                                  value={
                                    form
                                      ? parseInt(form.prima_vacaciones) >= 0
                                        ? parseInt(form.prima_vacaciones)
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>
                                    Si
                                  </Radio>
                                  <Radio onClick={handleClick} value={0}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item label="">
                                <label>c. Vacaciones Remuneradas: </label>
                                <Radio.Group
                                  name="vacaciones_remuneradas"
                                  value={
                                    form
                                      ? parseInt(form.vacaciones_remuneradas) >=
                                        0
                                        ? parseInt(form.vacaciones_remuneradas)
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>
                                    Si
                                  </Radio>
                                  <Radio onClick={handleClick} value={0}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item label="">
                                <label>d. Cesantias: </label>
                                <Radio.Group
                                  name="cesantias"
                                  value={
                                    form
                                      ? parseInt(form.cesantias) >= 0
                                        ? parseInt(form.cesantias)
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>
                                    Si
                                  </Radio>
                                  <Radio onClick={handleClick} value={0}>
                                    No
                                  </Radio>
                                </Radio.Group>
                              </Form.Item>
                            </Col>
                          </Form.Item>
                        ) : null
                      ) : null}

                      <Form.Item>
                        <label>
                          8. Cuantos son sus ingresos por la actividad laboral?
                        </label>
                        <Input
                          name="ingresos"
                          value={
                            form ? (form.ingresos ? form.ingresos : "") : ""
                          }
                          onChange={handleChange}
                          placeholder="$ Valor"
                        />
                      </Form.Item>
                    </>
                  ) : null
                ) : null}
                {/* </Form>
            </Col>
            <Col offset={2} span={12}>
              <Form
                // layout="vertical"
                initialValues={{ remember: true }}
                //   onFinish={onFinish}
                //   onFinishFailed={onFinishFailed}
              > */}
                {form ? (
                  ((form.actividad === actividades[2] ||
                    form.actividad === actividades[3] ||
                    form.actividad === actividades[4] ||
                    form.actividad === actividades[6]) &&
                    form.otra_actividad) ||
                  form.actividad === actividades[1] ? (
                    <>
                      <Form.Item>
                        <label>
                          9. Su trabajo se ha visto afectado negativamente como
                          consecuencia de la pandemia del Covid 19?
                        </label>
                        <Radio.Group
                          name="afectado_covid"
                          value={
                            form
                              ? parseInt(form.afectado_covid) >= 0
                                ? parseInt(form.afectado_covid)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>
                          10. Que tipo de Afectación sufrio su trabajo, empresa,
                          Negocio o finca, por causa de la Pandemía Covid. 19
                        </label>
                        {/* <select
                          className="ant-input"
                          name="tipo_afectacion_covid"
                          value={
                            form
                              ? form.tipo_afectacion_covid
                                ? form.tipo_afectacion_covid
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                          disabled={
                            form ? (form.afectado_covid ? false : true) : true
                          }
                          placeholder="Seleccione una opción"
                        >
                          {afectaciones_covid.map((afectacion_covid) => {
                            return (
                              <option value={afectacion_covid}>
                                {afectacion_covid}
                              </option>
                            );
                          })}
                        </select> */}
                        <Col offset={2}>
                          <Form.Item label="">
                            <label>
                              a. Se redujo su producción Laboral: {" "}
                            </label>
                            <Radio.Group
                              name="afectaciones_covid_reduccion_produccion"
                              value={
                                form
                                  ? parseInt(
                                      form.afectaciones_covid_reduccion_produccion
                                    ) >= 0
                                    ? parseInt(
                                        form.afectaciones_covid_reduccion_produccion
                                      )
                                    : ""
                                  : ""
                              }
                              onChange={handleChange}
                            >
                              <Radio onClick={handleClick} value={1}>
                                Si
                              </Radio>
                              <Radio onClick={handleClick} value={0}>
                                No
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item label="">
                            <label>
                              b. Se redujeron sus ganancias o ingresos económicos: {" "}
                            </label>
                            <Radio.Group
                              name="afectaciones_covid_reduccion_ganancias"
                              value={
                                form
                                  ? parseInt(
                                      form.afectaciones_covid_reduccion_ganancias
                                    ) >= 0
                                    ? parseInt(
                                        form.afectaciones_covid_reduccion_ganancias
                                      )
                                    : ""
                                  : ""
                              }
                              onChange={handleChange}
                            >
                              <Radio onClick={handleClick} value={1}>
                                Si
                              </Radio>
                              <Radio onClick={handleClick} value={0}>
                                No
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item label="">
                            <label>c. Tuvo que despedir de su Negocio o empresa: {" "}</label>
                            <Radio.Group
                              name="afectaciones_covid_despidio_negocio"
                              value={
                                form
                                  ? parseInt(
                                      form.afectaciones_covid_despidio_negocio
                                    ) >= 0
                                    ? parseInt(
                                        form.afectaciones_covid_despidio_negocio
                                      )
                                    : ""
                                  : ""
                              }
                              onChange={handleChange}
                            >
                              <Radio onClick={handleClick} value={1}>
                                Si
                              </Radio>
                              <Radio onClick={handleClick} value={0}>
                                No
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item label="">
                            <label>
                              d. Tuvo que cerrar algún local o planta de producción: {" "}
                            </label>
                            <Radio.Group
                              name="afectaciones_covid_cerrar_negocio"
                              value={
                                form
                                  ? parseInt(
                                      form.afectaciones_covid_cerrar_negocio
                                    ) >= 0
                                    ? parseInt(
                                        form.afectaciones_covid_cerrar_negocio
                                      )
                                    : ""
                                  : ""
                              }
                              onChange={handleChange}
                            >
                              <Radio onClick={handleClick} value={1}>
                                Si
                              </Radio>
                              <Radio onClick={handleClick} value={0}>
                                No
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item label="">
                            <label>
                              e. Perdieron empleos compañeros de trabajo: {" "}
                            </label>
                            <Radio.Group
                              name="afectaciones_covid_companeros_desempleados"
                              value={
                                form
                                  ? parseInt(
                                      form.afectaciones_covid_companeros_desempleados
                                    ) >= 0
                                    ? parseInt(
                                        form.afectaciones_covid_companeros_desempleados
                                      )
                                    : ""
                                  : ""
                              }
                              onChange={handleChange}
                            >
                              <Radio onClick={handleClick} value={1}>
                                Si
                              </Radio>
                              <Radio onClick={handleClick} value={0}>
                                No
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Form.Item>
                      <Form.Item>
                        <label>
                          11. Esta cotizando a un fondo de pension? Cual?
                        </label>
                        <select
                          className="ant-input"
                          name="fondo_pension"
                          value={
                            form
                              ? form.fondo_pension
                                ? form.fondo_pension
                                : ""
                              : ""
                          }
                          onChange={selectFondoPension}
                          placeholder="Seleccione una opción"
                        >
                          {fondos_pensiones.map((fondo_pension) => {
                            return (
                              <option value={fondo_pension}>
                                {fondo_pension}
                              </option>
                            );
                          })}
                        </select>
                      </Form.Item>
                    </>
                  ) : null
                ) : null}

                <Form.Item>
                  <label>
                    12. Como consecuencia del Covid 19, muchas personas
                    perdieron su empleo o cerraron sus negocios. Para usted que
                    estrategias debería implementar la Administración Municipal,
                    para reactivar la creación de empleos y la reactivación de
                    la economía Local
                  </label>
                  <Col offset={2}>
                    <Form.Item label="">
                      <label>a. Apoyo con créditos a microempresarios: </label>
                      <Radio.Group
                        name="reactivacion_economica_apoyo_creditos"
                        value={
                          form
                            ? parseInt(
                                form.reactivacion_economica_apoyo_creditos
                              ) >= 0
                              ? parseInt(
                                  form.reactivacion_economica_apoyo_creditos
                                )
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="">
                      <label>
                        b. Alivios tributarios a los comerciantes del Municipio:{" "}
                      </label>
                      <Radio.Group
                        name="reactivacion_economica_alivios_tributarios"
                        value={
                          form
                            ? parseInt(
                                form.reactivacion_economica_alivios_tributarios
                              ) >= 0
                              ? parseInt(
                                  form.reactivacion_economica_alivios_tributarios
                                )
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="">
                      <label>c. Creación de más ayudas sociales: </label>
                      <Radio.Group
                        name="reactivacion_economica_creación_ayudas"
                        value={
                          form
                            ? parseInt(
                                form.reactivacion_economica_creación_ayudas
                              ) >= 0
                              ? parseInt(
                                  form.reactivacion_economica_creación_ayudas
                                )
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="">
                      <label>
                        d. Mayor apoyo al campo y a los programas Agropecuarios:{" "}
                      </label>
                      <Radio.Group
                        name="reactivacion_economica_mayor_apoyo"
                        value={
                          form
                            ? parseInt(
                                form.reactivacion_economica_mayor_apoyo
                              ) >= 0
                              ? parseInt(
                                  form.reactivacion_economica_mayor_apoyo
                                )
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="">
                      <label>e. Nuevas obras de infraestrutura pública: </label>
                      <Radio.Group
                        name="reactivacion_economica_nuevas_obras"
                        value={
                          form
                            ? parseInt(
                                form.reactivacion_economica_nuevas_obras
                              ) >= 0
                              ? parseInt(
                                  form.reactivacion_economica_nuevas_obras
                                )
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item label="">
                      <label>f. Otra: </label>
                      <Radio.Group
                        name="reactivacion_economica_otra"
                        value={
                          form
                            ? parseInt(form.reactivacion_economica_otra) >= 0
                              ? parseInt(form.reactivacion_economica_otra)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                      {form ? (
                        parseInt(form.reactivacion_economica_otra) > 0 ? (
                          <label>
                            Cual:{" "}
                            <Input
                              style={{ width: "40%" }}
                              value={
                                form
                                  ? form.reactivacion_economica_cual
                                    ? form.reactivacion_economica_cual
                                    : ""
                                  : ""
                              }
                              name="reactivacion_economica_cual"
                              onChange={handleChange}
                            />
                          </label>
                        ) : null
                      ) : null}
                    </Form.Item>
                  </Col>
                </Form.Item>
                <Form.Item>
                  <label>
                    13. Conoce a usted a alguna persona, amigo o familiar, que
                    haya perdido su empleo, o hubiese cerrado su negocio, como
                    consecuencia de la pandemia causada por el Covid 19.
                  </label>
                  <Radio.Group
                    name="amigos_afectados_covid"
                    value={
                      form
                        ? parseInt(form.amigos_afectados_covid) >= 0
                          ? parseInt(form.amigos_afectados_covid)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    14. Usted cree que la crísis económica y social que se ha
                    presentando como consecuencia de la pandemia, ha afectado
                    significamente este municipio?
                  </label>
                  <Radio.Group
                    name="crisis_economica_covid"
                    value={
                      form
                        ? parseInt(form.crisis_economica_covid) >= 0
                          ? parseInt(form.crisis_economica_covid)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {form ? (
                  form.crisis_economica_covid ? (
                    <Form.Item>
                      <label>
                        15. ¿Cual o cuales han sido los sectores de la economía
                        de este municipio que se han visto más afectado como
                        consecuencia de esta pandemia por el COVID 19?
                      </label>
                      <Col offset={2}>
                        <Form.Item label="">
                          <label>a. Comercio: </label>
                          <Radio.Group
                            name="comercio"
                            value={
                              form
                                ? parseInt(form.comercio) >= 0
                                  ? parseInt(form.comercio)
                                  : ""
                                : ""
                            }
                            onChange={handleChange}
                          >
                            <Radio onClick={handleClick} value={1}>
                              Si
                            </Radio>
                            <Radio onClick={handleClick} value={0}>
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item label="">
                          <label>b. Servicios: </label>
                          <Radio.Group
                            name="servicios"
                            value={
                              form
                                ? parseInt(form.servicios) >= 0
                                  ? parseInt(form.servicios)
                                  : ""
                                : ""
                            }
                            onChange={handleChange}
                          >
                            <Radio onClick={handleClick} value={1}>
                              Si
                            </Radio>
                            <Radio onClick={handleClick} value={0}>
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item label="">
                          <label>c. Industria: </label>
                          <Radio.Group
                            name="industria"
                            value={
                              form
                                ? parseInt(form.industria) >= 0
                                  ? parseInt(form.industria)
                                  : ""
                                : ""
                            }
                            onChange={handleChange}
                          >
                            <Radio onClick={handleClick} value={1}>
                              Si
                            </Radio>
                            <Radio onClick={handleClick} value={0}>
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item label="">
                          <label>d. Agropecuario: </label>
                          <Radio.Group
                            name="agropecuario"
                            value={
                              form
                                ? parseInt(form.agropecuario) >= 0
                                  ? parseInt(form.agropecuario)
                                  : ""
                                : ""
                            }
                            onChange={handleChange}
                          >
                            <Radio onClick={handleClick} value={1}>
                              Si
                            </Radio>
                            <Radio onClick={handleClick} value={0}>
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item label="">
                          <label>e. Construccion e Infraestructura: </label>
                          <Radio.Group
                            name="construccion_infraestructura"
                            value={
                              form
                                ? parseInt(form.construccion_infraestructura) >=
                                  0
                                  ? parseInt(form.construccion_infraestructura)
                                  : ""
                                : ""
                            }
                            onChange={handleChange}
                          >
                            <Radio onClick={handleClick} value={1}>
                              Si
                            </Radio>
                            <Radio onClick={handleClick} value={0}>
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item label="">
                          <label>f. Otro: </label>
                          <Radio.Group
                            name="sector_economico_otro"
                            value={
                              form
                                ? parseInt(form.sector_economico_otro) >= 0
                                  ? parseInt(form.sector_economico_otro)
                                  : ""
                                : ""
                            }
                            onChange={handleChange}
                          >
                            <Radio onClick={handleClick} value={1}>
                              Si
                            </Radio>
                          </Radio.Group>
                          {form ? (
                            parseInt(form.sector_economico_otro) > 0 ? (
                              <label>
                                Cual:{" "}
                                <Input
                                  style={{ width: "40%" }}
                                  value={
                                    form
                                      ? form.sector_economico_cual
                                        ? form.sector_economico_cual
                                        : ""
                                      : ""
                                  }
                                  name="sector_economico_cual"
                                  onChange={handleChange}
                                />
                              </label>
                            ) : null
                          ) : null}
                        </Form.Item>
                      </Col>
                    </Form.Item>
                  ) : null
                ) : null}
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={form ? (form.n_orden > 0 ? false : true) : true}
                  type="danger"
                  icon={<SaveOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Ocupacion;
