import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Radio,
  Button,
  Switch,
} from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import { SaveOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { SaveSalud, GetSaludCodigo, UpdateSalud } from "../entity/helpers";
import styles from "./css/form.module.css";
import {
  salud_regimenes,
  quienes_pagan_afiliacion,
  calidad_servicio_opciones,
  covid_atenciones,
  vacunas_razones,
  sintomas_vacunas,
  lista_eps,
} from "../helpers/helperforms";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const Salud = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  var personas = cookies.get("salud") ? cookies.get("salud") : [];
  const [form, setForm] = useState(formulario);
  const [regimen, setRegimen] = useState(salud_regimenes[0]);
  const [eps, setEPS] = useState("");
  const [edicion, setEdicion] = useState(false);
  const [n_orden_search, setNOrdenSearch] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  function handleSave() {
    SaveSalud(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
        personas = personas.filter(function (value, index, arr) {
          return value.id != form.n_orden;
        });
        cookies.remove("salud");
        cookies.set("salud", personas);
        setRegimen(salud_regimenes[0]);
        setForm(formulario);
        setEPS("");
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const intputNinguno = (e) => {
    const { name, value } = e.target;
    if (value == 1) {
      setForm({
        ...form,
        fiebre: 0,
        dolor_cabeza: 0,
        vomitos: 0,
        malestar_general: 0,
        [name]: value,
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };

  const handleUpdate = () => {
    UpdateSalud(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setRegimen(salud_regimenes[0]);
        setEPS("");
        setNOrdenSearch(null);
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setCodigo(null);
    setEdicion(false);
    setNOrdenSearch(null);
    setForm(formulario);
    setRegimen(salud_regimenes[0]);
    setEPS("");
    setNOrdenSearch(null);
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleOrden = (e) => {
    const { id, value } = e.target;
    setNOrdenSearch(value);
  };

  const handleSearch = () => {
    if (codigo && n_orden_search) {
      GetSaludCodigo(codigo, n_orden_search).then((response) => {
        if (response[0]) {
          setForm(response[0]);
          setEdicion(true);
          if (
            salud_regimenes.indexOf(response[0].regimen) == -1 &&
            response[0].regimen != null
          ) {
            setRegimen(response[0].regimen);
            setForm({
              ...response[0],
              regimen: response[0].regimen,
            });
            setEPS(response[0].eps_regimen);
          } else {
            setRegimen(response[0].regimen);
            setEPS(response[0].eps_regimen);
          }
        } else {
          setCodigo(null);
          setNOrdenSearch(null);
          setForm(formulario);
          setRegimen(salud_regimenes[0]);
          setEPS("");
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  const selectSintomas = (value) => {
    setForm({
      ...form,
      sintomas_dosis_cuales: value.join(),
    });
  };

  const putRegimen = (e) => {
    const { name, value } = e.target;
    setRegimen(value);
    setForm({
      ...form,
      regimen: value,
    });
  };

  const putEps = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      eps_regimen: value,
    });
    setEPS(value);
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      console.log(value, name);
      setForm({
        ...form,
        [name]: value,
      });
    } else {
      setForm({
        ...form,
        sintomas_dosis: e.join(),
      });
    }
  };
  return (
    <>
      <Row justify="center">
        <h1></h1>
      </Row>

      <Row gutter={24} justify="center">
        <Card
          title={
            <div>
              G. SEGURIDAD SOCIAL EN SALUD
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por: </label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Input
                    id="n_orden"
                    placeholder={"# Orden"}
                    onChange={handleOrden}
                    value={n_orden_search ? n_orden_search : ""}
                    style={{ width: "10%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
          className={styles.cardDashboardgeneral}
          size="large"
        >
          <Row justify="center">
            <Col span={10}>
              <Form
                // layout="vertical"
                initialValues={{ remember: true }}
                //   onFinish={onFinish}
                //   onFinishFailed={onFinishFailed}
              >
                {!editBool ? (
                  <Form.Item label="">
                    <label>Numero de Orden: </label>
                    <select
                      className="ant-input"
                      name="n_orden"
                      value={form ? (form.n_orden ? form.n_orden : "") : ""}
                      onChange={handleChange}
                      placeholder="Seleccione una opción"
                      defaultValue={0}
                    >
                      {personas.map((num) => {
                        return <option value={num.id}>{num.value}</option>;
                      })}
                    </select>
                  </Form.Item>
                ) : null}
                <Form.Item>
                  <label>
                    1. ¿Está afiliado, es cotizante o es beneficiario de alguna
                    entidad de seguridad social en salud? (Empresa Promotora de
                    Salud - EPS )
                  </label>
                  <Radio.Group
                    name="cotizante"
                    value={
                      form
                        ? parseInt(form.cotizante) >= 0
                          ? parseInt(form.cotizante)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    2. ¿A cuál de los siguientes regímenes de seguridad social
                    en salud está afiliado:
                  </label>
                  <select
                    disabled={form ? (form.cotizante ? false : true) : false}
                    className="ant-input"
                    name="regimen"
                    value={regimen ? regimen : ""}
                    onChange={putRegimen}
                    placeholder="Seleccione una opción"
                  >
                    {salud_regimenes.map((salud_regimen) => {
                      return (
                        <option value={salud_regimen}>{salud_regimen}</option>
                      );
                    })}
                  </select>
                  {regimen === salud_regimenes[1] ||
                  regimen === salud_regimenes[3] ? (
                    <label>
                      EPS:{" "}
                      {/* <Input
                        name="eps_input"
                        value={eps ? eps : ""}
                        onChange={putEps}
                      /> */}
                      <select
                        className="ant-input"
                        name="eps_input"
                        value={eps ? eps : ""}
                        onChange={putEps}
                        placeholder="Seleccione una opción"
                      >
                        {lista_eps.map((value) => {
                          return <option value={value}>{value}</option>;
                        })}
                      </select>
                    </label>
                  ) : null}
                </Form.Item>
                <Form.Item>
                  <label>
                    3. ¿Quién paga mensualmente por la afiliación de...?
                  </label>
                  <select
                    className="ant-input"
                    name="quien_paga"
                    disabled={form ? (form.cotizante ? false : true) : false}
                    value={form ? (form.quien_paga ? form.quien_paga : "") : ""}
                    onChange={handleChange}
                    // style={{ width: "100%" }}
                    // showSearch
                    placeholder="Seleccione una opción"
                    // optionFilterProp="children"
                    // onChange={onChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    // filteroption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {quienes_pagan_afiliacion.map((quien_paga_afiliacion) => {
                      return (
                        <option value={quien_paga_afiliacion}>
                          {quien_paga_afiliacion}
                        </option>
                      );
                    })}
                  </select>
                </Form.Item>
                <Form.Item>
                  <label>
                    4. En general, considera que la calidad del servicio de la
                    EPS o de la entidad de seguridad social en salud en la cual
                    está afiliado/a es:
                  </label>
                  <select
                    className="ant-input"
                    name="calidad_servicio"
                    disabled={form ? (form.cotizante ? false : true) : false}
                    value={
                      form
                        ? form.calidad_servicio
                          ? form.calidad_servicio
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                    // style={{ width: "100%" }}
                    // showSearch
                    placeholder="Seleccione una opción"
                    // optionFilterProp="children"
                    // onChange={onChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    // filteroption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {calidad_servicio_opciones.map(
                      (calidad_servicio_opcion) => {
                        return (
                          <option value={calidad_servicio_opcion}>
                            {calidad_servicio_opcion}
                          </option>
                        );
                      }
                    )}
                  </select>
                </Form.Item>
                <Form.Item>
                  <label>
                    5. Durante este tiempo de pandemia, ha sufrido usted
                    contagio a causa del covid 19?
                  </label>
                  <Radio.Group
                    name="contagio_covid"
                    value={
                      form
                        ? parseInt(form.contagio_covid) >= 0
                          ? parseInt(form.contagio_covid)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    6. Durante del Periodo de contagios, recibió atención por:
                  </label>
                </Form.Item>
                <Form.Item>
                  <select
                    className="ant-input"
                    name="atencion_covid"
                    disabled={
                      form ? (form.contagio_covid ? false : true) : false
                    }
                    value={
                      form
                        ? form.atencion_covid
                          ? form.atencion_covid
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                    placeholder="Seleccione una opción"
                  >
                    {covid_atenciones.map((covid_atencion) => {
                      return (
                        <option value={covid_atencion}>{covid_atencion}</option>
                      );
                    })}
                  </select>
                </Form.Item>
              </Form>
            </Col>
            <Col offset={2} span={12}>
              <Form
                // layout="vertical"
                name="basic1"
                initialValues={{ remember: true }}
                //   onFinish={onFinish}
                //   onFinishFailed={onFinishFailed}
              >
                <Form.Item>
                  <label>7. Ha recibido la Vacuna contra el Covid 19?</label>
                  <Radio.Group
                    name="vacuna_covid"
                    value={
                      form
                        ? parseInt(form.vacuna_covid) >= 0
                          ? parseInt(form.vacuna_covid)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                {form ? (
                  form.vacuna_covid ? (
                    <>
                      <Form.Item>
                        <label>8. Ha recibido:</label>
                        <Radio.Group
                          name="n_dosis"
                          value={form ? (form.n_dosis ? form.n_dosis : "") : ""}
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value="Dosis 1">Dosis 1</Radio>
                          <Radio onClick={handleClick} value="Dosis 2">Dosis 2</Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>
                          9. Para que Fecha quedó programada la segunda dosis
                        </label>
                        <input
                          className="ant-input"
                          name="fecha_seg_dosis"
                          value={
                            form
                              ? form.fecha_seg_dosis
                                ? form.fecha_seg_dosis
                                : ""
                              : ""
                          }
                          type="date"
                          disabled={
                            form
                              ? form.n_dosis === "Dosis 1"
                                ? false
                                : true
                              : false
                          }
                          onChange={(e) => handleChange(e)}
                        />
                      </Form.Item>
                      <Form.Item>
                        <label>
                          10. Presento sintomas despues de Vacunarse?
                        </label>
                        <Radio.Group
                          name="sintomas_dosis"
                          value={
                            form
                              ? parseInt(form.sintomas_dosis) >= 0
                                ? parseInt(form.sintomas_dosis)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>Si</Radio>
                          <Radio onClick={handleClick} value={0}>No</Radio>
                        </Radio.Group>
                      </Form.Item>
                      {form ? (
                        form.sintomas_dosis ? (
                          <Form.Item>
                            <label>
                              11. Que Síntomas presentó despúes de haberse
                              aplicado la vacuna?
                            </label>
                            <Col offset={2}>
                              <Form.Item label="">
                                <label>a. Fiebre: </label>
                                <Radio.Group
                                  name="fiebre"
                                  value={
                                    form
                                      ? parseInt(form.fiebre) >= 0
                                        ? parseInt(form.fiebre)
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>Si</Radio>
                                  <Radio onClick={handleClick} value={0}>No</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item label="">
                                <label>b. Dolor de cabeza: </label>
                                <Radio.Group
                                  name="dolor_cabeza"
                                  value={
                                    form
                                      ? parseInt(form.dolor_cabeza) >= 0
                                        ? parseInt(form.dolor_cabeza)
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>Si</Radio>
                                  <Radio onClick={handleClick} value={0}>No</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item label="">
                                <label>c. Vomitos: </label>
                                <Radio.Group
                                  name="vomitos"
                                  value={
                                    form
                                      ? parseInt(form.vomitos) >= 0
                                        ? parseInt(form.vomitos)
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>Si</Radio>
                                  <Radio onClick={handleClick} value={0}>No</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item label="">
                                <label>d. Malestar general: </label>
                                <Radio.Group
                                  name="malestar_general"
                                  value={
                                    form
                                      ? parseInt(form.malestar_general) >= 0
                                        ? parseInt(form.malestar_general)
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>Si</Radio>
                                  <Radio onClick={handleClick} value={0}>No</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item>
                                <label>e. Otro: </label>
                                <Radio.Group
                                  name="sintoma_vac_otro"
                                  value={
                                    form
                                      ? parseInt(form.sintoma_vac_otro) >= 0
                                        ? parseInt(form.sintoma_vac_otro)
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>Si</Radio>
                                  <Radio onClick={handleClick} value={0}>No</Radio>
                                </Radio.Group>
                                {form ? (
                                  parseInt(form.sintoma_vac_otro) > 0 ? (
                                    <label>
                                      Cual:{" "}
                                      <Input
                                        style={{ width: "40%" }}
                                        value={
                                          form
                                            ? form.sintoma_vac_cual
                                              ? form.sintoma_vac_cual
                                              : ""
                                            : ""
                                        }
                                        name="sintoma_vac_cual"
                                        onChange={handleChange}
                                      />
                                    </label>
                                  ) : null
                                ) : null}
                              </Form.Item>
                            </Col>
                          </Form.Item>
                        ) : null
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Form.Item>
                        <label>
                          7.a. Por que motivo o Razón , no se ha aplicado la
                          vacuna?
                        </label>
                        <select
                          className="ant-input"
                          name="razon"
                          value={form ? (form.razon ? form.razon : "") : ""}
                          onChange={handleChange}
                          placeholder="Seleccione una opción"
                          disabled={
                            form ? (form.vacuna_covid ? true : false) : true
                          }
                        >
                          {vacunas_razones.map((vacuna_razon) => {
                            return (
                              <option value={vacuna_razon}>
                                {vacuna_razon}
                              </option>
                            );
                          })}
                        </select>
                      </Form.Item>
                    </>
                  )
                ) : null}
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={form ? (form.n_orden > 0 ? false : true) : true}
                  type="danger"
                  icon={<SaveOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Salud;
