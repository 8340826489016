import axios from "axios";
const baseApi = process.env.REACT_APP_API_URL;

export const GetUsersLogin = async (usuario, contrasena, semana, hora) => {
  let response = await fetch(`${baseApi}login/${usuario}/${contrasena}/${semana}/${hora}/`).then((response) =>
    response.json()
  );
  return response;
};

export const GetEncuestadores = async () => {
  let response = await fetch(`${baseApi}encuestadores/`).then((response) =>
    response.json()
  );
  return response;
};

export const GetDigitadores = async () => {
  let response = await fetch(`${baseApi}digitadores/`).then((response) =>
    response.json()
  );
  return response;
};

export const GetSupervisores = async () => {
  let response = await fetch(`${baseApi}supervisores/`).then((response) =>
    response.json()
  );
  return response;
};

export const GetDepartments = async () => {
  let response = await fetch(`${baseApi}departamentos/`).then((response) =>
    response.json()
  );
  return response;
};

export const Getmunicipalities = async () => {
  let response = await fetch(`${baseApi}municipios/`).then((response) =>
    response.json()
  );
  return response;
};

export const Getmunicipalitiesbydepartment = async (data) => {
  let response = await fetch(
    `${baseApi}municipiospordepartamento/${data}/`
  ).then((response) => response.json());
  return response;
};

export const GetIdentificacionCodigo = async (codigo) => {
  let response = await fetch(`${baseApi}codigo_identificacion/${codigo}`).then(
    (response) => response.json()
  );
  return response;
};

export const SaveIdentificacion = async (data) => {
  let response = await axios
    .post(`${baseApi}identificacion`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateIdentificacion = async (data) => {
  let response = await axios
    .put(`${baseApi}identificacion/${data.identificacion_id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const GetViviendaCodigo = async (codigo) => {
  let response = await fetch(`${baseApi}codigo_vivienda/${codigo}`).then(
    (response) => response.json()
  );
  return response;
};

export const SaveVivienda = async (data) => {
  let response = await axios
    .post(`${baseApi}vivienda`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateVivienda = async (data) => {
  let response = await axios
    .put(`${baseApi}vivienda/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const GetHogarCodigo = async (codigo) => {
  let response = await fetch(`${baseApi}codigo_hogares/${codigo}`).then(
    (response) => response.json()
  );
  return response;
};

export const SaveHogar = async (data) => {
  let response = await axios
    .post(`${baseApi}hogar`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateHogar = async (data) => {
  let response = await axios
    .put(`${baseApi}hogar/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const GetResidenteCodigo = async (codigo, n_orden) => {
  let response = await fetch(
    `${baseApi}codigo_residente/${codigo}/${n_orden}`
  ).then((response) => response.json());
  return response;
};

export const GetListaResidentes = async (identificacion_id) => {
  let response = await fetch(
    `${baseApi}lista_personas/${identificacion_id}`
  ).then((response) => response.json());
  return response;
};

export const UpdateResidente = async (data) => {
  let response = await axios
    .put(`${baseApi}residente/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateCultura = async (data) => {
  let response = await axios
    .put(`${baseApi}cultura/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const SavePersona = async (data) => {
  let response = await axios
    .post(`${baseApi}residente`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdatePersona = async (data) => {
  let response = await axios
    .put(`${baseApi}save_residente`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const GetVulnerabilidadCodigo = async (codigo, n_orden) => {
  let response = await fetch(
    `${baseApi}codigo_vulnerabilidad/${codigo}/${n_orden}`
  ).then((response) => response.json());
  return response;
};

export const SaveVulnerabilidad = async (data) => {
  let response = await axios
    .post(`${baseApi}vulnerabilidad`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateVulnerabilidad = async (data) => {
  let response = await axios
    .put(`${baseApi}vulnerabilidad/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateMigracion = async (data) => {
  let response = await axios
    .put(`${baseApi}migracion/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const GetMigracionCodigo = async (codigo, num_orden) => {
  let response = await fetch(
    `${baseApi}codigo_migracion/${codigo}/${num_orden}`
  ).then((response) => response.json());
  return response;
};

export const SaveMigracion = async (data) => {
  let response = await axios
    .post(`${baseApi}migracion`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateSalud = async (data) => {
  let response = await axios
    .put(`${baseApi}salud/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const GetSaludCodigo = async (codigo, num_orden) => {
  let response = await fetch(
    `${baseApi}codigo_salud/${codigo}/${num_orden}`
  ).then((response) => response.json());
  return response;
};

export const SaveSalud = async (data) => {
  let response = await axios
    .post(`${baseApi}salud`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateEducacion = async (data) => {
  let response = await axios
    .put(`${baseApi}educacion/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const GetEducacionCodigo = async (codigo, num_orden) => {
  let response = await fetch(
    `${baseApi}codigo_educacion/${codigo}/${num_orden}`
  ).then((response) => response.json());
  return response;
};

export const SaveEducacion = async (data) => {
  let response = await axios
    .post(`${baseApi}educacion`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateOcupacion = async (data) => {
  let response = await axios
    .put(`${baseApi}ocupacion/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const GetOcupacionCodigo = async (codigo, num_orden) => {
  let response = await fetch(
    `${baseApi}codigo_ocupacion/${codigo}/${num_orden}`
  ).then((response) => response.json());
  return response;
};

export const SaveOcupacion = async (data) => {
  let response = await axios
    .post(`${baseApi}ocupacion`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateDesocupado = async (data) => {
  let response = await axios
    .put(`${baseApi}desocupado/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const GetDesocupadoCodigo = async (codigo, num_orden) => {
  let response = await fetch(
    `${baseApi}codigo_desocupado/${codigo}/${num_orden}`
  ).then((response) => response.json());
  return response;
};

export const SaveDesocupado = async (data) => {
  let response = await axios
    .post(`${baseApi}desocupado`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateOtraActividad = async (data) => {
  let response = await axios
    .put(`${baseApi}otra_actividad/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const GetOtraActividadCodigo = async (codigo, num_orden) => {
  let response = await fetch(
    `${baseApi}codigo_otra_actividad/${codigo}/${num_orden}`
  ).then((response) => response.json());
  return response;
};

export const SaveOtraActividad = async (data) => {
  let response = await axios
    .post(`${baseApi}otra_actividad`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateOtroIngreso = async (data) => {
  let response = await axios
    .put(`${baseApi}otro_ingreso/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const GetOtroIngresoCodigo = async (codigo, num_orden) => {
  let response = await fetch(
    `${baseApi}codigo_otro_ingreso/${codigo}/${num_orden}`
  ).then((response) => response.json());
  return response;
};

export const SaveOtroIngreso = async (data) => {
  let response = await axios
    .post(`${baseApi}otro_ingreso`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const UpdateOrganismoInstitucional = async (data) => {
  let response = await axios
    .put(`${baseApi}organismo_institucional/${data.id}`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
  return response;
};

export const GetOrganismoCodigo = async (codigo, num_orden) => {
  let response = await fetch(
    `${baseApi}codigo_organismo_institucional/${codigo}/${num_orden}`
  ).then((response) => response.json());
  return response;
};

export const SaveOrganismo = async (data) => {
  let response = await axios
    .post(`${baseApi}organismo_institucional`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const GetCapturadorCodigo = async (codigo) => {
  let response = await fetch(`${baseApi}exportacion/${codigo}`).then(
    (response) => response.json()
  );
  return response;
};

export const GetAllCapturador = async () => {
  let response = await fetch(`${baseApi}exportar_todo/`).then((response) =>
    response.json()
  );
  return response;
};

export const DeleteFormularioCodigo = async (codigo) => {
  let response = await axios
    .delete(`${baseApi}limpiar_formulario/${codigo}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const GetDigitadorParam = async (codigo) => {
  let response = await fetch(`${baseApi}digitador_param/${codigo}`).then(
    (response) => response.json()
  );
  return response;
};

export const GetDigitadorExport = async () => {
  let response = await fetch(`${baseApi}lista_digitadores/`).then((response) =>
    response.json()
  );
  return response;
};

export const GetDigitaciones = async (fecha) => {
  let response = await fetch(`${baseApi}digitaciones/${fecha}`).then(
    (response) => response.json()
  );
  return response;
};
