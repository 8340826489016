import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Radio,
  Button,
  Switch,
} from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import {
  SaveVivienda,
  GetViviendaCodigo,
  UpdateVivienda,
} from "../entity/helpers";
import styles from "./css/form.module.css";
import { SaveOutlined, SearchOutlined, EditOutlined } from "@ant-design/icons";
import {
  sectores,
  tipo_viviendas,
  otras_viviendas,
  paredes,
  pisos,
  vias,
  transportes,
  seguridades_barrios,
} from "../helpers/helperforms";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const Vivienda = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  const [servicios, setServicios] = useState({});
  const [form, setForm] = useState(formulario);
  const [edicion, setEdicion] = useState(false);
  const [tipo_vivienda, setTipoVivienda] = useState(false);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  const selectTipoVivienda = (value) => {
    setForm({
      ...form,
      tipo_vivienda: value.join(),
    });
  };

  const handleTipoVivienda = (e) => {
    const { name, value } = e.target;
    if (value === tipo_viviendas[tipo_viviendas.length - 1]) {
      setTipoVivienda(true);
    } else {
      setTipoVivienda(false);
    }
    setForm({
      ...form,
      [name]: value,
    });
  };

  function handleSave() {
    SaveVivienda(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleUpdate = () => {
    UpdateVivienda(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setServicios({});
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSearch = () => {
    if (codigo) {
      GetViviendaCodigo(codigo).then((response) => {
        if (response[0]) {
          setForm(response[0]);
          if (parseInt(response[0].servicio_basura) > 0) {
            setServicios({
              ...servicios,
              servicio_basura_bool: 1,
            });
          } else if (parseInt(response[0].servicio_basura) == 0) {
            setServicios({
              ...servicios,
              servicio_basura_bool: 0,
            });
          }
          if (parseInt(response[0].servicio_energia) > 0) {
            setServicios({
              ...servicios,
              servicio_energia_bool: 1,
            });
          } else if (parseInt(response[0].servicio_energia) == 0) {
            setServicios({
              ...servicios,
              servicio_energia_bool: 0,
            });
          }
          setEdicion(true);
        } else {
          setCodigo(null);
          setForm(formulario);
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setEdicion(false);
    setForm(formulario);
    setCodigo(null);
    setServicios({});
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (name === "servicio_basura_bool" || name === "servicio_energia_bool") {
      if (value == servicios[name]) {
        setServicios({
          ...servicios,
          [name]: "",
        });
        if (name === "servicio_basura_bool") {
          setForm({
            ...form,
            servicio_basura: "",
          });
        }
        if (name === "servicio_energia_bool") {
          setForm({
            ...form,
            servicio_energia: "",
          });
        }
      }
    }
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "servicio_basura_bool" || name === "servicio_energia_bool") {
      setServicios({
        ...servicios,
        [name]: value,
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };
  return (
    <>
      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title={
            <div>
              B. DATOS DE LA VIVIENDA (Sólo para el primer hogar de la vivienda)
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por Codigo de formulario</label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
        >
          <Row justify="center">
            <Form layout="vertical" initialValues={{ remember: true }}>
              <Form.Item label="1. Tipo de Vivienda">
                <select
                  value={
                    form ? (form.tipo_vivienda ? form.tipo_vivienda : "") : ""
                  }
                  name="tipo_vivienda"
                  onChange={handleTipoVivienda}
                  className="ant-input"
                  placeholder="Escoja el Tipo de Vivienda"
                >
                  {tipo_viviendas.map((tipo_vivienda) => {
                    return (
                      <option value={tipo_vivienda}>{tipo_vivienda}</option>
                    );
                  })}
                </select>
                {tipo_vivienda ? (
                  <label>
                    Otras viviendas:
                    <Select
                      mode="multiple"
                      name="tipo_vivienda"
                      onChange={selectTipoVivienda}
                      placeholder="Seleccione o redacte una o varias opciónes"
                    >
                      {otras_viviendas.map((element) => {
                        return <Option value={element}>{element}</Option>;
                      })}
                    </Select>
                  </label>
                ) : null}
              </Form.Item>
              <Form.Item label="2. ¿Cuál es el material predominante de las paredes?">
                <select
                  value={form ? (form.paredes ? form.paredes : "") : ""}
                  name="paredes"
                  placeholder="Seleccione una opción"
                  className="ant-input"
                  onChange={handleChange}
                >
                  {paredes.map((pared) => {
                    return <option value={pared}>{pared}</option>;
                  })}
                </select>
              </Form.Item>
              <Form.Item label="3. ¿Cúal es el material predominante de los pisos de la vivienda?">
                <select
                  value={form ? (form.pisos ? form.pisos : "") : ""}
                  name="pisos"
                  onChange={handleChange}
                  className="ant-input"
                  placeholder="Seleccione una opción"
                >
                  {pisos.map((piso) => {
                    return <option value={piso}>{piso}</option>;
                  })}
                </select>
              </Form.Item>

              <Form.Item label="4. ¿Con cuáles de los siguientes servicios cuenta la vivienda?">
                <Row>
                  <Col offset={2} justify="start" span={12}>
                    <label>Energia Electrica: </label>
                  </Col>
                  {servicios.servicio_energia_bool ? (
                    <Col span={4}>
                      <label>Estrato: </label>
                      <Input
                        style={{ width: "40%" }}
                        size="small"
                        onChange={handleChange}
                        defaultValue={0}
                        value={
                          form
                            ? form.servicio_energia
                              ? form.servicio_energia
                              : ""
                            : ""
                        }
                        name="servicio_energia"
                        placeholder="Estrato"
                      />
                    </Col>
                  ) : null}
                  <Col>
                    <Radio.Group
                      name="servicio_energia_bool"
                      onChange={handleChange}
                      value={
                        servicios
                          ? parseInt(servicios.servicio_energia_bool) >= 0
                            ? parseInt(servicios.servicio_energia_bool)
                            : ""
                          : ""
                      }
                    >
                      <Radio onClick={handleClick} value={1}>
                        Si
                      </Radio>
                      <Radio onClick={handleClick} value={0}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row>
                  <Col offset={2} justify="start" span={12}>
                    <label>Gas natural conectado: </label>
                  </Col>
                  <Col span={8} justify="end">
                    <Radio.Group
                      value={
                        form
                          ? parseInt(form.servicio_gas) >= 0
                            ? parseInt(form.servicio_gas)
                            : ""
                          : ""
                      }
                      name="servicio_gas"
                      onChange={handleChange}
                    >
                      <Radio onClick={handleClick} value={1}>
                        Si
                      </Radio>
                      <Radio onClick={handleClick} value={0}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row>
                  <Col offset={2} justify="start" span={12}>
                    <label>Alcantarillado: </label>
                  </Col>
                  <Col span={8} justify="end">
                    <Radio.Group
                      value={
                        form
                          ? parseInt(form.servicio_alcantarillado) >= 0
                            ? parseInt(form.servicio_alcantarillado)
                            : ""
                          : ""
                      }
                      name="servicio_alcantarillado"
                      onChange={handleChange}
                    >
                      <Radio onClick={handleClick} value={1}>
                        Si
                      </Radio>
                      <Radio onClick={handleClick} value={0}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row>
                  <Col offset={2} justify="start" span={12}>
                    <label>Recoleccion de basura: </label>
                  </Col>

                  {servicios.servicio_basura_bool ? (
                    <Col span={4}>
                      <label># Veces: </label>
                      <Input
                        style={{ width: "30%" }}
                        size="small"
                        onChange={handleChange}
                        defaultValue={0}
                        value={
                          form
                            ? form.servicio_basura
                              ? form.servicio_basura
                              : ""
                            : ""
                        }
                        name="servicio_basura"
                        placeholder="# Recoleccion"
                      />
                    </Col>
                  ) : null}
                  <Col justify="end">
                    <Radio.Group
                      name="servicio_basura_bool"
                      onChange={handleChange}
                      value={
                        servicios
                          ? parseInt(servicios.servicio_basura_bool) >= 0
                            ? parseInt(servicios.servicio_basura_bool)
                            : ""
                          : ""
                      }
                    >
                      <Radio onClick={handleClick} value={1}>
                        Si
                      </Radio>
                      <Radio onClick={handleClick} value={0}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row>
                  <Col offset={2} justify="start" span={12}>
                    <label>Acueducto: </label>
                  </Col>
                  <Col span={8} justify="end">
                    <Radio.Group
                      name="servicio_acueducto"
                      onChange={handleChange}
                      value={
                        form
                          ? parseInt(form.servicio_acueducto) >= 0
                            ? parseInt(form.servicio_acueducto)
                            : ""
                          : ""
                      }
                    >
                      <Radio onClick={handleClick} value={1}>
                        Si
                      </Radio>
                      <Radio onClick={handleClick} value={0}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
                <Row>
                  <Col offset={2} justify="start" span={12}>
                    <label>Servicio de internet: </label>
                  </Col>
                  <Col span={8} justify="end">
                    <Radio.Group
                      name="servicio_internet"
                      onChange={handleChange}
                      value={
                        form
                          ? parseInt(form.servicio_internet) >= 0
                            ? parseInt(form.servicio_internet)
                            : ""
                          : ""
                      }
                    >
                      <Radio onClick={handleClick} value={1}>
                        Si
                      </Radio>
                      <Radio onClick={handleClick} value={0}>
                        No
                      </Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label="5. ¿El agua del acueducto llega las 24 horas del día durante los siete dias d ela semana?">
                <Radio.Group
                  name="agua_siempre"
                  value={
                    form
                      ? parseInt(form.agua_siempre) >= 0
                        ? parseInt(form.agua_siempre)
                        : ""
                      : ""
                  }
                  disabled={
                    form ? (form.servicio_acueducto ? false : true) : true
                  }
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="6. En que condiciones se encuentran las vias de acceso a su vivienda">
                <select
                  value={
                    form
                      ? form.condiciones_vias
                        ? form.condiciones_vias
                        : ""
                      : ""
                  }
                  name="condiciones_vias"
                  onChange={handleChange}
                  className="ant-input"
                  placeholder="Seleccione una opción"
                >
                  {vias.map((via) => {
                    return <option value={via}>{via}</option>;
                  })}
                </select>
              </Form.Item>

              <Form.Item label="7.  En su barrio o sector de residencia se encuentran ubicados">
                <select
                  value={
                    form
                      ? form.ubicacion_barrio
                        ? form.ubicacion_barrio
                        : ""
                      : ""
                  }
                  name="ubicacion_barrio"
                  className="ant-input"
                  placeholder="Seleccione una opción"
                  onChange={handleChange}
                >
                  {sectores.map((sector) => {
                    return <option value={sector}>{sector}</option>;
                  })}
                </select>
              </Form.Item>
              <Form.Item label="8. Cual es el principal medio de transporte que usa para movilizarse en este municipio:">
                <select
                  value={
                    form
                      ? form.medio_transporte
                        ? form.medio_transporte
                        : ""
                      : ""
                  }
                  name="medio_transporte"
                  onChange={handleChange}
                  className="ant-input"
                  placeholder="Seleccione una opción"
                >
                  {transportes.map((transporte) => {
                    return <option value={transporte}>{transporte}</option>;
                  })}
                </select>
              </Form.Item>

              <Form.Item label="9. Cuenta con servicio de Alumbrado Público en su calle o sector">
                <Radio.Group
                  name="alumbrado_publico"
                  value={
                    form
                      ? parseInt(form.alumbrado_publico) >= 0
                        ? parseInt(form.alumbrado_publico)
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <label>
                  10. En cuanto a la persepción de seguridad en su barrio o
                  sector donde reside, usted cree que es:
                </label>
                <select
                  value={form ? (form.seguridad ? form.seguridad : "") : ""}
                  name="seguridad"
                  className="ant-input"
                  placeholder="Seleccione una opción"
                  onChange={handleChange}
                >
                  {seguridades_barrios.map((valor) => {
                    return <option value={valor}>{valor}</option>;
                  })}
                </select>
              </Form.Item>
            </Form>
          </Row>
        </Card>
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="danger"
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? cookies.get("formulario")
                        ? false
                        : true
                      : true
                  }
                  icon={<SaveOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Vivienda;
