import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Row, Card, Form, Input, Select, Button, Switch } from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import styles from "./css/form.module.css";
import { SaveHogar, GetHogarCodigo, UpdateHogar } from "../entity/helpers";
import { SaveOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import {
  nums100,
  sanitarios,
  sanitarioshogar,
  basuras_eliminar,
  agua_consumos,
  lugares_alimentos,
  energias_cocinan,
  tipo_hogares,
} from "../helpers/helperforms";

const { Option } = Select;

const DatosHogar = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  const [form, setForm] = useState(formulario);
  const [q9a, setQ9a] = useState(true);
  const [q10, setQ10] = useState(true);
  const [edicion, setEdicion] = useState(false);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  const handleUpdate = () => {
    UpdateHogar(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setQ9a(true);
        setQ10(true);
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setEdicion(false);
    setForm(formulario);
    setCodigo(null);
    setQ9a(true);
    setQ10(true);
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleSearch = () => {
    if (codigo) {
      GetHogarCodigo(codigo).then((response) => {
        if (response[0]) {
          setForm(response[0]);
          setEdicion(true);
          if (
            response[0].tipo_hogar === tipo_hogares[1] ||
            response[0].tipo_hogar === tipo_hogares[4] ||
            response[0].tipo_hogar === tipo_hogares[5] ||
            response[0].tipo_hogar === tipo_hogares[6]
          ) {
            setQ9a(true);
            setQ10(true);
          }
          if (response[0].tipo_hogar === tipo_hogares[2]) {
            setQ9a(false);
            setQ10(true);
          }
          if (response[0].tipo_hogar === tipo_hogares[3]) {
            setQ9a(true);
            setQ10(false);
          }
        } else {
          setCodigo(null);
          setForm(formulario);
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  function handleSave() {
    SaveHogar(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "tipo_hogar") {
      if (
        value === tipo_hogares[1] ||
        value === tipo_hogares[4] ||
        value === tipo_hogares[5] ||
        value === tipo_hogares[6]
      ) {
        setQ9a(true);
        setQ10(true);
      }
      if (value === tipo_hogares[2]) {
        setQ9a(false);
        setQ10(true);
      }
      if (value === tipo_hogares[3]) {
        setQ9a(true);
        setQ10(false);
      }
    }
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <>
      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title={
            <div>
              C. DATOS DEL HOGAR (Para todos los hogares de la vivienda),
              (Continuación) (para el (la) jefe (a) del hogar o su cónyuge)
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por Codigo de formulario</label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
        >
          <Row justify="center">
            <Form layout="vertical" initialValues={{ remember: true }}>
              <Form.Item>
                <label>
                  1. Incluyendo sala-comedor ¿de cuántos cuartos en total
                  dispone este hogar?
                </label>
                <p>
                  <strong>
                    Excluya cocinas, baños, garajes y cuartos destinados a
                    negocio
                  </strong>
                </p>
                <select
                  className="ant-input"
                  name="numero_habitaciones"
                  value={
                    form
                      ? form.numero_habitaciones
                        ? form.numero_habitaciones
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                  placeholder="Seleccione una opción"
                >
                  <option value={0}>Seleccione una opción</option>
                  {nums100().map((num) => {
                    return <option value={num}>{num}</option>;
                  })}
                </select>

                <Row>Si no sabe registre 98</Row>

                <Row>Si no sabe registre 99</Row>
              </Form.Item>

              <Form.Item>
                <label>
                  2. ¿En cuántos de esos cuartos duermen las personas de este
                  hogar?
                </label>
                <select
                  className="ant-input"
                  name="numero_usuarios"
                  value={
                    form
                      ? form.numero_usuarios
                        ? form.numero_usuarios
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                  placeholder="Seleccione una opción"
                >
                  <option value={0}>Seleccione una opción</option>
                  {nums100().map((element) => {
                    return <option value={element}>{element}</option>;
                  })}
                </select>
              </Form.Item>

              <Form.Item>
                <label>3. El servicio sanitario que utiliza el hogar es:</label>
                <select
                  className="ant-input"
                  name="servicio_sanitario"
                  value={
                    form
                      ? form.servicio_sanitario
                        ? form.servicio_sanitario
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                  placeholder="Seleccione una opción"
                >
                  {sanitarios.map((element) => {
                    return <option value={element}>{element}</option>;
                  })}
                </select>
              </Form.Item>

              <Form.Item>
                <label>4. El servicio sanitario del hogar es:</label>
                <select
                  className="ant-input"
                  name="uso_sanitario"
                  value={
                    form ? (form.uso_sanitario ? form.uso_sanitario : "") : ""
                  }
                  disabled={
                    form
                      ? form.servicio_sanitario ===
                        sanitarios[sanitarios.length - 1]
                        ? true
                        : false
                      : true
                  }
                  onChange={handleChange}
                  placeholder="Seleccione una opción"
                >
                  {sanitarioshogar.map((element) => {
                    return <option value={element}>{element}</option>;
                  })}
                </select>
              </Form.Item>

              <Form.Item>
                <label>
                  5.¿Cómo eliminan principalmente la basura en este hogar?
                </label>
                <select
                  className="ant-input"
                  name="sacar_basura"
                  value={
                    form ? (form.sacar_basura ? form.sacar_basura : "") : ""
                  }
                  onChange={handleChange}
                  placeholder="Seleccione una opción"
                >
                  {basuras_eliminar.map((element) => {
                    return <option value={element}>{element}</option>;
                  })}
                </select>
              </Form.Item>
              <Form.Item>
                <label>
                  6. ¿De dónde obtiene principalmente este hogar el agua para
                  consumo humano?
                </label>
                <select
                  className="ant-input"
                  name="agua_hogar"
                  value={form ? (form.agua_hogar ? form.agua_hogar : "") : ""}
                  onChange={handleChange}
                  placeholder="Seleccione una opción"
                >
                  {agua_consumos.map((element) => {
                    return <option value={element}>{element}</option>;
                  })}
                </select>
              </Form.Item>
              <Form.Item>
                <label>
                  7. ¿En cuál de los siguientes lugares, preparan los alimentos
                  las personas de este hogar:
                </label>
                <select
                  className="ant-input"
                  name="donde_cocinan"
                  value={
                    form ? (form.donde_cocinan ? form.donde_cocinan : "") : ""
                  }
                  onChange={handleChange}
                  placeholder="Seleccione una opción"
                >
                  {lugares_alimentos.map((element) => {
                    return <option value={element}>{element}</option>;
                  })}
                </select>
              </Form.Item>
              <Form.Item>
                <label>8. ¿Con qué energía o combustible cocinan</label>
                <select
                  className="ant-input"
                  name="combustible_cocina"
                  value={
                    form
                      ? form.combustible_cocina
                        ? form.combustible_cocina
                        : ""
                      : ""
                  }
                  disabled={
                    form
                      ? form.donde_cocinan ===
                        lugares_alimentos[lugares_alimentos.length - 1]
                        ? true
                        : false
                      : true
                  }
                  onChange={handleChange}
                  placeholder="Seleccione una opción"
                >
                  {energias_cocinan.map((element) => {
                    return <option value={element}>{element}</option>;
                  })}
                </select>
              </Form.Item>
              <Form.Item>
                <label>9. La vivienda ocupada por este hogar es:</label>
                <select
                  className="ant-input"
                  name="tipo_hogar"
                  value={form ? (form.tipo_hogar ? form.tipo_hogar : "") : ""}
                  onChange={handleChange}
                  placeholder="Seleccione una opción"
                >
                  {tipo_hogares.map((element) => {
                    return <option value={element}>{element}</option>;
                  })}
                </select>
              </Form.Item>
              <Form.Item>
                <label>
                  9A. ¿Cuánto pagan mensualmente por cuota de amortización?
                </label>
                <Input
                  name="valor_amortizacion"
                  value={
                    form
                      ? form.valor_amortizacion
                        ? form.valor_amortizacion
                        : ""
                      : ""
                  }
                  disabled={q9a}
                  onChange={handleChange}
                  placeholder="Valor $"
                />
              </Form.Item>
              <Form.Item>
                <label>10. ¿Cuánto pagan mensualmente por arriendo?</label>
                <Input
                  name="valor_arriendo"
                  value={
                    form ? (form.valor_arriendo ? form.valor_arriendo : "") : ""
                  }
                  disabled={q10}
                  onChange={handleChange}
                  placeholder="Valor $"
                />
              </Form.Item>
              <Form.Item label="11. ¿Cuanto tiempo tiene de estar residiendo en esta vivienda?">
                <Input
                  style={{ width: "20%" }}
                  size="small"
                  onChange={handleChange}
                  name="tiempo_dias"
                  value={form ? (form.tiempo_dias ? form.tiempo_dias : "") : ""}
                  placeholder="Dias"
                />
                <Input
                  style={{ width: "20%" }}
                  size="small"
                  onChange={handleChange}
                  name="tiempo_meses"
                  value={
                    form ? (form.tiempo_meses ? form.tiempo_meses : "") : ""
                  }
                  placeholder="Meses"
                />
                <Input
                  onChange={handleChange}
                  style={{ width: "20%" }}
                  size="small"
                  name="tiempo_años"
                  value={form ? (form.tiempo_años ? form.tiempo_años : "") : ""}
                  placeholder="Años"
                />
              </Form.Item>
            </Form>
          </Row>
        </Card>
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="danger"
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? cookies.get("formulario")
                        ? false
                        : true
                      : true
                  }
                  icon={<SaveOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default DatosHogar;
