import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Radio,
  Button,
  Switch,
} from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import {
  SaveOtraActividad,
  UpdateOtraActividad,
  GetOtraActividadCodigo,
} from "../entity/helpers";
import { SaveOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./css/form.module.css";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const OtrasActividades = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  var personas = cookies.get("otras_actividades")
    ? cookies.get("otras_actividades").filter(function (value, index, arr) {
        return value.edad >= 15 || value.id == 0;
      })
    : [];
  const [form, setForm] = useState(formulario);
  const [edicion, setEdicion] = useState(false);
  const [n_orden_search, setNOrdenSearch] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  function handleSave() {
    SaveOtraActividad(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
        personas = personas.filter(function (value, index, arr) {
          return value.id != form.n_orden;
        });
        cookies.remove("otras_actividades");
        cookies.set("otras_actividades", personas);
        setForm(formulario);
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleUpdate = () => {
    UpdateOtraActividad(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setNOrdenSearch(null);
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setCodigo(null);
    setEdicion(false);
    setNOrdenSearch(null);
    setForm(formulario);
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleOrden = (e) => {
    const { id, value } = e.target;
    setNOrdenSearch(value);
  };

  const handleSearch = () => {
    if (codigo && n_orden_search) {
      GetOtraActividadCodigo(codigo, n_orden_search).then((response) => {
        if (response[0]) {
          setForm(response[0]);
          setEdicion(true);
        } else {
          setCodigo(null);
          setNOrdenSearch(null);
          setForm(formulario);
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <>
      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title={
            <div>
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por: </label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Input
                    id="n_orden"
                    placeholder={"# Orden"}
                    onChange={handleOrden}
                    value={n_orden_search ? n_orden_search : ""}
                    style={{ width: "10%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
        >
          <h1>
            K. OTRAS ACTIVIDADES Y AYUDAS EN LA SEMANA PASADA (PARA TODAS LAS
            PERSONAS DE 15 AÑOS Y MAS)
          </h1>
          <Row justify="center">
            <Col>
              <Form
                // layout="vertical"
                initialValues={{ remember: true }}
                //   onFinish={onFinish}
                //   onFinishFailed={onFinishFailed}
              >
                {!editBool ? (
                  <Form.Item label="Numero de Orden">
                    <select
                      className="ant-input"
                      name="n_orden"
                      value={form ? (form.n_orden ? form.n_orden : "") : ""}
                      onChange={handleChange}
                      placeholder="Seleccione una opción"
                      defaultValue={0}
                    >
                      {personas.map((num) => {
                        return <option value={num.id}>{num.value}</option>;
                      })}
                    </select>
                  </Form.Item>
                ) : null}
                <Form.Item>
                  <label>
                    1. Ayudar en labores del campo o en la cría de animales?
                  </label>
                  <br></br>
                  <Radio.Group name="labores_campo" value={form ? (parseInt(form.labores_campo) >= 0 ? parseInt(form.labores_campo) : "") : ""} onChange={handleChange}>
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>2. Realizar oficios en su hogar</label>
                  <br></br>
                  <Radio.Group name="oficio_hogar" value={form ? (parseInt(form.oficio_hogar) >= 0 ? parseInt(form.oficio_hogar) : "") : ""} onChange={handleChange}>
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    3. Realizar oficios en otros hogares o instituciones?
                  </label>
                  <br></br>
                  <Radio.Group name="oficio_lugares" value={form ? (parseInt(form.oficio_lugares) >= 0 ? parseInt(form.oficio_lugares) : "") : ""} onChange={handleChange}>
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>4. Cuidar o atender niños</label>
                  <br></br>
                  <Radio.Group name="cuidar_menores" value={form ? (parseInt(form.cuidar_menores) >= 0 ? parseInt(form.cuidar_menores) : "") : ""} onChange={handleChange}>
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    5. Cuidar personas enfermas, ancianas y/o discapacitadas?
                  </label>
                  <br></br>
                  <Radio.Group name="cuidar_mayores" value={form ? (parseInt(form.cuidar_mayores) >= 0 ? parseInt(form.cuidar_mayores) : "") : ""} onChange={handleChange}>
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    6. Elaborar prendas de vestir o tejidos para miembros del
                    hogar?
                  </label>
                  <br></br>
                  <Radio.Group name="vestir_hogar" value={form ? (parseInt(form.vestir_hogar) >= 0 ? parseInt(form.vestir_hogar) : "") : ""} onChange={handleChange}>
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>7. Asistir a cursos o eventos de capacitación?</label>
                  <br></br>
                  <Radio.Group name="cursos" value={form ? (parseInt(form.cursos) >= 0 ? parseInt(form.cursos) : "") : ""} onChange={handleChange}>
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>8. Trabajar en la autoconstrucción de vivienda?</label>
                  <br></br>
                  <Radio.Group name="auto_construccion" value={form ? (parseInt(form.auto_construccion) >= 0 ? parseInt(form.auto_construccion) : "") : ""} onChange={handleChange}>
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    9. Realizar trabajos comunitarios o voluntarios en
                    edificaciones u obras comunitarias o públicas?
                  </label>
                  <br></br>
                  <Radio.Group
                    name="trabajos_comunitarios" value={form ? (parseInt(form.trabajos_comunitarios) >= 0 ? parseInt(form.trabajos_comunitarios) : "") : ""}
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    10. Participar en otras actividades cívicas, sociales,
                    comunales y/o de trabajo voluntario?
                  </label>
                  <br></br>
                  <Radio.Group name="otras_actividades" value={form ? (parseInt(form.otras_actividades) >= 0 ? parseInt(form.otras_actividades) : "") : ""} onChange={handleChange}>
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={form ? (form.n_orden > 0 ? false : true) : true}
                  type="danger"
                  icon={<SaveOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default OtrasActividades;
