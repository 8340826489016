import React, { useState, useEffect } from "react";
import md5 from "md5";
import Cookies from "universal-cookie";
import { Row, Col, Card, Form, Input, Button } from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import styles from "./css/form.module.css";
import { GetUsersLogin } from "../entity/helpers";
//logos
import BarrancasLogo from "../../Assets/img/logoempresa.png";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const Acceso = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const [form, setForm] = useState(
    cookies.get("usuario") ? cookies.get("usuario") : null
  );
  const [login, setLogin] = useState(cookies.get("usuario") ? true : false);

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({
      ...form,
      [id]: value,
    });
  };

  const handleLogin = () => {
    if (form) {
      if (form.usuario) {
        GetUsersLogin(
          form.usuario,
          form.contrasena,
          new Date()
            .getDay()
            .toLocaleString("es-ES", { timeZone: "America/Bogota" }),
          new Date()
            .getHours()
            .toLocaleString("es-ES", { timeZone: "America/Bogota" })
        ).then((response) => {
          if (response.success) {
            cookies.set("usuario", response.data);
            setForm(response.data);
            setLogin(true);
          } else {
            cookies.remove("usuario");
            setLogin(false);
            setForm(null);
            MSJERROR(response.error);
          }
        });
      }
    }
  };

  const handleLogout = () => {
    cookies.remove("usuario");
    setLogin(false);
    setForm(null);
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 10 }}>
        {/* <Col className="gutter-row" span={4} offset={5}> */}
        <Col offset={8} className="gutter-row">
          <div>
            <img width="50%" src={BarrancasLogo} alt="BarrancasLogo" />
          </div>
        </Col>
      </Row>
      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="INGRESO CREDENCIALES"
        >
          <Row justify="center">
            <Col span={10}>
              <Form name="basic1" initialValues={{ remember: true }}>
                {cookies.get("usuario") ? (
                  <label>
                    Bienvenido:{" "}
                    {cookies.get("usuario").nombre +
                      " " +
                      cookies.get("usuario").apellidos}
                  </label>
                ) : (
                  <>
                    <Form.Item label="Usuario">
                      <Input
                        size="small"
                        placeholder="Ingrese Usuario"
                        id="usuario"
                        value={form ? (form.usuario ? form.usuario : "") : ""}
                        onChange={handleChange}
                      />
                    </Form.Item>
                    <Form.Item label="Contraseña">
                      <Input.Password
                        size="small"
                        placeholder="Ingrese contraseña"
                        id="contrasena"
                        value={
                          form ? (form.contrasena ? form.contrasena : "") : ""
                        }
                        onChange={handleChange}
                      />
                    </Form.Item>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>

      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {login ? (
              <Col>
                <Button
                  type="danger"
                  disabled={login ? false : true}
                  icon={<LogoutOutlined />}
                  size="large"
                  shape="round"
                  href="./"
                  onClick={() => handleLogout()}
                >
                  Cerrar Sesion
                </Button>
              </Col>
            ) : (
              <Col>
                <Button
                  type="primary"
                  disabled={login ? true : false}
                  icon={<LoginOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleLogin()}
                >
                  Ingresar
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Acceso;
