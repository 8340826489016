export const items = [
  {
    id: 1,
    color: "white",
    background: "#1F6CDA",
    name: "ACCESO",
  },
  {
    id: 2,
    color: "white",
    background: "#181a63",
    name: "IDENTIFICACIÓN",
  },
  { id: 3, color: "white", background: "#1F6CDA", name: "DATOS DE VIVIENDA" },
  { id: 4, color: "white", background: "#181a63", name: "DATOS DEL HOGAR" },
  {
    id: 5,
    color: "white",
    background: "#1F6CDA",
    name: "CARACTERISTICAS GENERALES",
  },
  {
    id: 6,
    color: "white",
    background: "#181a63",
    name: "CARACTERISTICAS CULTURALES",
  },
  { id: 7, color: "white", background: "#1F6CDA", name: "VULNERABILIDAD" },
  { id: 8, color: "white", background: "#181a63", name: "MIGRACIÓN" },
  {
    id: 9,
    color: "white",
    background: "#1F6CDA",
    name: "SEGURIDAD SOCIAL EN SALUD",
  },
  { id: 10, color: "white", background: "#181a63", name: "EDUCACIÓN" },
  {
    id: 11,
    color: "white",
    background: "#1F6CDA",
    name: "OCUPACIÓN - INGRESOS",
  },
  { id: 12, color: "white", background: "#181a63", name: "DESOCUPADOS" },
  { id: 13, color: "white", background: "#1F6CDA", name: "OTRAS ACTIVIDADES" },
  { id: 14, color: "white", background: "#181a63", name: "OTROS INGRESOS" },
  {
    id: 15,
    color: "white",
    background: "#1F6CDA",
    name: "ORGANISMOS INSTITUCIONALES",
  },
  { id: 16, color: "white", background: "#181a63", name: "EXPORTACIONES" },
  {
    id: 17,
    color: "white",
    background: "#1F6CDA",
    name: "REPORTE DIGITADORES",
  },
];

export const nums100 = () => {
  let nums = [];
  for (var i = 1; i <= 100; i++) {
    nums.push(i);
  }
  return nums;
};

export const sanitarios = [
  "Seleccione una opción",
  "Inodoro conectado a alcantarillado",
  "Inodoro conectado a pozo séptico",
  "Inodoro sin conexión",
  "Letrina",
  "Bajamar",
  "No tiene servicio sanitario",
];

export const sanitarioshogar = [
  "Seleccione una opción",
  "De uso exclusivo de las personas del hogar",
  "Compartido con personas de otros hogares",
];

export const basuras_eliminar = [
  "Seleccione una opción",
  "Por recolección pública o privada",
  "La tiran a un río, quebrada, caño o laguna",
  "La tiran a un patio, lote, zanja o baldío",
  "La queman o entierran",
  "La eliminan de otra forma",
];

export const agua_consumos = [
  "Seleccione una opción",
  "De acueducto por tubería",
  "De otra fuente por tubería",
  "De pozo con bomba",
  "De pozo sin bomba, aljibe, jagüey o barreno",
  "Aguas lluvias",
  "Río, quebrada, nacimiento ó manantial",
  "De pila pública",
  "Carro tanque",
  "Aguatero",
  "Agua embotellada o en bolsa",
];

export const lugares_alimentos = [
  "Seleccione una opción",
  "En un cuarto usado solo para cocinar",
  "En un cuarto usado también para dormir",
  "En una sala comedor con lavaplatos",
  "En una sala comedor sin lavaplatos",
  "En un patio, corredor, enramada, al aire libre",
  "En ninguna parte, no preparan alimentos",
];

export const energias_cocinan = [
  "Seleccione una opción",
  "Electricidad",
  "Petróleo, gasolina, kerosene, alcohol",
  "Gas natural conectado a red pública",
  "Gas propano en cilindro o pipeta",
  "Leña, madera o carbón de leña",
  "Carbón mineral",
  "Materiales de desecho",
];

export const tipo_hogares = [
  "Seleccione una opción",
  "Propia, totalmente pagada",
  "Propia, la están pagando",
  "En arriendo o subarriendo",
  "En usufructo",
  "Posesión sin titulo (Ocupante de hecho) ó propiedad colectiva",
  "Otra",
];

export const tipo_viviendas = [
  "Seleccione una opción",
  "Casa",
  "Apartamento",
  "Cuartos en inquilinato",
  "Cuartos en otro tipo de estructura",
  "Vivienda indigena",
  "Otra vivienda",
];

export const paredes = [
  "Seleccione una opción",
  "Ladrillo, bloque, material prefabricado, piedra",
  "Madera pulida",
  "Adobe o tapia pisada",
  "Bahareque",
  "Madera burda, tabla, tablón",
  "Guadua",
  "Caña, esterilla, otro tipo de material vegetal",
  "Zinc, tela, cartón, latas, desechos, plástico",
  "Sin paredes",
];

export const pisos = [
  "Seleccione una opción",
  "Tierra, arena",
  "Cemento, gravilla",
  "Madera burda, tabla, tablón, otro vegetal",
  "Baldosín, ladrillo, vinisol, otros materiales sintéticos",
  "Mármol",
  "Madera pulida",
  "Alfombra o tapete de pared a pared",
];

export const vias = [
  "Seleccione una opción",
  "Excentes Condiciones",
  "Buenas Condiciones",
  "Regulares Condciones",
  "Malas Condiciones (sin pavimento, vía despatada)",
  "Sin vías de acceso vehicular",
];

export const sectores = [
  "Seleccione una opción",
  "Parques de Recreacion",
  "Escenarios deportivos",
  "Vias con acceso a ciclorutas",
  "Escenarios Culturares (salas de cine, tetro etc)",
  "Ninguna de las Anteriores ",
];

export const transportes = [
  "Seleccione una opción",
  "Transporte Público Urbano",
  "Mototaxis o Motocarros",
  "Automovil Particular",
  "Motocicleta Particular",
  "Caminando",
  "Vehiculo de tracción animal",
  "Bicicletas",
];

export const tipo_identificaciones = [
  "Seleccione una opción",
  "C.C.",
  "C.E.",
  "T.I.",
  "R.C.",
];

export const sexos = ["Seleccione una opción", "Hombre", "Mujer"];

export const etnias = [
  "Seleccione una opción",
  "Wayuu",
  "Arhuaco",
  "Chimila",
  "Embera",
  "Kamkuamo",
  "Kogui",
  "Otro",
];

export const culturas = [
  "Seleccione una opción",
  "Afrodecendiente",
  "Palenquero",
  "Raizal del Archipielago",
  "del Archipielago",
  "Mulato",
  "Gitano o Row",
  "Ninguna",
];

export const comunidadLGBTs = [
  "Seleccione una opción",
  "Lesbianas",
  "Gays",
  "Bisexuales",
  "Transexuales",
  "Trasvestis",
  "Intersexuales",
];

export const religiones = [
  "Seleccione una opción",
  "Católico",
  "Evagélico",
  "Testigos de Jehova",
  "Musulman",
  "Judio",
  "Otra",
];

export const comunidad_culturales = [
  "Seleccione una opción",
  "NO",
  "Grupo de Baile o Danza",
  "Grupo de Teatro y/o Actuación",
];

export const donde_vivio_opciones = [
  "Seleccione una opción",
  "No Habia nacido",
  "En Este Municipio",
  "En otro Municipio",
  "En otro Pais",
];

export const motivos_migraciones = [
  "Seleccione una opción",
  "Trabajo",
  "Estudio",
  "Salud",
  "Amenaza o riesgos para su vida",
  "Razones Climaticas",
];

export const salud_regimenes = [
  "Seleccione una opción",
  "Contributivo",
  "Especial (Fuerzas Armadas, Ecopetrol, universidades públicas; magisterio)",
  "Subsidiado",
  "No sabe, no informa",
];

export const quienes_pagan_afiliacion = [
  "Seleccione una opción",
  "Paga la totalidad de la afiliación",
  "Paga una parte y otra la empresa o patrón ",
  "Paga completamente la empresa o patrón donde trabaja o trabajó",
  "Le descuentan de la pensión",
  "No paga, es beneficiario",
  "No sabe, no informa",
];

export const calidad_servicio_opciones = [
  "Seleccione una opción",
  "Muy Buena",
  "Buena",
  "Regular",
  "Mala",
  "Muy Mala",
];

export const covid_atenciones = [
  "Seleccione una opción",
  "Servicios de Urgencias",
  "Hospitalización",
  "Servicio en Unidad de Ciudados Intensivos (UCI)",
  "Recibio atención médica en Casa",
  "Fue Asintomáitco",
  "No recibió ningún tipo de atención médica",
];

export const vacunas_razones = [
  "Seleccione una opción",
  "No lo han llamado de la EPS o Entidad de Salud",
  "Tiene Temor en vacunarse",
  "Su Familia no quiere que se Vacune",
  "No estan vacunando en esta población",
];

export const sintomas_vacunas = [
  "Fiebre",
  "Dolor de cabeza",
  "Vomitos",
  "Malestar general",
  "Ninguna",
];

export const niveles_educativos = [
  "Seleccione una opción",
  "Ninguno",
  "Preescolar",
  "Básica primaria (1o - 5o)",
  "Básica secundaria (6o - 9o)",
  "Media (10o - 13o)",
  "Superior o universitaria",
  "No sabe, no informa",
];

export const titulos = [
  "Seleccione una opción",
  "Ninguno",
  "Bachiller",
  "Técnico o tecnológico",
  "Universitario",
  "Postgrado",
  "No sabe, no informa",
];

export const actividades = [
  "Seleccione una opción",
  "Trabajando",
  "Buscando trabajo",
  "Estudiando",
  "Oficios del hogar",
  "Incapacitado permanente para trabajar",
  "Otra actividad",
];

export const actividades_laborales = [
  "Seleccione una opción",
  "Obrero o empleado de Empresa particular",
  "Obrero o empleado del gobierno",
  "Empleado doméstico",
  "Trabajador por cuenta propia (independiente)",
  "Patrón o empleador",
  "Jornalero o peón",
  "Otro",
];

export const tipo_contratos = [
  "Seleccione una opción",
  "Verbal",
  "Escrito",
  "No tengo",
];

export const tipo_contratos2 = [
  "Seleccione una opción",
  "Termino Fijo",
  "Termino Indefinido",
];

export const prestaciones = [
  "Seleccione una opción",
  "Primas de Servicio",
  "Prima de Vacaciones",
  "Vacaciones Remuneradas",
  "Cesantias",
];

export const fondos_pensiones = [
  "Seleccione una opción",
  "Fondo privado",
  "Colpensiones",
  "Regímenes especiales (FFMM, Ecopetrol etc)",
  "Fondo Subsidiado (Prosperar,etc.)",
  "No",
];

export const afectaciones_covid = [
  "Seleccione una opción",
  "Se redujo su producción Laboral",
  "Se redujeron sus ganancias o ingresos  económicos",
  "Tuvo que despedir de su Negocio o empresa",
  "Tuvo que cerrar algún local o planta de producción",
  "Perdieron empleos compañeros de trabajo",
  "No",
];

export const sectores_economicos_covid = [
  "Comercio",
  "Industria",
  "Construccion e Infraestructura",
  "Servicios",
  "Agropecuario",
];

export const organismos_institucionales = [
  "Seleccione una opción",
  "Alcaldía Municipal",
  "Registraduria del Servicio Civil",
  "Personería Municipal",
  "Instituto Colombiano de Bienestar Famliar",
  "Otro",
];

export const frecuencia_asistencias = [
  "Seleccione una opción",
  "Diaramente",
  "una vez a la semana",
  "Varias veces a la semana",
  "Una Vez al mes",
  "Varias veces al mes",
  "Otro",
];

export const razon_asistencia = [
  "Gestiones y/o trámites Personales ",
  "Gestiones en pro de la comunidad",
  "Vulneracion de Derechos Humanos.",
  "Quejas y Reclamos",
];

export const calificacion_servicio = [
  "Seleccione una opción",
  "Excelente",
  "Buena",
  "Regular",
  "Mala",
];

export const ambiente_covid = [
  "Seleccione una opción",
  "Es Optimista",
  "Es Pesimista",
  "Nada ha cambiado en este Municipio",
  "Algunas Cosas podría Mejorar",
  "Algunas Cosas Podrian Empeorar",
];

export const espectativas = [
  "Inversión en Salud y Educación",
  "Proyectos de Vivienda",
  "Pavimentacion de vías",
  "Programas de Emprendimiento y creación de Empleo",
  "Apoyo al empresario Local	",
  "Sanamiento Básico",
  "Programas Agropecuarios y Apoyo al campesinado",
];

export const pertenece_a = [
  "Asociación gremial",
  "Asociación Sindical",
  "Partido o Grupo Político",
  "Partido o Grupo Político Junta de Acción Comunal",
  "Concejos Comunitarios",
  "Grupos Juveniles",
  "Mujeres Líderes",
];

export const realizado_gestion = [
  "Secretaria de Gobierno",
  "Secretaria de Hacienda",
  "Gestión Social",
  "Planeación Municipal",
  "Secretaria de Salud",
  "Secretaria de Educación",
];

export const estado_gestion = [
  "Seleccione una opción",
  "Resuelta en feliz término",
  "Se Encuentra en Trámite",
  "No tuvo aceptación ante la dependencia competente",
  "No ha tenido aún respuesta por parte de labdependencia",
];

export const estado_civiles = [
  "Seleccione una opción",
  "CASADO",
  "UNION LIBRE",
  "SEPARADO",
  "VIUDO",
  "SOLTERO",
];

export const otras_viviendas = [
  "Carpa",
  "Vagón",
  "Embarcación",
  "Cueva",
  "Refugio natural",
];

export const parentescos = [
  "Seleccione una opción",
  "JEFE DEL HOGAR",
  "CÓNYUGE",
  "HIJOS O HIJASTRO  DEL JEFE DEL HOGAR O DE LA PAREJA",
  "HIJO SOLTERO DEL JEFE  O CONYUGUE",
  "HIJOS CASADOS SEPARADOS VIUDOS CON SU NUCLEO",
  "NIETOS",
  "OTROS PARIENTES DEL JEFE DEL HOGAR",
  "EMPLEADO DOMESTICO",
  "TRABAJADORES DE HOGAR",
  "PENSIONISTAS",
  "OTROS NO PARIENTES CON EL JEFE DEL HOGAR",
];

export const idiomas = [
  "Seleccione una opción o escriba",
  "Español o Castellano",
  "Inglés",
  "Etnico",
  "Otros",
];

export const reactivaciones_economicas = [
  "Seleccione una opción o escriba",
  "Apoyo con créditos a microempresarios",
  "Alivios tributarios a los comerciantes del Municipio",
  "Creación de más ayudas sociales",
  "Mayor apoyo al campo y a los programas Agropecuarios",
  "Nuevas obras de infraestrutura pública",
  "Otra",
];

export const seguridades_barrios = [
  "Seleccione una opción o escriba",
  "Muy segura",
  "Segura",
  "Insegura",
  "Muy insegura",
];

export const lista_eps = [
  "Seleccione una opción o escriba",
  "COMFAUAJIRA",
  "CAJACOPI",
  "DUSAKAWI",
  "AIC",
  "ANASSWAYUU",
  "SANITAS",
  "COOMEVA",
  "NEPS",
  "COOSALUD",
  "ASMET SALUD",
  "SAVIA SALUD",
  "SALUD TOTAL",
  "EPS DEL NORTE",
  "ENSAANAR",
  "CAJA SALUD",
  "MUTUAL SER",
  "SOS",
  "COMPARTA",
  "BARRIOS UNIDOS",
  "FAMISANAR",
  "SALUD VIDA",
  "PUBLISALUD",
];
