import { Layout, Menu, Button, Sider, Row, Col, Card, Form, Input } from "antd";
import Cookies from "universal-cookie";
import { isValidElement, useState } from "react";
import { MSJOK, MSJERROR } from "./Alerts";
import { GetUsersLogin } from "./entity/helpers";
import styles from "./Forms/css/form.module.css";
//logos
import BarrancasLogo from "./../Assets/img/logoempresa.png";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import {
  AppstoreOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  DesktopOutlined,
  ContainerOutlined,
  MailOutlined,
} from "@ant-design/icons";
import Vivienda from "./Forms/Vivienda";
import Personas from "./Forms/Personas";
import PersonasCultura from "./Forms/PersonasCultura";
import Acceso from "./Forms/Acceso";
import Identificacion from "./Forms/Identificacion";
import DatosHogar from "./Forms/DatosHogar";
import Vulnerabilidad from "./Forms/Vulnerabilidad";
import Migracion from "./Forms/Migracion";
import Salud from "./Forms/Salud";
import Educacion from "./Forms/Educacion";
import Ocupacion from "./Forms/Ocupacion";
import Desocupados from "./Forms/Desocupados";
import OtrasActividades from "./Forms/OtrasActividades";
import OtrosIngresos from "./Forms/OtrosIngresos";
import Organismos from "./Forms/Organismos";
import Exportacion from "./Forms/Exportacion";
import Reporte from "./Forms/Reporte";
import { items } from "./helpers/helperforms";

const forms = [
  { id: 1, component: <Acceso /> },
  { id: 2, component: <Identificacion /> },
  { id: 3, component: <Vivienda /> },
  { id: 4, component: <DatosHogar /> },
  { id: 5, component: <Personas /> },
  { id: 6, component: <PersonasCultura /> },
  { id: 7, component: <Vulnerabilidad /> },
  { id: 8, component: <Migracion /> },
  { id: 9, component: <Salud /> },
  { id: 10, component: <Educacion /> },
  { id: 11, component: <Ocupacion /> },
  { id: 12, component: <Desocupados /> },
  { id: 13, component: <OtrasActividades /> },
  { id: 14, component: <OtrosIngresos /> },
  { id: 15, component: <Organismos /> },
  { id: 16, component: <Exportacion /> },
  { id: 17, component: <Reporte /> },
];

const { Header, Content } = Layout;

export const InitialDash = () => {
  const cookies = new Cookies();

  const [formselected, setformselected] = useState(1);
  const [collapsed, setCollapsed] = useState(false);
  const [form, setForm] = useState(
    cookies.get("usuario") ? cookies.get("usuario") : null
  );
  const [login, setLogin] = useState(cookies.get("usuario") ? true : false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({
      ...form,
      [id]: value,
    });
  };

  const handleLogin = () => {
    if (form) {
      if (form.usuario) {
        GetUsersLogin(
          form.usuario,
          form.contrasena,
          new Date()
            .getDay()
            .toLocaleString("es-ES", { timeZone: "America/Bogota" }),
          new Date()
            .getHours()
            .toLocaleString("es-ES", { timeZone: "America/Bogota" })
        ).then((response) => {
          if (response.success) {
            cookies.set("usuario", response.data);
            setForm(response.data);
            setLogin(true);
          } else {
            cookies.remove("usuario");
            setLogin(false);
            setForm(null);
            MSJERROR(response.error);
          }
        });
      }
    }
  };

  const handleLogout = () => {
    cookies.remove("usuario");
    setLogin(false);
    setForm(null);
  };
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  function handleChangeForm(e) {
    setformselected(parseInt(e.key));
  }

  function showForm() {
    let filtercomponent = forms.filter((form) => form.id == formselected);
    return filtercomponent[0].component;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {login ? (
        <>
          <Layout.Sider
            style={{ background: "#1f6cda" }}
            collapsible
            collapsed={collapsed}
            onCollapse={toggleCollapsed}
          >
            <div className="logo" />
            <Menu
              theme="dark"
              style={{ background: "#1f6cda", color: "white" }}
              mode="inline"
              inlineCollapsed={collapsed}
              defaultSelectedKeys={["1"]}
              onClick={(e) => handleChangeForm(e)}
            >
              {items.map((item) => {
                return <Menu.Item style={{background: item.background, color: item.color}} key={item.id}>{item.name}</Menu.Item>;
              })}
            </Menu>
          </Layout.Sider>
          <Layout className="site-layout">
            <Content style={{ padding: "0 50px" }}>
              <div className="site-layout-content">{showForm()}</div>
            </Content>
          </Layout>
        </>
      ) : (
        <>
          <Layout className="site-layout">
            <Content style={{ padding: "0 50px" }}>
              <div className="site-layout-content">
                <Row
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  style={{ marginTop: 10 }}
                >
                  {/* <Col className="gutter-row" span={4} offset={5}> */}
                  <Col offset={9} className="gutter-row">
                    <div>
                      <img
                        width="50%"
                        src={BarrancasLogo}
                        alt="BarrancasLogo"
                      />
                    </div>
                  </Col>
                </Row>
                <Row gutter={24} justify="center">
                  <Card
                    className={styles.cardDashboardgeneral}
                    size="large"
                    title="INGRESO CREDENCIALES"
                  >
                    <Row justify="center">
                      <Col span={10}>
                        <Form name="basic1" initialValues={{ remember: true }}>
                          {cookies.get("usuario") ? (
                            <label>
                              Bienvenido:{" "}
                              {cookies.get("usuario").nombre +
                                " " +
                                cookies.get("usuario").apellidos}
                            </label>
                          ) : (
                            <>
                              <Form.Item label="Usuario">
                                <Input
                                  size="small"
                                  placeholder="Ingrese Usuario"
                                  id="usuario"
                                  value={
                                    form
                                      ? form.usuario
                                        ? form.usuario
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Item>
                              <Form.Item label="Contraseña">
                                <Input.Password
                                  size="small"
                                  placeholder="Ingrese contraseña"
                                  id="contrasena"
                                  value={
                                    form
                                      ? form.contrasena
                                        ? form.contrasena
                                        : ""
                                      : ""
                                  }
                                  onChange={handleChange}
                                />
                              </Form.Item>
                            </>
                          )}
                        </Form>
                      </Col>
                    </Row>
                  </Card>
                </Row>

                <Row gutter={24} justify="center">
                  <Card className={styles.cardDashboardgeneral} size="large">
                    <Row justify="center">
                      {login ? (
                        <Col>
                          <Button
                            type="danger"
                            disabled={login ? false : true}
                            icon={<LogoutOutlined />}
                            size="large"
                            shape="round"
                            onClick={() => handleLogout()}
                          >
                            Cerrar Sesion
                          </Button>
                        </Col>
                      ) : (
                        <Col>
                          <Button
                            type="primary"
                            disabled={login ? true : false}
                            icon={<LoginOutlined />}
                            size="large"
                            shape="round"
                            onClick={() => handleLogin()}
                          >
                            Ingresar
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Row>
              </div>
            </Content>
          </Layout>
        </>
      )}
    </Layout>
  );
};
