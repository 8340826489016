import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Row, Card, Form, Input, Select, Radio, Button, Switch } from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import {
  SaveMigracion,
  GetMigracionCodigo,
  UpdateMigracion,
} from "../entity/helpers";
import styles from "./css/form.module.css";
import { SaveOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import {
  donde_vivio_opciones,
  motivos_migraciones,
} from "../helpers/helperforms";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const Migracion = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  var personas = cookies.get("migracion") ? cookies.get("migracion") : [];
  const [form, setForm] = useState(formulario);
  const [donde_vivio, setDondeVivio] = useState(donde_vivio_opciones[0]);
  const [edicion, setEdicion] = useState(false);
  const [n_orden_search, setNOrdenSearch] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  function handleSave() {
    SaveMigracion(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
        personas = personas.filter(function (value, index, arr) {
          return value.id != form.n_orden;
        });
        cookies.remove("migracion");
        cookies.set("migracion", personas);
        setForm(formulario);
        setDondeVivio(donde_vivio_opciones[0]);
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleUpdate = () => {
    UpdateMigracion(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setDondeVivio(donde_vivio_opciones[0]);
        setNOrdenSearch(null);
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setCodigo(null);
    setEdicion(false);
    setNOrdenSearch(null);
    setForm(formulario);
    setDondeVivio(donde_vivio_opciones[0]);
    setNOrdenSearch(null);
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleOrden = (e) => {
    const { id, value } = e.target;
    setNOrdenSearch(value);
  };

  const handleSearch = () => {
    if (codigo && n_orden_search) {
      GetMigracionCodigo(codigo, n_orden_search).then((response) => {
        if (response[0]) {
          setForm(response[0]);
          setEdicion(true);
          if (
            donde_vivio_opciones.indexOf(response[0].donde_vivio) == -1 &&
            response[0].donde_vivio != null
          ) {
            setDondeVivio(
              donde_vivio_opciones[donde_vivio_opciones.length - 1]
            );
          }
        } else {
          setCodigo(null);
          setNOrdenSearch(null);
          setForm(formulario);
          setDondeVivio(donde_vivio_opciones[0]);
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  const selectDondeVivio = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      donde_vivio: value,
    });
    setDondeVivio(value);
  };

  const inputDondeVivio = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      donde_vivio: value,
    });
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form) {
      if (name === "migracion") {
        if (value == parseInt(form[name])) {
          setForm({
            ...form,
            donde_vivio: "",
            motivo_migracion: "",
            migracion: "",
          });
          setDondeVivio(donde_vivio_opciones[0]);
        }
      } else {
        if (value == form[name]) {
          setForm({
            ...form,
            [name]: "",
          });
        }
      }
    }
  };

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setForm({
        ...form,
        [name]: value,
      });
    } else {
      setForm({
        ...form,
        motivo_migracion: e,
      });
    }
  };
  return (
    <>
      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title={
            <div>
              F. MIGRACION
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por: </label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Input
                    id="n_orden"
                    placeholder={"# Orden"}
                    onChange={handleOrden}
                    value={n_orden_search ? n_orden_search : ""}
                    style={{ width: "10%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
        >
          <Row justify="center">
            <Form layout="vertical" initialValues={{ remember: true }}>
              {!editBool ? (
                <Form.Item label="Numero de Orden">
                  <select
                    className="ant-input"
                    name="n_orden"
                    value={form ? (form.n_orden ? form.n_orden : "") : ""}
                    onChange={handleChange}
                    placeholder="Seleccione una opción"
                    defaultValue={0}
                  >
                    {personas.map((num) => {
                      return <option value={num.id}>{num.value}</option>;
                    })}
                  </select>
                </Form.Item>
              ) : null}
              <Form.Item label="1. Siempre ha Vivido en este Municipio">
                <Radio.Group
                  name="migracion"
                  value={
                    form
                      ? parseInt(form.migracion) >= 0
                        ? parseInt(form.migracion)
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                >
                  <Radio onClick={handleClick} value={1}>
                    Si
                  </Radio>
                  <Radio onClick={handleClick} value={0}>
                    No
                  </Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item label="2. Donde Vivia hace 3 años">
                <select
                  className="ant-input"
                  name="donde_vivio"
                  value={form ? (form.donde_vivio ? form.donde_vivio : "") : ""}
                  onChange={selectDondeVivio}
                  disabled={
                    form ? (parseInt(form.migracion) > 0 ? true : false) : true
                  }
                  placeholder="Seleccione una opción"
                >
                  {donde_vivio_opciones.map((donde_vivio) => {
                    return <option value={donde_vivio}>{donde_vivio}</option>;
                  })}
                </select>
                {donde_vivio ===
                donde_vivio_opciones[donde_vivio_opciones.length - 1] ? (
                  <label>
                    Otra residencia:{" "}
                    <Input name="otro_residencia" onChange={inputDondeVivio} />
                  </label>
                ) : null}
              </Form.Item>
              <Form.Item label="3. Cual fue el principal Motivo por el que cambio su lugar de residencia">
                <select
                  name="motivo_migracion"
                  className="ant-input"
                  value={
                    form
                      ? form.motivo_migracion
                        ? form.motivo_migracion
                        : ""
                      : ""
                  }
                  onChange={handleChange}
                  disabled={
                    form ? (parseInt(form.migracion) > 0 ? true : false) : true
                  }
                  placeholder="Seleccione una opción"
                >
                  {motivos_migraciones.map((motivo_migracion) => {
                    return (
                      <option value={motivo_migracion}>
                        {motivo_migracion}
                      </option>
                    );
                  })}
                </select>
              </Form.Item>
            </Form>
          </Row>
        </Card>
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="danger"
                  disabled={
                    form ? (parseInt(form.n_orden) > 0 ? false : true) : true
                  }
                  icon={<SaveOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Migracion;
