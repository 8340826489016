import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Row, Card, Form, Input, Select, Radio, Button } from "antd";
import ReactExport from "react-export-excel";
import { MSJOK, MSJERROR } from "../Alerts";
import { GetCapturadorCodigo, GetAllCapturador } from "../entity/helpers";
import styles from "./css/form.module.css";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Migracion = () => {
  const dateToday = new Date();
  const [form, setForm] = useState(null);
  const [excel, setExcel] = useState([]);

  const handleSearch = () => {
    if (form) {
      if (form.codigo) {
        GetCapturadorCodigo(form.codigo).then((response) => {
          if (response[0]) {
            setExcel(response);
            MSJOK("Informacion cargada y lista para procesar.");
          } else {
            setExcel([]);
            setForm(null);
            MSJERROR("No existe formulario con codigo: " + form.codigo);
          }
        });
      }
    }
  };

  const handleGetExportacion = () => {
    GetAllCapturador().then((response) => {
      if (response[0]) {
        setExcel(response);
        MSJOK("Informacion cargada y lista para procesar.");
      } else {
        setExcel([]);
        setForm(null);
        MSJERROR("No existe formulario con codigo: " + form.codigo);
      }
    });
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({
      ...form,
      [id]: value,
    });
  };

  return (
    <>
      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="EXPORTACION"
        >
          <h1>Ingrese el codigo del formulario:</h1>
          <Form name="basic1" initialValues={{ remember: true }}>
            <Form.Item label="Codigo Formulario" name="codigo">
              <Input
                id="codigo"
                value={form ? (form.codigo ? form.codigo : "") : ""}
                style={{ width: "50%" }}
                onChange={handleChange}
              />
              <Button
                type="primary"
                disabled={form ? (form.codigo ? false : true) : true}
                shape="circle"
                onClick={handleSearch}
                icon={<SearchOutlined />}
              />
              {" ó "}
              <Button
                variant="contained"
                color="secondary"
                shape="circle"
                onClick={handleGetExportacion}
              >
                Todo
              </Button>
            </Form.Item>
          </Form>
          <ExcelFile
            element={
              <Button
                variant="contained"
                disabled={excel.length > 0 ? false : true}
                icon={<DownloadOutlined />}
                color="secondary"
                size="large"
              >
                Descargar
              </Button>
            }
            filename={"capturador-" + dateToday.toDateString()}
          >
            <ExcelSheet data={excel} name={"plantilla"}>
              <ExcelColumn label="N FORMULARIO" value="codigo" />

              <ExcelColumn label="FECHA CREACION" value="fecha_creacion" />

              <ExcelColumn label="DEPARTAMENTO" value="departamento" />

              <ExcelColumn label="MUNICIPIO" value="municipio" />

              <ExcelColumn label="BARRIO" value="barrio" />

              <ExcelColumn label="CLASE" value="clase" />

              <ExcelColumn label="ENCUESTADOR" value="encuestador" />

              <ExcelColumn label="DIGITADOR" value="digitador" />

              <ExcelColumn label="SUPERVISOR" value="supervisor" />

              <ExcelColumn label="DIRECCION" value="direccion" />

              <ExcelColumn label="ESTRATO" value="estrato" />

              <ExcelColumn label="FECHA" value="fecha" />

              <ExcelColumn label="MANZANA" value="manzana" />

              <ExcelColumn label="SECCION" value="seccion" />

              <ExcelColumn label="SECTOR" value="sector" />

              <ExcelColumn label="TELEFONO" value="telefono" />

              <ExcelColumn label="OBSERVACION" value="observacion" />

              <ExcelColumn label="TIPO VIVIENDA" value="tipo_vivienda" />

              <ExcelColumn label="PAREDES" value="paredes" />

              <ExcelColumn label="PISOS" value="pisos" />

              <ExcelColumn label="SERVICIO ENERGIA" value="tiene_energia" />

              <ExcelColumn label="ESTRATO ENERGIA" value="servicio_energia" />

              <ExcelColumn label="SERVICIO GAS" value="servicio_gas" />

              <ExcelColumn
                label="SERVICIO ALCANTARILLADO"
                value="servicio_alcantarillado"
              />

              <ExcelColumn label="SERVICIO BASURA" value="tiene_basura" />

              <ExcelColumn
                label="NUMERO RECOLECCION BASURA"
                value="servicio_basura"
              />

              <ExcelColumn
                label="SERVICIO ACUEDUCTO"
                value="servicio_acueducto"
              />

              <ExcelColumn
                label="SERVICIO INTERNET"
                value="servicio_internet"
              />

              <ExcelColumn label="AGUA SIEMPRE" value="agua_siempre" />

              <ExcelColumn label="CONDICIONES VIAS" value="condiciones_vias" />

              <ExcelColumn label="UBICACION BARRIO" value="ubicacion_barrio" />

              <ExcelColumn label="MEDIO TRANSPORTE" value="medio_transporte" />

              <ExcelColumn
                label="ALUMBRADO PUBLICO"
                value="alumbrado_publico"
              />

              <ExcelColumn label="SEGURIDAD" value="seguridad" />

              <ExcelColumn
                label="NUMERO HABITACIONES"
                value="numero_habitaciones"
              />

              <ExcelColumn label="NUMERO USUARIOS" value="numero_usuarios" />

              <ExcelColumn
                label="SERVICIO SANITARIO"
                value="servicio_sanitario"
              />

              <ExcelColumn label="USO SANITARIO" value="uso_sanitario" />

              <ExcelColumn label="SACAR BASURA" value="sacar_basura" />

              <ExcelColumn label="AGUA HOGAR" value="agua_hogar" />

              <ExcelColumn label="DONDE COCINAN" value="donde_cocinan" />

              <ExcelColumn
                label="COMBUSTIBLE COCINA"
                value="combustible_cocina"
              />

              <ExcelColumn label="TIPO HOGAR" value="tipo_hogar" />

              <ExcelColumn
                label="VALOR AMORTIZACION"
                value="valor_amortizacion"
              />

              <ExcelColumn label="VALOR ARRIENDO" value="valor_arriendo" />

              <ExcelColumn
                label="TIEMPO RESIDENCIA"
                value="tiempo_residencia"
              />

              <ExcelColumn label="N ORDEN" value="num_orden" />

              <ExcelColumn
                label="TIPO IDENTIFICACION"
                value="tipo_identificacion"
              />

              <ExcelColumn label="IDENTIFICACION" value="identificacion" />

              <ExcelColumn label="NOMBRE" value="nombre" />

              <ExcelColumn label="PARENTESCO" value="parentesco" />

              <ExcelColumn label="ESTADO CIVIL" value="estado_civil" />

              <ExcelColumn label="SEXO" value="sexo" />

              <ExcelColumn label="FECHA NACIMIENTO" value="fecha_nacimiento" />

              <ExcelColumn label="EDAD" value="edad" />

              <ExcelColumn label="ETNIA" value="etnia" />

              <ExcelColumn label="CULTURA" value="cultura" />

              <ExcelColumn label="IDIOMA" value="idioma" />

              <ExcelColumn label="COMUNIDAD LGBT" value="comunidad_lgbt" />

              <ExcelColumn label="RELIGION" value="religion" />

              <ExcelColumn
                label="COMUNIDAD CULTURAL"
                value="comunidad_cultural"
              />

              <ExcelColumn label="GRUPO BAILE" value="grupo_baile" />

              <ExcelColumn label="GRUPO TEATRO" value="grupo_teatro" />

              <ExcelColumn label="DEZPLAZADO" value="dezplazado" />

              <ExcelColumn label="VICTIMA ESTADO" value="victima_estado" />

              <ExcelColumn
                label="MINORIAS POLITICAS"
                value="minorias_politicas"
              />

              <ExcelColumn label="HABITANTE CALLE" value="habitante_calle" />

              <ExcelColumn
                label="MUJERES EMBARAZADAS"
                value="mujeres_embarazadas"
              />

              <ExcelColumn label="CABEZA HOGAR" value="cabeza_hogar" />

              <ExcelColumn
                label="PERSONAS ESPECIALES"
                value="personas_especiales"
              />

              <ExcelColumn
                label="DISCAPACIDAD COGNITIVA"
                value="discapacidad_cognitiva"
              />

              <ExcelColumn
                label="SUSTANCIAS SICOACTIVAS"
                value="sustancias_sicoactivas"
              />

              <ExcelColumn label="VIH" value="vih" />

              <ExcelColumn label="TRATAMIENTO VIH" value="tratamiento_vih" />

              <ExcelColumn label="REGIMEN VIH" value="regimen_vih" />

              <ExcelColumn
                label="VIH ANTIRETROVIRAL"
                value="vih_antiretroviral"
              />

              <ExcelColumn label="CANCER" value="cancer" />

              <ExcelColumn
                label="TRATAMIENTO CANCER"
                value="tratamiento_cancer"
              />

              <ExcelColumn label="REGIMEN CANCER" value="regimen_cancer" />

              <ExcelColumn
                label="CANCER ANTIRETROVIRAL"
                value="cancer_antiretroviral"
              />

              <ExcelColumn label="TALLA BAJA" value="talla_baja" />

              <ExcelColumn
                label="DISCAPACIDAD VISUAL"
                value="discapacidad_visual"
              />

              <ExcelColumn
                label="DISCAPACIDAD AUDITIVA"
                value="discapacidad_auditiva"
              />

              <ExcelColumn
                label="TRABAJADORAS SEXUALES"
                value="trabajadoras_sexuales"
              />

              <ExcelColumn label="ZONAS MARGINALES" value="zonas_marginales" />

              <ExcelColumn label="MIGRACION" value="migracion" />

              <ExcelColumn label="DONDE VIVIO" value="donde_vivio" />

              <ExcelColumn label="MOTIVO MIGRACION" value="motivo_migracion" />

              <ExcelColumn label="COTIZANTE" value="cotizante" />

              <ExcelColumn label="REGIMEN" value="regimen" />

              <ExcelColumn label="EPS" value="eps_regimen" />

              <ExcelColumn label="QUIEN PAGA" value="quien_paga" />

              <ExcelColumn label="CALIDAD SERVICIO" value="calidad_servicio" />

              <ExcelColumn label="CONTAGIO COVID" value="contagio_covid" />

              <ExcelColumn label="ATENCION COVID" value="atencion_covid" />

              <ExcelColumn label="VACUNA COVID" value="vacuna_covid" />

              <ExcelColumn label="RAZON" value="razon" />

              <ExcelColumn label="N DOSIS" value="n_dosis" />

              <ExcelColumn label="FECHA SEG DOSIS" value="fecha_seg_dosis" />

              <ExcelColumn label="SINTOMAS DOSIS" value="sintomas_dosis" />

              <ExcelColumn label="FIEBRE" value="fiebre" />
              <ExcelColumn label="DOLOR DE CABEZA" value="dolor_cabeza" />
              <ExcelColumn label="VOMITOS" value="vomitos" />
              <ExcelColumn label="MALESTAR GENERAL" value="malestar_general" />
              <ExcelColumn label="OTRO" value="sintoma_vac_otro" />
              <ExcelColumn label="CUAL" value="sintoma_vac_cual" />

              <ExcelColumn label="LEER ESCRIBIR" value="leer_escribir" />

              <ExcelColumn
                label="ACTUALMENTE ESTUDIA"
                value="actualmente_estudia"
              />

              <ExcelColumn
                label="PROBLEMAS CLASES VIRTUALES"
                value="problemas_clases_virtuales"
              />

              <ExcelColumn label="DEJAR ESTUDIOS" value="dejar_estudios" />

              <ExcelColumn
                label="NIVEL EDUCATIVO RECIENTE"
                value="nivel_educativo_reciente"
              />

              <ExcelColumn label="NIVEL EDUCATIVO" value="nivel_educativo" />

              <ExcelColumn label="ACTIVIDAD" value="actividad" />

              <ExcelColumn label="OTRA ACTIVIDAD" value="otra_actividad" />

              <ExcelColumn label="FUNCION" value="funcion" />

              <ExcelColumn label="NOMBRE EMPRESA" value="nombre_empresa" />

              <ExcelColumn
                label="ACTIVIDAD LABORAL"
                value="actividad_laboral"
              />

              <ExcelColumn label="TIPO CONTRATO" value="tipo_contrato" />

              <ExcelColumn label="PRIMAS DE SERVICIO" value="primas_servicio" />
              <ExcelColumn
                label="PRIMA DE VACACIONES"
                value="prima_vacaciones"
              />
              <ExcelColumn
                label="VACACIONES REMUNERADAS"
                value="vacaciones_remuneradas"
              />
              <ExcelColumn label="CESANTIAS" value="cesantias" />

              <ExcelColumn label="INGRESOS" value="ingresos" />

              <ExcelColumn label="AFECTADO COVID" value="afectado_covid" />

              <ExcelColumn
                label="TIPO AFECTACION COVID"
                value="tipo_afectacion_covid"
              />

              <ExcelColumn
                label="SE REDUJO SU PRODUCCIÓN LABORAL"
                value="afectaciones_covid_reduccion_produccion"
              />

              <ExcelColumn
                label="SE REDUJERON SUS GANANCIAS O INGRESOS  ECONÓMICOS"
                value="afectaciones_covid_reduccion_ganancias"
              />

              <ExcelColumn
                label="TUVO QUE DESPEDIR DE SU NEGOCIO O EMPRESA"
                value="afectaciones_covid_despidio_negocio"
              />

              <ExcelColumn
                label="TUVO QUE CERRAR ALGÚN LOCAL O PLANTA DE PRODUCCIÓN"
                value="afectaciones_covid_cerrar_negocio"
              />

              <ExcelColumn
                label="PERDIERON EMPLEOS COMPAÑEROS DE TRABAJO"
                value="afectaciones_covid_companeros_desempleados"
              />

              <ExcelColumn label="FONDO PENSION" value="fondo_pension" />

              <ExcelColumn
                label="APOYO CON CRÉDITOS A MICROEMPRESARIOS"
                value="reactivacion_economica_apoyo_creditos"
              />
              <ExcelColumn
                label="ALIVIOS TRIBUTARIOS A LOS COMERCIANTES DEL MUNICIPIO"
                value="reactivacion_economica_alivios_tributarios"
              />
              <ExcelColumn
                label="CREACIÓN DE MÁS AYUDAS SOCIALES"
                value="reactivacion_economica_creacion_ayudas"
              />
              <ExcelColumn
                label="MAYOR APOYO AL CAMPO Y A LOS PROGRAMAS AGROPECUARIOS"
                value="reactivacion_economica_mayor_apoyo"
              />
              <ExcelColumn
                label="NUEVAS OBRAS DE INFRAESTRUTURA PÚBLICA"
                value="reactivacion_economica_nuevas_obras"
              />
              <ExcelColumn label="OTRA" value="reactivacion_economica_otra" />
              <ExcelColumn label="CUAL" value="reactivacion_economica_cual" />

              <ExcelColumn
                label="AMIGOS AFECTADOS COVID"
                value="amigos_afectados_covid"
              />

              <ExcelColumn
                label="CRISIS ECONOMICA COVID"
                value="crisis_economica_covid"
              />

              <ExcelColumn label="COMERCIO" value="comercio" />
              <ExcelColumn label="INDUSTRIA" value="industria" />
              <ExcelColumn
                label="CONSTRUCCION E INFRAESTRUCTURA"
                value="construccion_infraestructura"
              />
              <ExcelColumn label="SERVICIOS" value="servicios" />
              <ExcelColumn label="AGROPECUARIO" value="agropecuario" />
              <ExcelColumn label="OTRO SECTOR" value="sector_economico_otro" />
              <ExcelColumn label="CUAL" value="sector_economico_cual" />

              <ExcelColumn label="BUSCADO TRABAJO" value="buscado_trabajo" />

              <ExcelColumn label="TIEMPO BUSQUEDA" value="tiempo_busqueda" />

              <ExcelColumn
                label="MIN ASPIRACION SALARIAL"
                value="min_aspiracion_salarial"
              />

              <ExcelColumn
                label="OCUPACION BUSQUEDA"
                value="ocupacion_busqueda"
              />

              <ExcelColumn label="PRIMERA BUSQUEDA" value="primera_busqueda" />

              <ExcelColumn
                label="SU ULTIMO  EMPLEO LO PERDIO  POR LA PANDEMIA COVID 19"
                value="desempleo_covid"
              />

              <ExcelColumn
                label="CIERRE DE EMPRESA DONDE TRABAJABAPOR LA COVID"
                value="empresa_covid"
              />

              <ExcelColumn
                label="PERDIDA DE EMPLEO DE USTED O DE LOS COMPAÑEROS POR MEDIDDAS ADOPTADAS EN LA PANDEMIA"
                value="otros_afectados_covid"
              />

              <ExcelColumn
                label="LABORES CAMPO O CRIA DE ANIMALES"
                value="labores_campo"
              />

              <ExcelColumn label="OFICIO HOGAR" value="oficio_hogar" />

              <ExcelColumn label="OFICIO LUGARES" value="oficio_lugares" />

              <ExcelColumn label="CUIDAR MENORES" value="cuidar_menores" />

              <ExcelColumn
                label="CUIDAR PERSONAS MAYORES, DISCAPACITADAS O ENFERMAS"
                value="cuidar_mayores"
              />

              <ExcelColumn
                label="ELABORACION PRENDAS  DE VESTIR"
                value="vestir_hogar"
              />

              <ExcelColumn
                label="ASISTENCIA A CURSOS O EVENTOS"
                value="cursos"
              />

              <ExcelColumn
                label="TRABAJA EN CONSTRUCION DE VIVIENDA"
                value="auto_construccion"
              />

              <ExcelColumn
                label="PARTICIPACION EN TRABAJOS COMUNITARIOS"
                value="trabajos_comunitarios"
              />

              <ExcelColumn
                label="OTRAS ACTIVIDADES"
                value="otras_actividades"
              />

              <ExcelColumn
                label="RECIBIO INGRESOS EN EL MES PASADO"
                value="recibio_ingresos"
              />

              <ExcelColumn
                label="PAGOS POR ARRIENDOS"
                value="pagos_arriendos"
              />

              <ExcelColumn label="PAGOS POR PENSIONES" value="pensiones" />

              <ExcelColumn label="PAGOS POR SUBSIDIOS" value="subsidios" />

              <ExcelColumn
                label="SUBSIDIO DE FAMILIAS ACCION"
                value="familias_accion"
              />

              <ExcelColumn
                label="SUBSIDIO DE JOVENES ACCION"
                value="jovenes_accion"
              />

              <ExcelColumn
                label="SUBSIDIO DE ADULTO MAYOR"
                value="adulto_mayor"
              />

              <ExcelColumn label="OTRO TIPO DE SUBSIDIO" value="otro" />

              <ExcelColumn
                label="NOMBRE DEL OTRO TIPO DE SUBSIDIO"
                value="otro_cual"
              />

              <ExcelColumn
                label="ALCALDÍA MUNICIPAL"
                value="organismos_institucionales_alcaldia_municipal"
              />
              <ExcelColumn
                label="REGISTRADURIA DEL SERVICIO CIVIL"
                value="organismos_institucionales_registraduria_civil"
              />
              <ExcelColumn
                label="PERSONERÍA MUNICIPAL"
                value="organismos_institucionales_personeria"
              />
              <ExcelColumn
                label="INSTITUTO COLOMBIANO DE BIENESTAR FAMLIAR"
                value="organismos_institucionales_icbf"
              />
              <ExcelColumn
                label="OTRO ORGANISMO"
                value="organismos_institucionales_otro"
              />
              <ExcelColumn
                label="CUAL"
                value="organismos_institucionales_cual"
              />

              <ExcelColumn
                label="FRECUENCIA ASISTENCIA"
                value="frecuencia_asistencia"
              />

              <ExcelColumn
                label="GESTIONES Y/O TRÁMITES PERSONALES "
                value="razon_asistencia_gestiones"
              />
              <ExcelColumn
                label="GESTIONES EN PRO DE LA COMUNIDAD"
                value="razon_asistencia_comunidad"
              />
              <ExcelColumn
                label="VULNERACION DE DERECHOS HUMANOS."
                value="razon_asistencia_vulnerabilidad"
              />
              <ExcelColumn
                label="QUEJAS Y RECLAMOS"
                value="razon_asistencia_qr"
              />

              <ExcelColumn label="OTRO" value="razon_asistencia_otro" />
              <ExcelColumn label="CUAL" value="razon_asistencia_cual" />

              <ExcelColumn
                label="CALIFICACION SERVICIO"
                value="calificacion_servicio"
              />

              <ExcelColumn label="AMBIENTE COVID" value="ambiente_covid" />

              <ExcelColumn
                label="INVERSIÓN EN SALUD Y EDUCACIÓN"
                value="espectativas_inversion_salud_educacion"
              />
              <ExcelColumn
                label="PROYECTOS DE VIVIENDA"
                value="espectativas_proyectos_vivienda"
              />
              <ExcelColumn
                label="PAVIMENTACION DE VÍAS"
                value="espectativas_pavimentacion_vias"
              />
              <ExcelColumn
                label="PROGRAMAS DE EMPRENDIMIENTO Y CREACIÓN DE EMPLEO"
                value="espectativas_programas_emprendimiento_creacion_empleo"
              />
              <ExcelColumn
                label="APOYO AL EMPRESARIO LOCAL"
                value="espectativas_apoyo_empresario_local"
              />
              <ExcelColumn
                label="SANAMIENTO BÁSICO"
                value="espectativas_sanamiento_basico"
              />
              <ExcelColumn
                label="PROGRAMAS AGROPECUARIOS Y APOYO AL CAMPESINADO"
                value="espectativas_programas_agropecuarios_apoyo_campesinado"
              />

              <ExcelColumn label="OTRO" value="espectativas_otro" />
              <ExcelColumn label="CUAL" value="espectativas_cual" />

              <ExcelColumn
                label="PERTENECE A UNA ASOCIACIÓN GREMIAL"
                value="pertenece_a_asociacion_gremial"
              />
              <ExcelColumn
                label="PERTENECE A UNA ASOCIACIÓN SINDICAL"
                value="pertenece_a_asociacion_sindical"
              />
              <ExcelColumn
                label="PERTENECE A UN PARTIDO O GRUPO POLÍTICO"
                value="pertenece_a_partido_grupo_politico"
              />
              <ExcelColumn
                label="PERTENECE A UN PARTIDO O GRUPO POLÍTICO JUNTA DE ACCIÓN COMUNAL"
                value="pertenece_a_junta_de_accion_comunal"
              />
              <ExcelColumn
                label="PERTENECE A CONCEJOS COMUNITARIOS"
                value="pertenece_a_concejos_comunitarios"
              />
              <ExcelColumn
                label="PERTENECE A GRUPOS JUVENILES"
                value="pertenece_a_grupos_juveniles"
              />
              <ExcelColumn
                label="PERTENECE A MUJERES LÍDERES"
                value="pertenece_a_mujeres_lideres"
              />

              <ExcelColumn label="OTRO" value="pertenece_a_otro" />
              <ExcelColumn label="CUAL" value="pertenece_a_cual" />

              <ExcelColumn
                label=" REUNIONES  EN PLAZAS CON LOS MIEMBROS DE SU ORGANIZACIÓN"
                value="reuniones"
              />

              <ExcelColumn
                label="CONOCE PROCESOS Y GESTION DE LA ADMINISTRACION MUNICIPAL"
                value="conoce_procesos"
              />

              <ExcelColumn
                label="SECRETARIA DE GOBIERNO"
                value="realizado_gestion_secretaria_gobierno"
              />
              <ExcelColumn
                label="SECRETARIA DE HACIENDA"
                value="realizado_gestion_secretaria_hacienda"
              />
              <ExcelColumn
                label="GESTIÓN SOCIAL"
                value="realizado_gestion_gestion_social"
              />
              <ExcelColumn
                label="PLANEACIÓN MUNICIPAL"
                value="realizado_gestion_planeacion_municipal"
              />
              <ExcelColumn
                label="SECRETARIA DE SALUD"
                value="realizado_gestion_secretaria_salud"
              />
              <ExcelColumn
                label="SECRETARIA DE EDUCACIÓN"
                value="realizado_gestion_secretaria_educacion"
              />

              <ExcelColumn label="OTRO" value="realizado_gestion_otro" />
              <ExcelColumn label="CUAL" value="realizado_gestion_cual" />

              <ExcelColumn label="ESTADO GESTION" value="estado_gestion" />
            </ExcelSheet>
          </ExcelFile>
        </Card>
      </Row>
    </>
  );
};

export default Migracion;
