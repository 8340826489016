import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Radio,
  Button,
  Switch,
} from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import {
  SaveDesocupado,
  UpdateDesocupado,
  GetDesocupadoCodigo,
} from "../entity/helpers";
import { SaveOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import styles from "./css/form.module.css";
import { actividades } from "../helpers/helperforms";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const Desocupados = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  var personas = cookies.get("desocupados")
    ? cookies.get("desocupados").filter(function (value, index, arr) {
        return (
          value.id == 0 ||
          (value.edad >= 15 &&
            (value.actividad === "" ||
              (value.actividad === actividades[2] &&
                value.otra_actividad == 0) ||
              (value.actividad === actividades[3] &&
                value.otra_actividad == 0) ||
              (value.actividad === actividades[4] &&
                value.otra_actividad == 0) ||
              (value.actividad === actividades[6] &&
                value.otra_actividad == 0)))
        );
      })
    : [];
  const [form, setForm] = useState(formulario);
  const [edicion, setEdicion] = useState(false);
  const [n_orden_search, setNOrdenSearch] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  function handleSave() {
    SaveDesocupado(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
        personas = personas.filter(function (value, index, arr) {
          return value.id != form.n_orden;
        });
        cookies.remove("desocupados");
        cookies.set("desocupados", personas);
        setForm(formulario);
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleUpdate = () => {
    UpdateDesocupado(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setNOrdenSearch(null);
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setCodigo(null);
    setEdicion(false);
    setNOrdenSearch(null);
    setForm(formulario);
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleOrden = (e) => {
    const { id, value } = e.target;
    setNOrdenSearch(value);
  };

  const handleSearch = () => {
    if (codigo && n_orden_search) {
      GetDesocupadoCodigo(codigo, n_orden_search).then((response) => {
        console.log(response)
        if (response[0]) {
          setForm(response[0]);
          setEdicion(true);
        } else {
          setCodigo(null);
          setNOrdenSearch(null);
          setForm(formulario);
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <>
      <Row gutter={24} justify="center">
        <Card
          title={
            <div>
              J. DESOCUPADOS
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por: </label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Input
                    id="n_orden"
                    placeholder={"# Orden"}
                    onChange={handleOrden}
                    value={n_orden_search ? n_orden_search : ""}
                    style={{ width: "10%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
          className={styles.cardDashboardgeneral}
          size="large"
        >
          <Row justify="center">
            <Form
              // layout="vertical"
              initialValues={{ remember: true }}
              //   onFinish={onFinish}
              //   onFinishFailed={onFinishFailed}
            >
              {!editBool ? (
                <Form.Item label="Numero de Orden">
                  <select
                    className="ant-input"
                    name="n_orden"
                    style={{ width: "60%" }}
                    value={form ? (form.n_orden ? form.n_orden : "") : ""}
                    onChange={handleChange}
                    placeholder="Seleccione una opción"
                    defaultValue={0}
                  >
                    {personas.map((num) => {
                      return <option value={num.id}>{num.value}</option>;
                    })}
                  </select>
                </Form.Item>
              ) : null}
              <Form.Item>
                <label>1. ¿Ha buscado Trabajo durante los últimos meses?</label>
                <Radio.Group name="buscado_trabajo" defaultValue={null} value={form ? (parseInt(form.buscado_trabajo) >= 0 ? parseInt(form.buscado_trabajo) : "") : ""} onChange={handleChange}>
                  <Radio onClick={handleClick} value={1}>Si</Radio>
                  <Radio onClick={handleClick} value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
              {form ? (
                form.buscado_trabajo ? (
                  <Form.Item>
                    <label>
                      2. ¿Durante cuántas semanas ha estado buscando trabajo?
                    </label>
                    <Input name="tiempo_busqueda" value={form ? (form.tiempo_busqueda ? form.tiempo_busqueda : "") : ""} onChange={handleChange} />
                  </Form.Item>
                ) : null
              ) : null}

              {form ? (
                form.buscado_trabajo ? (
                  <Form.Item>
                    <label>
                      3. En caso de que le ofrecieran un empleo a ... ¿Cuál
                      sería la remuneración o el salario mensual más bajo por el
                      que
                    </label>
                    <Input
                      name="min_aspiracion_salarial" value={form ? (form.min_aspiracion_salarial ? form.min_aspiracion_salarial : "") : ""}
                      onChange={handleChange}
                    />
                  </Form.Item>
                ) : null
              ) : null}

              {form ? (
                form.buscado_trabajo ? (
                  <Form.Item>
                    <label>
                      4. ¿En qué ocupación, oficio o labor ha buscado trabajo?{" "}
                    </label>
                    <Input name="ocupacion_busqueda" value={form ? (form.ocupacion_busqueda ? form.ocupacion_busqueda : "") : ""} onChange={handleChange} />
                  </Form.Item>
                ) : null
              ) : null}
            </Form>
          </Row>
        </Card>

        {form ? (
          form.buscado_trabajo ? (
            <Card className={styles.cardDashboardgeneral} size="large">
              <Row justify="center">
                <Form initialValues={{ remember: true }}>
                  <Form.Item>
                    <label>
                      5. ¿Ha buscado trabajo por primera vez o había trabajado
                      antes por lo menos durante dos semanas consecutivas?
                    </label>
                    <Radio.Group
                      name="primera_busqueda" value={form ? ((form.primera_busqueda) ? (form.primera_busqueda) : "") : ""}
                      onChange={handleChange}
                    >
                      <Radio onClick={handleClick} value={"Primera vez"}>Primera vez</Radio>
                      <Radio onClick={handleClick} value={"Trabajo antes"}>Trabajó antes</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {form ? (
                    form.primera_busqueda === "Trabajo antes" ? (
                      <>
                        <Form.Item>
                          <label>
                            6. ¿ Su último empleo lo perdió como Consecuencia de
                            las medidas adoptas a causa de la pandemia del Covid
                            19?
                          </label>
                          <Radio.Group
                            name="desempleo_covid" value={form ? (parseInt(form.desempleo_covid) >= 0 ? parseInt(form.desempleo_covid) : "") : ""}
                            onChange={handleChange}
                          >
                            <Radio onClick={handleClick} value={1}>Si</Radio>
                            <Radio onClick={handleClick} value={0}>No</Radio>
                          </Radio.Group>
                        </Form.Item>
                        {form ? (
                          form.desempleo_covid ? (
                            <>
                              <Form.Item>
                                <label>
                                  7. ¿ La empresa, negocio o finca, en donde
                                  trabajada cerró o se vió afectada como
                                  consecuencia de la pandemia a causa del Covid
                                  19
                                </label>
                                <Radio.Group
                                  name="empresa_covid" value={form ? (parseInt(form.empresa_covid) >= 0 ? parseInt(form.empresa_covid) : "") : ""}
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>Si</Radio>
                                  <Radio onClick={handleClick} value={0}>No</Radio>
                                </Radio.Group>
                              </Form.Item>
                              <Form.Item>
                                <label>
                                  8. ¿ Además de usted, otro u otros compañeros
                                  perdieron su empleo como Consecuencia de las
                                  medidas adoptas a causa de la pandemia del
                                  Covid 19?
                                </label>
                                <Radio.Group
                                  name="otros_afectados_covid" value={form ? (parseInt(form.otros_afectados_covid) >= 0 ? parseInt(form.otros_afectados_covid) : "") : ""}
                                  onChange={handleChange}
                                >
                                  <Radio onClick={handleClick} value={1}>Si</Radio>
                                  <Radio onClick={handleClick} value={0}>No</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </>
                          ) : null
                        ) : null}
                      </>
                    ) : null
                  ) : null}
                </Form>
              </Row>
            </Card>
          ) : null
        ) : null}
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={form ? (form.n_orden > 0 ? false : true) : true}
                  type="danger"
                  icon={<SaveOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Desocupados;
