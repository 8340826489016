import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Radio,
  Button,
  Switch,
} from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import {
  SaveOutlined,
  EditOutlined,
  CheckOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  SaveOrganismo,
  GetOrganismoCodigo,
  UpdateOrganismoInstitucional,
} from "../entity/helpers";
import styles from "./css/form.module.css";
import {
  frecuencia_asistencias,
  calificacion_servicio,
  ambiente_covid,
  estado_gestion,
  organismos_institucionales,
} from "../helpers/helperforms";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const Organismos = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  var personas = cookies.get("organismos")
    ? cookies.get("organismos").filter(function (value, index, arr) {
        return value.edad >= 15 || value.id == 0;
      })
    : [];
  const [form, setForm] = useState(formulario);
  const [terminate_bool, setTerminateBool] = useState(
    cookies.get("formulario") ? false : true
  );
  const [organismo_institucional, setOrganismosInstitucionales] = useState(-1);
  const [razon_asistencia_bool, setRazonAsistenciaBool] = useState(-1);
  const [pertenece_a_bool, setPerteneceABool] = useState(-1);
  const [realizado_gestion_bool, setRealizadoGestionBool] = useState(-1);
  const [frecuencia_asistencia, setFrecuenciaAsistencia] = useState(
    frecuencia_asistencias[0]
  );
  const [edicion, setEdicion] = useState(false);
  const [n_orden_search, setNOrdenSearch] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  function handleSave() {
    SaveOrganismo(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
        personas = personas.filter(function (value, index, arr) {
          return value.id != form.n_orden;
        });
        cookies.remove("organismos");
        cookies.set("organismos", personas);
        setForm(formulario);
        setOrganismosInstitucionales(-1);
        setFrecuenciaAsistencia(frecuencia_asistencias[0]);
        setRealizadoGestionBool(-1);
        setRazonAsistenciaBool(-1);
        setPerteneceABool(-1);
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleUpdate = () => {
    UpdateOrganismoInstitucional(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setNOrdenSearch(null);
        setOrganismosInstitucionales(-1);
        setFrecuenciaAsistencia(frecuencia_asistencias[0]);
        setRealizadoGestionBool(-1);
        setRazonAsistenciaBool(-1);
        setPerteneceABool(-1);
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setCodigo(null);
    setEdicion(false);
    setNOrdenSearch(null);
    setForm(formulario);
    setOrganismosInstitucionales(-1);
    setFrecuenciaAsistencia(frecuencia_asistencias[0]);
    setRealizadoGestionBool(-1);
    setRazonAsistenciaBool(-1);
    setPerteneceABool(-1);
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleOrden = (e) => {
    const { id, value } = e.target;
    setNOrdenSearch(value);
  };

  const handleSearch = () => {
    if (codigo && n_orden_search) {
      GetOrganismoCodigo(codigo, n_orden_search).then((response) => {
        if (response[0]) {
          setForm(response[0]);
          setEdicion(true);
          if (response[0].organismos_institucionales.toString() !== "0") {
            setOrganismosInstitucionales(1);
          }
          if (
            frecuencia_asistencias.indexOf(response[0].frecuencia_asistencia) ==
            -1
          ) {
            setFrecuenciaAsistencia(
              frecuencia_asistencias[frecuencia_asistencias.length - 1]
            );
          } else {
            setFrecuenciaAsistencia(response[0].frecuencia_asistencia);
          }
          if (parseInt(response[0].razon_asistencia) > 0) {
            setRazonAsistenciaBool(1);
          } else {
            setRazonAsistenciaBool(0);
          }
          if (parseInt(response[0].realizado_gestion) > 0) {
            setRealizadoGestionBool(1);
          } else {
            setRealizadoGestionBool(0);
          }
          if (parseInt(response[0].pertenece_a) > 0) {
            setPerteneceABool(1);
          } else {
            setPerteneceABool(0);
          }
        } else {
          setCodigo(null);
          setNOrdenSearch(null);
          setForm(formulario);
          setOrganismosInstitucionales(0);
          setFrecuenciaAsistencia(frecuencia_asistencias[0]);
          setRealizadoGestionBool(0);
          setRazonAsistenciaBool(0);
          setPerteneceABool(0);
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  function terminate() {
    setTerminateBool(true);
    cookies.remove("formulario");
    cookies.remove("personas");
    cookies.remove("personas2");
    cookies.remove("vulnerabilidad");
    cookies.remove("migracion");
    cookies.remove("salud");
    cookies.remove("educacion");
    cookies.remove("ocupacion");
    cookies.remove("desocupados");
    cookies.remove("otras_actividades");
    cookies.remove("otros_ingresos");
    cookies.remove("organismos");
    MSJOK("Formulario guardado completamente.");
  }

  const radioOrganismosIsntitucionales = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      organismos_institucionales: value,
    });
    setOrganismosInstitucionales(value);
  };

  const radioRazonAsistenciaBool = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      frecuencia_asistencia: value,
    });
    setRazonAsistenciaBool(value);
  };

  const radioPerteneceABool = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      pertenece_a: value,
    });
    setPerteneceABool(value);
  };

  const radioRealizadoGestionBool = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      realizado_gestion: value,
    });
    setRealizadoGestionBool(value);
  };

  const selectFrecuenciaAsistencia = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      frecuencia_asistencia: value,
    });
    setFrecuenciaAsistencia(value);
  };

  const inputFrecuenciaAsistencia = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      frecuencia_asistencia: value,
    });
  };

  const selectOrganismos = (value) => {
    setForm({
      ...form,
      organismos_institucionales: value.join(),
    });
  };

  const selectRazonAsistencia = (value) => {
    setForm({
      ...form,
      razon_asistencia: value.join(),
    });
  };

  const selectEspectativas = (value) => {
    setForm({
      ...form,
      espectativas: value.join(),
    });
  };

  const selectPerteneceA = (value) => {
    setForm({
      ...form,
      pertenece_a: value.join(),
    });
  };

  const selectRealizadoGestion = (value) => {
    setForm({
      ...form,
      realizado_gestion: value.join(),
    });
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (name === "organismos_institucionales") {
      if (value == organismo_institucional) setOrganismosInstitucionales("");
    }
    if (name === "pertenece_a") {
      if (value == pertenece_a_bool) setPerteneceABool("");
    }
    if (name === "realizado_gestion") {
      if (value == realizado_gestion_bool) setRealizadoGestionBool("");
    }
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <>
      <Row gutter={24} justify="center">
        <Card
          title={
            <div>
              M. ORGANISMOS INSTITUCIONALES (PERSONAS DE 15 AÑOS)
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por: </label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Input
                    id="n_orden"
                    placeholder={"# Orden"}
                    onChange={handleOrden}
                    value={n_orden_search ? n_orden_search : ""}
                    style={{ width: "10%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
          className={styles.cardDashboardgeneral}
          size="large"
        >
          <Row justify="center">
            <Col span={10}>
              <Form initialValues={{ remember: true }}>
                {!editBool ? (
                  <Form.Item>
                    <label>Numero de Orden: </label>
                    <select
                      className="ant-input"
                      name="n_orden"
                      value={form ? (form.n_orden ? form.n_orden : "") : ""}
                      onChange={handleChange}
                      placeholder="Seleccione una opción"
                      defaultValue={0}
                    >
                      {personas.map((num) => {
                        return <option value={num.id}>{num.value}</option>;
                      })}
                    </select>
                  </Form.Item>
                ) : null}
                <Form.Item>
                  <label>
                    1. Asiste o ha asistido a una de las siguientes
                    instituciones
                  </label>
                  <br></br>
                </Form.Item>
                <Form.Item>
                  <Radio.Group
                    name="organismos_institucionales"
                    value={
                      organismo_institucional >= 0
                        ? organismo_institucional
                        : ""
                    }
                    onChange={radioOrganismosIsntitucionales}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {organismo_institucional > 0 ? (
                  <Form.Item>
                    <Col offset={2}>
                      <Form.Item>
                        <label>a. Alcaldía Municipal: </label>
                        <Radio.Group
                          name="organismos_institucionales_alcaldia_municipal"
                          value={
                            form
                              ? parseInt(
                                  form.organismos_institucionales_alcaldia_municipal
                                ) >= 0
                                ? parseInt(
                                    form.organismos_institucionales_alcaldia_municipal
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>b. Registraduria del Servicio Civil: </label>
                        <Radio.Group
                          name="organismos_institucionales_registraduria_civil"
                          value={
                            form
                              ? parseInt(
                                  form.organismos_institucionales_registraduria_civil
                                ) >= 0
                                ? parseInt(
                                    form.organismos_institucionales_registraduria_civil
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>c. Personería Municipal: </label>
                        <Radio.Group
                          name="organismos_institucionales_personeria"
                          value={
                            form
                              ? parseInt(
                                  form.organismos_institucionales_personeria
                                ) >= 0
                                ? parseInt(
                                    form.organismos_institucionales_personeria
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>
                          d. Instituto Colombiano de Bienestar Famliar:{" "}
                        </label>
                        <Radio.Group
                          name="organismos_institucionales_icbf"
                          value={
                            form
                              ? parseInt(
                                  form.organismos_institucionales_icbf
                                ) >= 0
                                ? parseInt(form.organismos_institucionales_icbf)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>e. Otro: </label>
                        <Radio.Group
                          name="organismos_institucionales_otro"
                          value={
                            form
                              ? parseInt(
                                  form.organismos_institucionales_otro
                                ) >= 0
                                ? parseInt(form.organismos_institucionales_otro)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                        {form ? (
                          parseInt(form.organismos_institucionales_otro) > 0 ? (
                            <label>
                              Cual:{" "}
                              <Input
                                style={{ width: "40%" }}
                                value={
                                  form
                                    ? form.organismos_institucionales_cual
                                      ? form.organismos_institucionales_cual
                                      : ""
                                    : ""
                                }
                                name="organismos_institucionales_cual"
                                onChange={handleChange}
                              />
                            </label>
                          ) : null
                        ) : null}
                      </Form.Item>
                    </Col>
                  </Form.Item>
                ) : null}

                <Form.Item>
                  <label>
                    2. Con que frecuencia asiste o ha asistido a los organismos
                    institucionales
                  </label>
                  <br></br>
                  <select
                    className="ant-input"
                    value={frecuencia_asistencia ? frecuencia_asistencia : ""}
                    name="frecuencia_asistencia"
                    onChange={selectFrecuenciaAsistencia}
                    placeholder="Seleccione una opción"
                  >
                    {frecuencia_asistencias.map((element) => {
                      return <option value={element}>{element}</option>;
                    })}
                  </select>
                  {frecuencia_asistencia ===
                  frecuencia_asistencias[frecuencia_asistencias.length - 1] ? (
                    <label>
                      Otro frecuencia:{" "}
                      <Input
                        value={
                          form
                            ? form.frecuencia_asistencia
                              ? form.frecuencia_asistencia
                              : ""
                            : ""
                        }
                        name="otro_frecuencia"
                        onChange={inputFrecuenciaAsistencia}
                      />
                    </label>
                  ) : null}
                </Form.Item>
                <Form.Item>
                  <label>
                    3. Por que razones o motivos, acude o ha acudido a los
                    organismos institucionales
                  </label>
                  <br></br>
                  <Col offset={2}>
                    <Form.Item>
                      <label>a. Gestiones y/o trámites Personales: </label>
                      <Radio.Group
                        name="razon_asistencia_gestiones"
                        value={
                          form
                            ? parseInt(form.razon_asistencia_gestiones) >= 0
                              ? parseInt(form.razon_asistencia_gestiones)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>b. Gestiones en pro de la comunidad: </label>
                      <Radio.Group
                        name="razon_asistencia_comunidad"
                        value={
                          form
                            ? parseInt(form.razon_asistencia_comunidad) >= 0
                              ? parseInt(form.razon_asistencia_comunidad)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>c. Vulneracion de Derechos Humanos: </label>
                      <Radio.Group
                        name="razon_asistencia_vulnerabilidad"
                        value={
                          form
                            ? parseInt(form.razon_asistencia_vulnerabilidad) >=
                              0
                              ? parseInt(form.razon_asistencia_vulnerabilidad)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>d. Quejas y Reclamos: </label>
                      <Radio.Group
                        name="razon_asistencia_qr"
                        value={
                          form
                            ? parseInt(form.razon_asistencia_qr) >= 0
                              ? parseInt(form.razon_asistencia_qr)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>e. Otro: </label>
                      <Radio.Group
                        name="razon_asistencia_otro"
                        value={
                          form
                            ? parseInt(form.razon_asistencia_otro) >= 0
                              ? parseInt(form.razon_asistencia_otro)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                      {form ? (
                        parseInt(form.razon_asistencia_otro) > 0 ? (
                          <label>
                            Cual:{" "}
                            <Input
                              style={{ width: "40%" }}
                              value={
                                form
                                  ? form.razon_asistencia_cual
                                    ? form.razon_asistencia_cual
                                    : ""
                                  : ""
                              }
                              name="razon_asistencia_cual"
                              onChange={handleChange}
                            />
                          </label>
                        ) : null
                      ) : null}
                    </Form.Item>
                  </Col>
                </Form.Item>

                <Form.Item>
                  <label>
                    4. Desde el inicio de esta nueva administración, como
                    percibe usted su gestión, ante las diferentes problemáticas
                    que presenta esta municipio
                  </label>
                  <br></br>
                  <select
                    className="ant-input"
                    value={
                      form
                        ? form.calificacion_servicio
                          ? form.calificacion_servicio
                          : ""
                        : ""
                    }
                    name="calificacion_servicio"
                    onChange={handleChange}
                    placeholder="Seleccione una opción"
                  >
                    {calificacion_servicio.map((element) => {
                      return <option value={element}>{element}</option>;
                    })}
                  </select>
                </Form.Item>
                <Form.Item>
                  <label>
                    5. Frente a la situación que vive el mundo entero, por causa
                    de este pandemia, del COVID 19, como persibe usted, el
                    ambiente y el futuro en esta población ante esta crisis
                    social y económica
                  </label>
                  <br></br>
                  <select
                    className="ant-input"
                    name="ambiente_covid"
                    value={
                      form
                        ? form.ambiente_covid
                          ? form.ambiente_covid
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                    placeholder="Seleccione una opción"
                  >
                    {ambiente_covid.map((element) => {
                      return <option value={element}>{element}</option>;
                    })}
                  </select>
                </Form.Item>
                <Form.Item>
                  <label>
                    6. Que espera usted frente a la gestión pública que viene
                    realizando la administración municipal
                  </label>
                  <br></br>
                  <Col offset={2}>
                    <Form.Item>
                      <label>a. Inversión en Salud y Educación: </label>
                      <Radio.Group
                        name="espectativas_inversion_salud_educacion"
                        value={
                          form
                            ? parseInt(
                                form.espectativas_inversion_salud_educacion
                              ) >= 0
                              ? parseInt(
                                  form.espectativas_inversion_salud_educacion
                                )
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>b. Proyectos de Vivienda: </label>
                      <Radio.Group
                        name="espectativas_proyectos_vivienda"
                        value={
                          form
                            ? parseInt(form.espectativas_proyectos_vivienda) >=
                              0
                              ? parseInt(form.espectativas_proyectos_vivienda)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>c. Pavimentacion de vías: </label>
                      <Radio.Group
                        name="espectativas_pavimentacion_vias"
                        value={
                          form
                            ? parseInt(form.espectativas_pavimentacion_vias) >=
                              0
                              ? parseInt(form.espectativas_pavimentacion_vias)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>
                        d. Programas de Emprendimiento y creación de Empleo:{" "}
                      </label>
                      <Radio.Group
                        name="espectativas_programas_emprendimiento_creacion_empleo"
                        value={
                          form
                            ? parseInt(
                                form.espectativas_programas_emprendimiento_creacion_empleo
                              ) >= 0
                              ? parseInt(
                                  form.espectativas_programas_emprendimiento_creacion_empleo
                                )
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>e. Apoyo al empresario Local: </label>
                      <Radio.Group
                        name="espectativas_apoyo_empresario_local"
                        value={
                          form
                            ? parseInt(
                                form.espectativas_apoyo_empresario_local
                              ) >= 0
                              ? parseInt(
                                  form.espectativas_apoyo_empresario_local
                                )
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>f. Sanamiento Básico: </label>
                      <Radio.Group
                        name="espectativas_sanamiento_basico"
                        value={
                          form
                            ? parseInt(form.espectativas_sanamiento_basico) >= 0
                              ? parseInt(form.espectativas_sanamiento_basico)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>
                        h. Programas Agropecuarios y Apoyo al campesinado:{" "}
                      </label>
                      <Radio.Group
                        name="espectativas_programas_agropecuarios_apoyo_campesinado"
                        value={
                          form
                            ? parseInt(
                                form.espectativas_programas_agropecuarios_apoyo_campesinado
                              ) >= 0
                              ? parseInt(
                                  form.espectativas_programas_agropecuarios_apoyo_campesinado
                                )
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                      <label>i. Otro: </label>
                      <Radio.Group
                        name="espectativas_otro"
                        value={
                          form
                            ? parseInt(form.espectativas_otro) >= 0
                              ? parseInt(form.espectativas_otro)
                              : ""
                            : ""
                        }
                        onChange={handleChange}
                      >
                        <Radio onClick={handleClick} value={1}>
                          Si
                        </Radio>
                        <Radio onClick={handleClick} value={0}>
                          No
                        </Radio>
                      </Radio.Group>
                      {form ? (
                        parseInt(form.espectativas_otro) > 0 ? (
                          <label>
                            Cual:{" "}
                            <Input
                              style={{ width: "40%" }}
                              value={
                                form
                                  ? form.espectativas_cual
                                    ? form.espectativas_cual
                                    : ""
                                  : ""
                              }
                              name="espectativas_cual"
                              onChange={handleChange}
                            />
                          </label>
                        ) : null
                      ) : null}
                    </Form.Item>
                  </Col>
                </Form.Item>
                {/* </Form>
            </Col>
            <Col offset={2} span={12}>
              <Form initialValues={{ remember: true }}> */}
                <Form.Item>
                  <label>7. Hace parte o pertenece a</label>
                  <br></br>
                  <Radio.Group
                    name="pertenece_a"
                    value={pertenece_a_bool >= 0 ? pertenece_a_bool : ""}
                    onChange={radioPerteneceABool}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {pertenece_a_bool > 0 ? (
                  <Form.Item>
                    <Col offset={2}>
                      <Form.Item>
                        <label>a. Asociación gremial: </label>
                        <Radio.Group
                          name="pertenece_a_asociacion_gremial"
                          value={
                            form
                              ? parseInt(form.pertenece_a_asociacion_gremial) >=
                                0
                                ? parseInt(form.pertenece_a_asociacion_gremial)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>b. Asociación Sindical: </label>
                        <Radio.Group
                          name="pertenece_a_asociacion_sindical"
                          value={
                            form
                              ? parseInt(
                                  form.pertenece_a_asociacion_sindical
                                ) >= 0
                                ? parseInt(form.pertenece_a_asociacion_sindical)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>c. Partido o Grupo Político: </label>
                        <Radio.Group
                          name="pertenece_a_partido_grupo_politico"
                          value={
                            form
                              ? parseInt(
                                  form.pertenece_a_partido_grupo_politico
                                ) >= 0
                                ? parseInt(
                                    form.pertenece_a_partido_grupo_politico
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>d. Junta de Acción Comunal: </label>
                        <Radio.Group
                          name="pertenece_a_junta_de_accion_comunal"
                          value={
                            form
                              ? parseInt(
                                  form.pertenece_a_junta_de_accion_comunal
                                ) >= 0
                                ? parseInt(
                                    form.pertenece_a_junta_de_accion_comunal
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>e. Concejos Comunitarios: </label>
                        <Radio.Group
                          name="pertenece_a_concejos_comunitarios"
                          value={
                            form
                              ? parseInt(
                                  form.pertenece_a_concejos_comunitarios
                                ) >= 0
                                ? parseInt(
                                    form.pertenece_a_concejos_comunitarios
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>f. Grupos Juveniles: </label>
                        <Radio.Group
                          name="pertenece_a_grupos_juveniles"
                          value={
                            form
                              ? parseInt(form.pertenece_a_grupos_juveniles) >= 0
                                ? parseInt(form.pertenece_a_grupos_juveniles)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>g. Mujeres Líderes: </label>
                        <Radio.Group
                          name="pertenece_a_mujeres_lideres"
                          value={
                            form
                              ? parseInt(form.pertenece_a_mujeres_lideres) >= 0
                                ? parseInt(form.pertenece_a_mujeres_lideres)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>h. Otro: </label>
                        <Radio.Group
                          name="pertenece_a_otro"
                          value={
                            form
                              ? parseInt(form.pertenece_a_otro) >= 0
                                ? parseInt(form.pertenece_a_otro)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                        {form ? (
                          parseInt(form.pertenece_a_otro) > 0 ? (
                            <label>
                              Cual:{" "}
                              <Input
                                style={{ width: "40%" }}
                                value={
                                  form
                                    ? form.pertenece_a_cual
                                      ? form.pertenece_a_cual
                                      : ""
                                    : ""
                                }
                                name="pertenece_a_cual"
                                onChange={handleChange}
                              />
                            </label>
                          ) : null
                        ) : null}
                      </Form.Item>
                    </Col>
                  </Form.Item>
                ) : null}

                <Form.Item>
                  <label>
                    8. Se reune en Plazas, salones comunales u otros sitios con
                    los miembro su grupo u organización
                  </label>
                  <br></br>
                  <Radio.Group
                    name="reuniones"
                    value={
                      form
                        ? parseInt(form.reuniones) >= 0
                          ? parseInt(form.reuniones)
                          : ""
                        : ""
                    }
                    disabled={pertenece_a_bool ? false : true}
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    9. Conoce usted, los procesos, funciones, gestiones y demás
                    labores que se realizan desde la administración pública
                  </label>
                  <br></br>
                  <Radio.Group
                    name="conoce_procesos"
                    value={
                      form
                        ? parseInt(form.conoce_procesos) >= 0
                          ? parseInt(form.conoce_procesos)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <label>
                    10. Ha realizado alguna gestión y/o soilicitud en alguna de
                    las siguientes dependencias?
                  </label>
                  <br></br>
                  <Radio.Group
                    name="realizado_gestion"
                    value={realizado_gestion_bool >= 0 ? realizado_gestion_bool : ""}
                    onChange={radioRealizadoGestionBool}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                {realizado_gestion_bool > 0 ? (
                  <Form.Item>
                    <Col offset={2}>
                      <Form.Item>
                        <label>a. Secretaria de Gobierno: </label>
                        <Radio.Group
                          name="realizado_gestion_secretaria_gobierno"
                          value={
                            form
                              ? parseInt(
                                  form.realizado_gestion_secretaria_gobierno
                                ) >= 0
                                ? parseInt(
                                    form.realizado_gestion_secretaria_gobierno
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>b. Secretaria de Hacienda: </label>
                        <Radio.Group
                          name="realizado_gestion_secretaria_hacienda"
                          value={
                            form
                              ? parseInt(
                                  form.realizado_gestion_secretaria_hacienda
                                ) >= 0
                                ? parseInt(
                                    form.realizado_gestion_secretaria_hacienda
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>c. Gestión Social: </label>
                        <Radio.Group
                          name="realizado_gestion_gestion_social"
                          value={
                            form
                              ? parseInt(
                                  form.realizado_gestion_gestion_social
                                ) >= 0
                                ? parseInt(
                                    form.realizado_gestion_gestion_social
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>d. Planeación Municipal: </label>
                        <Radio.Group
                          name="realizado_gestion_planeacion_municipal"
                          value={
                            form
                              ? parseInt(
                                  form.realizado_gestion_planeacion_municipal
                                ) >= 0
                                ? parseInt(
                                    form.realizado_gestion_planeacion_municipal
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>f. Secretaria de Salud: </label>
                        <Radio.Group
                          name="realizado_gestion_secretaria_salud"
                          value={
                            form
                              ? parseInt(
                                  form.realizado_gestion_secretaria_salud
                                ) >= 0
                                ? parseInt(
                                    form.realizado_gestion_secretaria_salud
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>g. Secretaria de Educación: </label>
                        <Radio.Group
                          name="realizado_gestion_secretaria_educacion"
                          value={
                            form
                              ? parseInt(
                                  form.realizado_gestion_secretaria_educacion
                                ) >= 0
                                ? parseInt(
                                    form.realizado_gestion_secretaria_educacion
                                  )
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item>
                        <label>h. Otro: </label>
                        <Radio.Group
                          name="realizado_gestion_otro"
                          value={
                            form
                              ? parseInt(form.realizado_gestion_otro) >= 0
                                ? parseInt(form.realizado_gestion_otro)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                        {form ? (
                          parseInt(form.realizado_gestion_otro) > 0 ? (
                            <label>
                              Cual:{" "}
                              <Input
                                style={{ width: "40%" }}
                                value={
                                  form
                                    ? form.realizado_gestion_cual
                                      ? form.realizado_gestion_cual
                                      : ""
                                    : ""
                                }
                                name="realizado_gestion_cual"
                                onChange={handleChange}
                              />
                            </label>
                          ) : null
                        ) : null}
                      </Form.Item>
                    </Col>
                  </Form.Item>
                ) : null}

                <Form.Item>
                  <label>
                    11. La Gestión y/o solicitud realizada ante la dependencia
                    de la administación municipal fue
                  </label>
                  <br></br>
                  <select
                    disabled={realizado_gestion_bool ? false : true}
                    value={
                      form
                        ? form.estado_gestion
                          ? form.estado_gestion
                          : ""
                        : ""
                    }
                    className="ant-input"
                    name="estado_gestion"
                    onChange={handleChange}
                    placeholder="Seleccione una opción"
                  >
                    {estado_gestion.map((element) => {
                      return <option value={element}>{element}</option>;
                    })}
                  </select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Col span={5}>
                  <Button
                    disabled={form ? (form.n_orden > 0 ? false : true) : true}
                    type="danger"
                    icon={<SaveOutlined />}
                    size="large"
                    shape="round"
                    onClick={() => handleSave()}
                  >
                    Guardar
                  </Button>
                </Col>
                <Col offset={5}>
                  <Button
                    type="primary"
                    style={
                      !terminate_bool
                        ? {}
                        : { color: "grey", backgroundColor: "white" }
                    }
                    size="large"
                    shape="round"
                    disabled={
                      typeof cookies.get("usuario") !== "undefined"
                        ? false
                        : true
                    }
                    icon={<CheckOutlined />}
                    onClick={() => terminate()}
                  >
                    Terminar
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Organismos;
