import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Radio,
  Button,
  Switch,
} from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import { SaveOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import {
  SaveOtroIngreso,
  UpdateOtroIngreso,
  GetOtroIngresoCodigo,
} from "../entity/helpers";
import styles from "./css/form.module.css";
import { niveles_educativos, titulos } from "../helpers/helperforms";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const OtrosIngresos = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const [ingresos, setIngresos] = useState({});
  const [subsidios, setSubsidios] = useState({});
  const formulario = cookies.get("formulario");
  var personas = cookies.get("otros_ingresos")
    ? cookies.get("otros_ingresos").filter(function (value, index, arr) {
        return value.edad >= 15 || value.id == 0;
      })
    : [];
  const [form, setForm] = useState(formulario);
  const [edicion, setEdicion] = useState(false);
  const [n_orden_search, setNOrdenSearch] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  function handleSave() {
    SaveOtroIngreso(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
        personas = personas.filter(function (value, index, arr) {
          return value.id != form.n_orden;
        });
        cookies.remove("otros_ingresos");
        cookies.set("otros_ingresos", personas);
        setForm(formulario);
        setIngresos({});
        setSubsidios({});
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleUpdate = () => {
    UpdateOtroIngreso(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setNOrdenSearch(null);
        setIngresos({});
        setSubsidios({});
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setCodigo(null);
    setEdicion(false);
    setNOrdenSearch(null);
    setForm(formulario);
    setIngresos({});
    setSubsidios({});
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleOrden = (e) => {
    const { id, value } = e.target;
    setNOrdenSearch(value);
  };

  const handleSearch = () => {
    if (codigo && n_orden_search) {
      GetOtroIngresoCodigo(codigo, n_orden_search).then((response) => {
        if (response[0]) {
          setForm(response[0]);
          setEdicion(true);
          if (
            response[0].pagos_arriendos &&
            parseInt(response[0].pagos_arriendos)
          ) {
            setIngresos({
              ...ingresos,
              pagos_arriendos_bool: 1,
            });
          }
          if (response[0].pensiones && parseInt(response[0].pensiones)) {
            setIngresos({
              ...ingresos,
              pensiones_bool: 1,
            });
          }
          if (response[0].subsidios && parseInt(response[0].subsidios)) {
            setIngresos({
              ...ingresos,
              subsidios_bool: 1,
            });
          }
          //----------------
          if (
            response[0].familias_accion &&
            parseInt(response[0].familias_accion)
          ) {
            setSubsidios({
              ...subsidios,
              familias_accion_bool: 1,
            });
          }
          if (
            response[0].jovenes_accion &&
            parseInt(response[0].jovenes_accion)
          ) {
            setSubsidios({
              ...subsidios,
              jovenes_accion_bool: 1,
            });
          }
          if (response[0].adulto_mayor && parseInt(response[0].adulto_mayor)) {
            setSubsidios({
              ...subsidios,
              adulto_mayor_bool: 1,
            });
          }
          if (response[0].otro && parseInt(response[0].otro)) {
            setSubsidios({
              ...subsidios,
              otro_bool: 1,
            });
          }
        } else {
          setCodigo(null);
          setNOrdenSearch(null);
          setForm(formulario);
          setIngresos({});
          setSubsidios({});
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (
      name === "pagos_arriendos_bool" ||
      name === "pensiones_bool" ||
      name === "subsidios_bool"
    ) {
      if (value == ingresos[name]) {
        setIngresos({
          ...ingresos,
          [name]: "",
        });
      }
    } else {
      if (
        name === "familias_accion_bool" ||
        name === "jovenes_accion_bool" ||
        name === "adulto_mayor_bool" ||
        name === "otro_bool"
      ) {
        if (value == subsidios[name]) {
          setSubsidios({
            ...subsidios,
            [name]: "",
          });
        }
      }
    }
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "pagos_arriendos_bool" ||
      name === "pensiones_bool" ||
      name === "subsidios_bool"
    ) {
      setIngresos({
        ...ingresos,
        [name]: value,
      });
    } else {
      if (
        name === "familias_accion_bool" ||
        name === "jovenes_accion_bool" ||
        name === "adulto_mayor_bool" ||
        name === "otro_bool"
      ) {
        setSubsidios({
          ...subsidios,
          [name]: value,
        });
      } else {
        setForm({
          ...form,
          [name]: value,
        });
      }
    }
  };
  return (
    <>
      <Row gutter={20} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title={
            <div>
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por: </label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Input
                    id="n_orden"
                    placeholder={"# Orden"}
                    onChange={handleOrden}
                    value={n_orden_search ? n_orden_search : ""}
                    style={{ width: "10%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
        >
          <h1>L. OTROS INGRESOS (PARA TODAS LAS PERSONAS DE 15 AÑOS Y MAS)</h1>
          <Row>
            <Col>
              <Form initialValues={{ remember: true }}>
                {!editBool ? (
                  <Form.Item label="Numero de Orden">
                    <select
                      className="ant-input"
                      name="n_orden"
                      value={form ? (form.n_orden ? form.n_orden : "") : ""}
                      onChange={handleChange}
                      placeholder="Seleccione una opción"
                      defaultValue={0}
                    >
                      {personas.map((num) => {
                        return <option value={num.id}>{num.value}</option>;
                      })}
                    </select>
                  </Form.Item>
                ) : null}
                <Form.Item label="">
                  <label>
                    1. El mes pasado, ¿recibió pagos por concepto de arriendos
                    y/o pensiones?
                  </label>
                  <br></br>
                  <Radio.Group
                    name="recibio_ingresos"
                    value={
                      form
                        ? parseInt(form.recibio_ingresos) >= 0
                          ? parseInt(form.recibio_ingresos)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>

        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="2. En los ultimos meses recibió ingresos por concepto de:"
        >
          <Row justify="center">
            <Col span={18}>
              <Form initialValues={{ remember: true }}>
                {form ? (
                  parseInt(form.recibio_ingresos) ? (
                    <>
                      <Form.Item label="">
                        <label>
                          a. Arriendos de casas, apartamentos, fincas, lotes,
                          vehículos, equipos etc
                        </label>
                        <br></br>
                        <Radio.Group
                          name="pagos_arriendos_bool"
                          value={
                            ingresos
                              ? parseInt(ingresos.pagos_arriendos_bool) >= 0
                                ? parseInt(ingresos.pagos_arriendos_bool)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                        {ingresos.pagos_arriendos_bool ? (
                          <Input
                            name="pagos_arriendos"
                            value={
                              form
                                ? parseInt(form.pagos_arriendos) >= 0
                                  ? parseInt(form.pagos_arriendos)
                                  : ""
                                : ""
                            }
                            placeholder="Valor mes $"
                            onChange={handleChange}
                          />
                        ) : null}
                      </Form.Item>
                      <Form.Item label="">
                        <label>
                          b. Pensiones o jubilaciones por vejez, invalidez o
                          sustitución pensional
                        </label>
                        <br></br>
                        <Radio.Group
                          name="pensiones_bool"
                          value={
                            ingresos
                              ? parseInt(ingresos.pensiones_bool) >= 0
                                ? parseInt(ingresos.pensiones_bool)
                                : ""
                              : ""
                          }
                          onChange={handleChange}
                        >
                          <Radio onClick={handleClick} value={1}>
                            Si
                          </Radio>
                          <Radio onClick={handleClick} value={0}>
                            No
                          </Radio>
                        </Radio.Group>
                        {ingresos.pensiones_bool ? (
                          <Input
                            name="pensiones"
                            value={
                              form
                                ? parseInt(form.pensiones) >= 0
                                  ? parseInt(form.pensiones)
                                  : ""
                                : ""
                            }
                            placeholder="Valor mes $"
                            onChange={handleChange}
                          />
                        ) : null}
                      </Form.Item>
                    </>
                  ) : null
                ) : null}
                <Form.Item label="">
                  <label>
                    c. Subsidios o Ayudas a través de programas sociales del
                    Estado
                  </label>
                  <br></br>
                  <Radio.Group
                    name="subsidios_bool"
                    value={
                      ingresos
                        ? parseInt(ingresos.subsidios_bool) >= 0
                          ? parseInt(ingresos.subsidios_bool)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>

        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="2.d. Es beneficiario de alguno de los siguientes subsidios?"
        >
          <Row justify="center">
            <Col span={10}>
              <Form initialValues={{ remember: true }}>
                <Form.Item label="">
                  <label>Familias en Acción</label>
                  <br></br>
                  <Radio.Group
                    name="familias_accion_bool"
                    value={
                      subsidios
                        ? parseInt(subsidios.familias_accion_bool) >= 0
                          ? parseInt(subsidios.familias_accion_bool)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                  {subsidios.familias_accion_bool ? (
                    <Input
                      name="familias_accion"
                      value={
                        form
                          ? parseInt(form.familias_accion) >= 0
                            ? parseInt(form.familias_accion)
                            : ""
                          : ""
                      }
                      placeholder="Valor mes $"
                      onChange={handleChange}
                    />
                  ) : null}
                </Form.Item>
                <Form.Item label="">
                  <label>Jovenes en Acción</label>
                  <br></br>
                  <Radio.Group
                    name="jovenes_accion_bool"
                    value={
                      subsidios
                        ? parseInt(subsidios.jovenes_accion_bool) >= 0
                          ? parseInt(subsidios.jovenes_accion_bool)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                  {subsidios.jovenes_accion_bool ? (
                    <Input
                      name="jovenes_accion"
                      value={
                        form
                          ? parseInt(form.jovenes_accion) >= 0
                            ? parseInt(form.jovenes_accion)
                            : ""
                          : ""
                      }
                      placeholder="Valor mes $"
                      onChange={handleChange}
                    />
                  ) : null}
                </Form.Item>
                <Form.Item label="">
                  <label>Adulto Mayor</label>
                  <br></br>
                  <Radio.Group
                    name="adulto_mayor_bool"
                    value={
                      subsidios
                        ? parseInt(subsidios.adulto_mayor_bool) >= 0
                          ? parseInt(subsidios.adulto_mayor_bool)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                  {subsidios.adulto_mayor_bool ? (
                    <Input
                      name="adulto_mayor"
                      value={
                        form
                          ? parseInt(form.adulto_mayor) >= 0
                            ? parseInt(form.adulto_mayor)
                            : ""
                          : ""
                      }
                      placeholder="Valor mes $"
                      onChange={handleChange}
                    />
                  ) : null}
                </Form.Item>
                <Form.Item label="">
                  <label>
                    Otro:{" "}
                    {subsidios.otro_bool ? (
                      <Input
                        name="otro_cual"
                        style={{ width: "50%" }}
                        value={
                          form ? (form.otro_cual ? form.otro_cual : "") : ""
                        }
                        placeholder="Cual"
                        onChange={handleChange}
                      />
                    ) : null}
                  </label>

                  <br></br>
                  <Radio.Group
                    name="otro_bool"
                    value={
                      subsidios
                        ? parseInt(subsidios.otro_bool) >= 0
                          ? parseInt(subsidios.otro_bool)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>
                      Si
                    </Radio>
                    <Radio onClick={handleClick} value={0}>
                      No
                    </Radio>
                  </Radio.Group>
                  {subsidios.otro_bool ? (
                    <Input
                      name="otro"
                      value={
                        form
                          ? parseInt(form.otro) >= 0
                            ? parseInt(form.otro)
                            : ""
                          : ""
                      }
                      placeholder="Valor mes $"
                      onChange={handleChange}
                    />
                  ) : null}
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={form ? (form.n_orden > 0 ? false : true) : true}
                  type="danger"
                  icon={<SaveOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default OtrosIngresos;
