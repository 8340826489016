import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Row, Col, Card, Form, Input, Select, DatePicker, Button } from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import styles from "./css/identificacion.module.css";
import {
  GetEncuestadores,
  GetDigitadores,
  GetIdentificacionCodigo,
  SaveIdentificacion,
  UpdateIdentificacion,
  GetDepartments,
  Getmunicipalities,
  Getmunicipalitiesbydepartment,
  GetSupervisores,
  DeleteFormularioCodigo,
} from "../entity/helpers";
//logos
import LogoAlcaldia from "../../Assets/img/alcaldia.png";
import BarrancasLogo from "../../Assets/img/logoempresa.png";
import MembreteLogo from "../../Assets/img/membrete.png";
import swal from "sweetalert"
import {
  SaveOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

const { TextArea } = Input;

const Identificacion = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const [form, setForm] = useState(null);
  const [guardado, setGuardado] = useState(false);
  const [encuestadores, setencuestadores] = useState(null);
  const [digitadores, setDigitadores] = useState(null);
  const [edicion, setEdicion] = useState(false);
  const [departamentos, setdepartamentos] = useState(null);
  const [municipios, setmunicipios] = useState(null);
  const [supervisores, setsupervisores] = useState(null);

  const alertConfirm = () => {
    swal({
      title: "Confirmar",
      text: "Desea eliminar este registro?",
      icon: "warning",
      buttons: ["No", "Si"]
    }).then(respuesta => {
      if (respuesta){
        handleDelete()
      }
    })
  }

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({
      ...form,
      [id]: value,
    });
    if (id === "departamento_id") {
      Getmunicipalitiesbydepartment(value).then((response) => {
        setmunicipios(response);
      });
    }
  };

  function handleUpdate() {
    UpdateIdentificacion(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(null);
      } else {
        MSJERROR(res.data.error);
      }
    });
  }

  function handleDelete() {
    if (form) {
      if (form.codigo) {
        DeleteFormularioCodigo(form.codigo).then((res) => {
          if (res.data.message) {
            MSJOK(res.data.message);
            setEdicion(false);
            setForm(null);
          } else {
            MSJERROR(res.data.error);
          }
        });
      }
    }
  }

  function handleSave() {
    if (form) {
      if (
        form.codigo &&
        form.departamento_id &&
        form.municipio_id &&
        form.clase &&
        form.sector &&
        form.seccion &&
        form.manzana &&
        form.barrio &&
        form.direccion &&
        form.estrato &&
        form.telefono &&
        form.encuestador &&
        form.digitador &&
        form.supervisor &&
        form.fecha &&
        form.observacion
      ) {
        SaveIdentificacion(form).then((res) => {
          if (res.data.identificacion_id) {
            MSJOK("Datos guardados con exito.");
            setGuardado(true);
            cookies.set("formulario", {
              identificacion_id: res.data.identificacion_id,
            });
          } else {
            MSJERROR(res.data.errorInfo[0] + " " + res.data.errorInfo[2]);
          }
        });
      } else {
        MSJERROR("Varios campos estan vacios");
      }
    } else {
      MSJERROR("Formulario vacio");
    }
  }

  const handleSearch = () => {
    if (form) {
      if (form.codigo) {
        GetIdentificacionCodigo(form.codigo).then((response) => {
          if (response[0]) {
            setForm(response[0]);
            setEdicion(true);
          } else {
            setForm(null);
            MSJERROR("No existe formulario con codigo: " + form.codigo);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (cookies.get("personas")) {
      if (cookies.get("personas").length <= 1)
        cookies.set("personas", [
          {
            id: 0,
            value: "Seleccione una opcion",
            actividad: "",
            otra_actividad: 0,
          },
        ]);
    } else {
      cookies.set("personas", [
        {
          id: 0,
          value: "Seleccione una opcion",
          actividad: "",
          otra_actividad: 0,
        },
      ]);
    }

    GetEncuestadores().then((response) => {
      setencuestadores(response);
    });

    GetDigitadores().then((response) => {
      setDigitadores(response);
    });

    GetDepartments().then((response) => {
      setdepartamentos(response);
    });

    Getmunicipalities().then((response) => {
      setmunicipios(response);
    });

    GetSupervisores().then((response) => {
      setsupervisores(response);
    });
  }, []);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 10 }}>
        {/* <Col className="gutter-row" span={4} offset={5}> */}
        <Col className="gutter-row">
          <div>
            <img width="100%" src={MembreteLogo} alt="BarrancasLogo" />
          </div>
        </Col>
        {/* <Col className="gutter-row" span={6}>
          <div
            style={{
              background: "white",
              padding: 10,
              marginTop: 50,
              border: "1px grey solid",
              borderRadius: 20,
            }}
          >
            <p>
              CARACTERIZACIÓN SOCIO-ECONÓMICA POBLACIONAL PARA LA TOMA DE
              DECISIONES Y CORRECTA EJECUCIÓN E INVERSIÓN DE LOS RECURSOS DEL
              PLAN DE DESARROLLO MUNICIPAL, 2020 - 2023 DEL MUNICIPIO DE
              BARRANCAS - LA GUAJIRA
            </p>
          </div>
        </Col>
        <Col className="gutter-row" span={6}>
          <div>
            <img src={LogoAlcaldia} alt="LogoAlcaldia" />
          </div>
        </Col> */}
      </Row>

      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="A. IDENTIFICACIÓN"
        >
          <Row justify="center">
            <Col span={10}>
              <Form name="basic1" initialValues={{ remember: true }}>
                <Form.Item label="Codigo Formulario" name="codigo">
                  <Input
                    id="codigo"
                    value={form ? (form.codigo ? form.codigo : "") : ""}
                    style={{ width: "50%" }}
                    onChange={handleChange}
                  />
                  <Button
                    type="primary"
                    disabled={form ? (form.codigo ? false : true) : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                  <Button
                    type="danger"
                    disabled={form ? (form.codigo ? false : true) : true}
                    shape="circle"
                    // onClick={if(window.confirm('Delete the item?')){this.removeToCollection(key, e)}}
                    onClick={alertConfirm}
                    icon={<DeleteOutlined />}
                  />
                </Form.Item>
                <Form.Item label="1. Departamento">
                  <select
                    className="ant-input"
                    placeholder="Seleccione una opción"
                    onChange={(e) => handleChange(e)}
                    name="departamento_id"
                    id="departamento_id"
                    value={
                      form
                        ? form.departamento_id
                          ? parseInt(form.departamento_id)
                          : 0
                        : 0
                    }
                  >
                    <option value="">Seleccione una opción</option>
                    {departamentos &&
                      departamentos.map((departamento, index) => {
                        return (
                          <option key={index} value={departamento.id}>
                            {departamento.nombre}
                          </option>
                        );
                      })}
                  </select>
                </Form.Item>

                <Form.Item label="2. Municipio">
                  <select
                    className="ant-input"
                    placeholder="Seleccione una opción"
                    onChange={(e) => handleChange(e)}
                    name="municipio_id"
                    id="municipio_id"
                    value={
                      form
                        ? form.municipio_id
                          ? form.municipio_id
                          : null
                        : null
                    }
                  >
                    <option value="">Seleccione una opción</option>
                    {municipios &&
                      municipios.map((municipio, index) => {
                        return (
                          <option key={index} value={municipio.id}>
                            {municipio.nombre}
                          </option>
                        );
                      })}
                  </select>
                </Form.Item>

                <Form.Item label="3. Cláse">
                  <select
                    onChange={handleChange}
                    id="clase"
                    value={form ? (form.clase ? form.clase : "") : ""}
                    className="ant-input"
                  >
                    <option value="0">Seleccione una opción</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                </Form.Item>

                <Form.Item label="4. Sector">
                  <Input
                    id="sector"
                    value={form ? (form.sector ? form.sector : "") : ""}
                    onChange={handleChange}
                  />
                </Form.Item>

                <Form.Item label="5. Sección">
                  <Input
                    id="seccion"
                    value={form ? (form.seccion ? form.seccion : "") : ""}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Form>
            </Col>
            <Col offset={2} span={12}>
              <Form.Item label="6. Manzana">
                <Input
                  id="manzana"
                  value={form ? (form.manzana ? form.manzana : "") : ""}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item label="7. Barrio, centro poblado ó vereda">
                <Input
                  id="barrio"
                  value={form ? (form.barrio ? form.barrio : "") : ""}
                  onChange={handleChange}
                />
              </Form.Item>

              <Form.Item label="8. Dirección de la vivienda ó nombre">
                <Input
                  id="direccion"
                  value={form ? (form.direccion ? form.direccion : "") : ""}
                  onChange={handleChange}
                />
              </Form.Item>

              <Form.Item label="9. Estrato">
                <select
                  onChange={handleChange}
                  id="estrato"
                  value={form ? (form.estrato ? form.estrato : "") : ""}
                  className="ant-input"
                >
                  <option value="0">Seleccione una opción</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>
              </Form.Item>

              <Form.Item label="10. Teléfono">
                <Input
                  id="telefono"
                  value={form ? (form.telefono ? form.telefono : "") : ""}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Row>

      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="A.1 CONTROL DE CALIDAD DE LA ENCUESTA"
        >
          <Row justify="center">
            <Col span={10}>
              <Form name="basic" initialValues={{ remember: true }}>
                <Form.Item label="Encuestador">
                  <select
                    className="ant-input"
                    id="encuestador"
                    value={
                      form ? (form.encuestador ? form.encuestador : "") : ""
                    }
                    onChange={handleChange}
                  >
                    <option value={0}>{"Seleccione una opción"}</option>
                    {encuestadores &&
                      encuestadores.map((encuestador, index) => {
                        return (
                          <option key={index} value={encuestador.id}>
                            {encuestador.nombre + " " + encuestador.apellidos}
                          </option>
                        );
                      })}
                  </select>
                </Form.Item>
                <Form.Item label="1. Digitador">
                  <select
                    className="ant-input"
                    id="digitador"
                    value={form ? (form.digitador ? form.digitador : "") : ""}
                    onChange={handleChange}
                  >
                    <option value={0}>{"Seleccione una opción"}</option>
                    {digitadores &&
                      digitadores.map((digitador, index) => {
                        return (
                          <option key={index} value={digitador.id}>
                            {digitador.nombre + " " + digitador.apellidos}
                          </option>
                        );
                      })}
                  </select>
                </Form.Item>

                <Form.Item label="2. Supervisor">
                  <select
                    className="ant-input"
                    id="supervisor"
                    value={form ? (form.supervisor ? form.supervisor : "") : ""}
                    onChange={handleChange}
                  >
                    <option value={0}>{"Seleccione una opción"}</option>
                    {supervisores &&
                      supervisores.map((supervisor, index) => {
                        return (
                          <option key={index} value={supervisor.id}>
                            {supervisor.nombre + " " + supervisor.apellidos}
                          </option>
                        );
                      })}
                  </select>
                </Form.Item>

                <Form.Item label="Fecha">
                  <input
                    className="ant-input"
                    id="fecha"
                    value={form ? (form.fecha ? form.fecha : "") : ""}
                    type="date"
                    onChange={(e) => handleChange(e)}
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>

      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title="OBSERVACIONES"
        >
          <Row justify="center">
            <TextArea
              id="observacion"
              value={form ? (form.observacion ? form.observacion : "") : ""}
              onChange={handleChange}
              rows={4}
              placeholder="Digite aqui sus observaciones"
            />
          </Row>
        </Card>
      </Row>

      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            <Col offset={6} span={6}>
              <Button
                type="danger"
                disabled={
                  (typeof cookies.get("usuario") !== "undefined")
                    ? cookies.get("formulario") || guardado
                      ? true
                      : edicion
                      ? true
                      : false
                    : true
                }
                icon={<SaveOutlined />}
                size="large"
                shape="round"
                onClick={() => handleSave()}
              >
                Guardar
              </Button>
            </Col>
            <Col offset={2} span={10}>
              <Button
                style={
                  (typeof cookies.get("usuario") !== "undefined") ? (edicion ? { color: "white", backgroundColor: "green" } : {}) : {}
                }
                disabled={(typeof cookies.get("usuario") !== "undefined") ? (edicion ? false : true) : true}
                icon={<EditOutlined />}
                size="large"
                shape="round"
                onClick={() => handleUpdate()}
              >
                Modificar
              </Button>
            </Col>
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Identificacion;
