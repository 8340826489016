import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  Radio,
  Button,
  Switch,
} from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import styles from "./css/form.module.css";
import { SaveOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import {
  SaveEducacion,
  UpdateEducacion,
  GetEducacionCodigo,
} from "../entity/helpers";
import { niveles_educativos, titulos } from "../helpers/helperforms";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const Educacion = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  var personas = cookies.get("educacion")
    ? cookies.get("educacion").filter(function (value, index, arr) {
        return value.edad >= 3 || value.id == 0;
      })
    : [];
  const [form, setForm] = useState(formulario);
  const [edicion, setEdicion] = useState(false);
  const [n_orden_search, setNOrdenSearch] = useState(null);
  const [codigo, setCodigo] = useState(null);
  const [editBool, setEditBool] = useState(false);

  function handleSave() {
    SaveEducacion(form).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
        personas = personas.filter(function (value, index, arr) {
          return value.id != form.n_orden;
        });
        cookies.remove("educacion");
        cookies.set("educacion", personas);
        setForm(formulario);
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleUpdate = () => {
    UpdateEducacion(form).then((res) => {
      if (res.data.message) {
        MSJOK(res.data.message);
        setEdicion(false);
        setForm(formulario);
        setCodigo(null);
        setNOrdenSearch(null);
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setCodigo(null);
    setEdicion(false);
    setNOrdenSearch(null);
    setForm(formulario);
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleOrden = (e) => {
    const { id, value } = e.target;
    setNOrdenSearch(value);
  };

  const handleSearch = () => {
    if (codigo && n_orden_search) {
      GetEducacionCodigo(codigo, n_orden_search).then((response) => {
        if (response[0]) {
          setForm(response[0]);
          setEdicion(true);
        } else {
          setCodigo(null);
          setNOrdenSearch(null);
          setForm(formulario);
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form) {
      if (value == form[name]) {
        setForm({
          ...form,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  return (
    <>
      <Row gutter={24} justify="center">
        <Card
          title={
            <div>
              H. EDUCACIÓN: para personas de 3 años y más
              {"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
              <br></br>
              {editBool ? (
                <div>
                  <label>Buscar por: </label>
                  <Input
                    id="codigo"
                    placeholder={"Codigo formulario"}
                    onChange={handleCodigo}
                    value={codigo ? codigo : ""}
                    style={{ width: "20%" }}
                  />
                  <Input
                    id="n_orden"
                    placeholder={"# Orden"}
                    onChange={handleOrden}
                    value={n_orden_search ? n_orden_search : ""}
                    style={{ width: "10%" }}
                  />
                  <Button
                    type="primary"
                    disabled={codigo ? false : true}
                    shape="circle"
                    onClick={handleSearch}
                    icon={<SearchOutlined />}
                  />
                </div>
              ) : null}
            </div>
          }
          className={styles.cardDashboardgeneral}
          size="large"
        >
          <Row justify="center">
            <Col offset={2}>
              <Form layout="vertical" initialValues={{ remember: true }}>
                {!editBool ? (
                  <Form.Item label="Numero de Orden">
                    <select
                      className="ant-input"
                      name="n_orden"
                      style={{ width: "20%" }}
                      value={form ? (form.n_orden ? form.n_orden : "") : ""}
                      onChange={handleChange}
                      placeholder="Seleccione una opción"
                      defaultValue={0}
                    >
                      {personas.map((num) => {
                        return <option value={num.id}>{num.value}</option>;
                      })}
                    </select>
                  </Form.Item>
                ) : null}
                <Form.Item label="1. ¿Sabe leer y escribir?">
                  <Radio.Group
                    name="leer_escribir"
                    value={
                      form ? (parseInt(form.leer_escribir) >= 0 ? parseInt(form.leer_escribir) : "") : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="2. ¿Actualmente ... asiste al preescolar, escuela, colegio o universidad?">
                  <Radio.Group
                    name="actualmente_estudia"
                    value={
                      form
                        ? parseInt(form.actualmente_estudia) >= 0
                          ? parseInt(form.actualmente_estudia)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="2.a. Ha tenido problemas de conectividad en las clases virtuales que se vienen realizando como consecuencia de la pandemia a causa del Covide 19">
                  <Radio.Group
                    name="problemas_clases_vir"
                    value={
                      form
                        ? parseInt(form.problemas_clases_vir) >= 0
                          ? parseInt(form.problemas_clases_vir)
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                    disabled={
                      form ? (form.actualmente_estudia ? false : true) : true
                    }
                  >
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="2.b. Se vió forzado a abandonar sus estudios, por no contar con la conectividad para las clases virtuales, o por no tener los recursos económicos para continuar los mismos, con consecuencias arrojadas por la pandemia a cuasa del Covid 19.">
                  <Radio.Group
                    name="dejar_estudios"
                    value={
                      form
                        ? parseInt(form.dejar_estudios) >= 0
                          ? parseInt(form.dejar_estudios)
                          : ""
                        : ""
                    }
                    disabled={
                      form ? (form.actualmente_estudia ? false : true) : true
                    }
                    onChange={handleChange}
                  >
                    <Radio onClick={handleClick} value={1}>Si</Radio>
                    <Radio onClick={handleClick} value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="3. ¿Cuál es el nivel educativo más alto alcanzado por .... y el último año o grado aprobado en este nivel?">
                  <select
                    className="ant-input"
                    style={{ width: "40%" }}
                    name="nivel_educativo_reciente"
                    value={
                      form
                        ? form.nivel_educativo_reciente
                          ? form.nivel_educativo_reciente
                          : ""
                        : ""
                    }
                    onChange={handleChange}
                    placeholder="Seleccione una opción"
                  >
                    {niveles_educativos.map((nivel_educativo) => {
                      return (
                        <option value={nivel_educativo}>
                          {nivel_educativo}
                        </option>
                      );
                    })}
                  </select>
                </Form.Item>
                <Form.Item label="4. ¿Cuál es el título o diploma  de mayor nivel educativo que usted ha recibido?">
                  <select
                    className="ant-input"
                    name="nivel_educativo"
                    value={
                      form
                        ? form.nivel_educativo
                          ? form.nivel_educativo
                          : ""
                        : ""
                    }
                    style={{ width: "40%" }}
                    onChange={handleChange}
                    // style={{ width: "100%" }}
                    // showSearch
                    placeholder="Seleccione una opción"
                    // optionFilterProp="children"
                    // onChange={onChange}
                    // onFocus={onFocus}
                    // onBlur={onBlur}
                    // onSearch={onSearch}
                    // filteroption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {titulos.map((titulo) => {
                      return <option value={titulo}>{titulo}</option>;
                    })}
                  </select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Card>
      </Row>
      <Row gutter={24} justify="center">
        <Card className={styles.cardDashboardgeneral} size="large">
          <Row justify="center">
            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  disabled={form ? (form.n_orden > 0 ? false : true) : true}
                  type="danger"
                  icon={<SaveOutlined />}
                  size="large"
                  shape="round"
                  onClick={() => handleSave()}
                >
                  Guardar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Educacion;
