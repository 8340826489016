import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Select,
  DatePicker,
  Radio,
  Button,
  Switch,
} from "antd";
import { MSJOK, MSJERROR } from "../Alerts";
import {
  SavePersona,
  UpdatePersona,
  GetResidenteCodigo,
  UpdateResidente,
} from "../entity/helpers";
import { getEdad } from "../entity/utils";
import styles from "./css/form.module.css";
import {
  SearchOutlined,
  PlusCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { sexos, estado_civiles, parentescos } from "../helpers/helperforms";
const { TextArea } = Input;

const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const { Option } = Select;

const Personas = () => {
  const cookies = new Cookies();
  if (typeof cookies.get("usuario") !== "undefined") {
    if (
      parseInt(cookies.get("usuario").hora_fin) <
      new Date()
        .getHours()
        .toLocaleString("es-ES", { timeZone: "America/Bogota" })
    )
      cookies.remove("usuario");
  }
  const formulario = cookies.get("formulario");
  var personas = cookies.get("personas");
  const [form_persona, setFormPersona] = useState({
    ...formulario,
    n_orden: personas ? personas.length : 0,
  });
  const [edicion, setEdicion] = useState(false);
  const [codigo, setCodigo] = useState(null);
  const [n_orden_search, setNOrdenSearch] = useState(null);
  const [editBool, setEditBool] = useState(false);
  console.log(form_persona.n_orden  && 'nombre' in form_persona && 'parentesco' in form_persona && 'estado_civil' in form_persona && 'sexo' in form_persona && 'fecha_nacimiento' in form_persona )

  function handleSave() {
    SavePersona(form_persona).then((res) => {
      if (res.config.data) {
        MSJOK("Datos guardados con exito.");
        personas.push({
          id: form_persona.n_orden,
          value: form_persona.nombre + " " + form_persona.parentesco,
          nombre: form_persona.nombre,
          parentesco: form_persona.parentesco,
          actividad: "",
          otra_actividad: 0,
          edad: getEdad(form_persona.fecha_nacimiento),
        });
        cookies.remove("personas");
        cookies.set("personas", personas);
        cookies.set("personas2", personas);
        cookies.set("vulnerabilidad", personas);
        cookies.set("migracion", personas);
        cookies.set("salud", personas);
        cookies.set("educacion", personas);
        cookies.set("ocupacion", personas);
        cookies.set("desocupados", personas);
        cookies.set("otras_actividades", personas);
        cookies.set("otros_ingresos", personas);
        cookies.set("organismos", personas);
        setFormPersona({
          ...formulario,
          n_orden: personas ? personas.length : 0,
        });
      } else {
        MSJERROR(res.statusText);
      }
    });
  }

  const handleUpdate = () => {
    var personas2 = cookies.get("personas2");
    var vulnerabilidad = cookies.get("vulnerabilidad");
    var migracion = cookies.get("migracion");
    var salud = cookies.get("salud");
    var educacion = cookies.get("educacion");
    var ocupacion = cookies.get("ocupacion");
    var desocupados = cookies.get("desocupados");
    var otras_actividades = cookies.get("otras_actividades");
    var otros_ingresos = cookies.get("otros_ingresos");
    var organismos = cookies.get("organismos");
    UpdateResidente(form_persona).then((res) => {
      if (res.data.message) {
        if (formulario) {
          if (formulario.identificacion_id == form_persona.identificacion_id) {
            // cookies.set("personas", cookies.get("vulnerabilidad"));
            const indexPersonas = personas.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexPersonas != -1) {
              personas[indexPersonas] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }
            const indexpersonas2 = personas2.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexpersonas2 != -1) {
              personas2[indexpersonas2] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }

            const indexvulnerabilidad = vulnerabilidad.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexvulnerabilidad != -1) {
              vulnerabilidad[indexvulnerabilidad] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }

            const indexmigracion = migracion.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexmigracion != -1) {
              migracion[indexmigracion] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }

            const indexsalud = salud.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexsalud != -1) {
              salud[indexsalud] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }

            const indexeducacion = educacion.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexeducacion != -1) {
              educacion[indexeducacion] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }

            const indexocupacion = ocupacion.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexocupacion != -1) {
              ocupacion[indexocupacion] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }

            const indexdesocupados = desocupados.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexdesocupados != -1) {
              desocupados[indexdesocupados] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }

            const indexotras_actividades = otras_actividades.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexotras_actividades != -1) {
              otras_actividades[indexotras_actividades] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }

            const indexotros_ingresos = otros_ingresos.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexotros_ingresos != -1) {
              otros_ingresos[indexotros_ingresos] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }

            const indexorganismos = organismos.findIndex(
              (element) => element.id == form_persona.n_orden
            );
            if (indexorganismos != -1) {
              organismos[indexorganismos] = {
                id: form_persona.n_orden,
                value: form_persona.nombre + " " + form_persona.parentesco,
                nombre: form_persona.nombre,
                parentesco: form_persona.parentesco,
                actividad: "",
                otra_actividad: 0,
                edad: getEdad(form_persona.fecha_nacimiento),
              };
            }

            cookies.remove("personas2");
            cookies.remove("vulnerabilidad");
            cookies.remove("migracion");
            cookies.remove("salud");
            cookies.remove("educacion");
            cookies.remove("ocupacion");
            cookies.remove("desocupados");
            cookies.remove("otras_actividades");
            cookies.remove("otros_ingresos");
            cookies.remove("organismos");
            cookies.set("personas", personas);
            cookies.set("personas2", personas2);
            cookies.set("vulnerabilidad", vulnerabilidad);
            cookies.set("migracion", migracion);
            cookies.set("salud", salud);
            cookies.set("educacion", educacion);
            cookies.set("ocupacion", ocupacion);
            cookies.set("desocupados", desocupados);
            cookies.set("otras_actividades", otras_actividades);
            cookies.set("otros_ingresos", otros_ingresos);
            cookies.set("organismos", organismos);
          }
        }
        MSJOK(res.data.message);
        setEdicion(false);
        setFormPersona({
          ...formulario,
          n_orden: personas ? personas.length : 0,
        });
        setCodigo(null);
        setNOrdenSearch(null);
      } else {
        MSJERROR(res.data.error);
      }
    });
  };

  const handleSwitch = (e) => {
    setEditBool(e);
    setCodigo(null);
    setEdicion(false);
    setNOrdenSearch(null);
    setFormPersona({ ...formulario, n_orden: personas ? personas.length : 0 });
  };

  const handleCodigo = (e) => {
    const { id, value } = e.target;
    setCodigo(value);
  };

  const handleOrden = (e) => {
    const { id, value } = e.target;
    setNOrdenSearch(value);
  };

  const handleSearch = () => {
    if (codigo && n_orden_search) {
      GetResidenteCodigo(codigo, n_orden_search).then((response) => {
        if (response[0]) {
          setFormPersona(response[0]);
          setEdicion(true);
        } else {
          setCodigo(null);
          setNOrdenSearch(null);
          setFormPersona(formulario);
          MSJERROR("No existe formulario con codigo: " + codigo);
        }
      });
    }
  };

  const handleClick = (e) => {
    const { name, value } = e.target;
    if (form_persona) {
      if (value == form_persona[name]) {
        setFormPersona({
          ...form_persona,
          [name]: "",
        });
      }
    }
  };

  const handleChange = (e) => {
    if (e.target) {
      const { name, value } = e.target;
      setFormPersona({
        ...form_persona,
        [name]: value,
      });
    }
  };
  return (
    <>
      <Row justify="center"></Row>

      <Row gutter={24} justify="center">
        <Card
          className={styles.cardDashboardgeneral}
          size="large"
          title={
            <>
              D.1. REGISTRO DE PERSONAS{"\n"}
              <>Desea editar?</>
              <Switch defaultChecked={editBool} onChange={handleSwitch} />
            </>
          }
        >
          {editBool ? (
            <div>
              <label>Buscar por: </label>
              <Input
                id="codigo"
                placeholder={"Codigo formulario"}
                onChange={handleCodigo}
                value={codigo ? codigo : ""}
                style={{ width: "20%" }}
              />
              <Input
                id="n_orden"
                placeholder={"# Orden"}
                onChange={handleOrden}
                value={n_orden_search ? n_orden_search : ""}
                style={{ width: "10%" }}
              />
              <Button
                type="primary"
                disabled={codigo ? false : true}
                shape="circle"
                onClick={handleSearch}
                icon={<SearchOutlined />}
              />
            </div>
          ) : null}
          <h1>
            ¿Cuáles son los nombres y apellidos de las personas que comen y
            duermen habitualmente en este hogar, RESIDENTES HABITUALES presentes
            o no?
          </h1>
          <Row justify="center">
            <Col span={10}>
              <Form layout="vertical" initialValues={{ remember: true }}>
                <Form.Item label="Numero de Orden">
                  <Input
                    style={{ width: "30%" }}
                    name="n_orden"
                    disabled={true}
                    onChange={handleChange}
                    value={personas ? personas.length : 0}
                  />
                </Form.Item>
                <Form.Item label="1. Nombres y apellidos">
                  <Input
                    name="nombre"
                    onChange={handleChange}
                    value={
                      form_persona
                        ? form_persona.nombre
                          ? form_persona.nombre
                          : ""
                        : ""
                    }
                  />
                </Form.Item>

                <Form.Item label="2. Tipo de Residente o Parentesco con jefe de hogar">
                  <select
                    className="ant-input"
                    name="parentesco"
                    onChange={handleChange}
                    placeholder="Seleccione una opción"
                    value={
                      form_persona
                        ? form_persona.parentesco
                          ? form_persona.parentesco
                          : ""
                        : ""
                    }
                  >
                    {parentescos.map((parentesco) => {
                      return <option value={parentesco}>{parentesco}</option>;
                    })}
                  </select>
                </Form.Item>
              </Form>
            </Col>
            <Col offset={2} span={12}>
              <Form>
                <Form.Item label="3. Estado Civil">
                  <select
                    className="ant-input"
                    name="estado_civil"
                    onChange={handleChange}
                    placeholder="Seleccione una opción"
                    value={
                      form_persona
                        ? form_persona.estado_civil
                          ? form_persona.estado_civil
                          : ""
                        : ""
                    }
                  >
                    {estado_civiles.map((value) => {
                      return <option value={value}>{value}</option>;
                    })}
                  </select>
                </Form.Item>
                <Form.Item label="4. Sexo">
                  <select
                    className="ant-input"
                    name="sexo"
                    onChange={handleChange}
                    placeholder="Seleccione una opción"
                    value={
                      form_persona
                        ? form_persona.sexo
                          ? form_persona.sexo
                          : ""
                        : ""
                    }
                  >
                    {sexos.map((sexo) => {
                      return <option value={sexo}>{sexo}</option>;
                    })}
                  </select>
                </Form.Item>
                <Form.Item>
                  <label>5. Fecha de nacimiento</label>
                  <br></br>
                  <input
                    className="ant-input"
                    style={{ width: "40%" }}
                    name="fecha_nacimiento"
                    onChange={handleChange}
                    type="date"
                    value={
                      form_persona
                        ? form_persona.fecha_nacimiento
                          ? form_persona.fecha_nacimiento
                          : ""
                        : ""
                    }
                  />
                  <label>
                    {" "}
                    Edad:{" "}
                    {form_persona
                      ? form_persona.fecha_nacimiento
                        ? getEdad(form_persona.fecha_nacimiento)
                        : 0
                      : 0}
                  </label>
                </Form.Item>
              </Form>
            </Col>

            {editBool ? (
              <>
                <Button
                  style={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? { color: "white", backgroundColor: "green" }
                        : {}
                      : {}
                  }
                  disabled={
                    typeof cookies.get("usuario") !== "undefined"
                      ? edicion
                        ? false
                        : true
                      : true
                  }
                  icon={<EditOutlined />}
                  size="large"
                  shape="round"
                  onClick={handleUpdate}
                >
                  Modificar
                </Button>
              </>
            ) : (
              <>
                <Button
                  style={
                    formulario
                      ? form_persona
                        ? form_persona.n_orden
                          ? { color: "white", backgroundColor: "green" }
                          : {}
                        : {}
                      : {}
                  }
                  icon={<PlusCircleOutlined />}
                  size="large"
                  shape="round"
                  disabled={
                    formulario
                      ? form_persona
                        ? form_persona.n_orden  && 'nombre' in form_persona && 'parentesco' in form_persona && 'estado_civil' in form_persona && 'sexo' in form_persona && 'fecha_nacimiento' in form_persona 
                          ? false
                          : true
                        : true
                      : true
                  }
                  onClick={() => handleSave()}
                >
                  Agregar
                </Button>
              </>
            )}
          </Row>
        </Card>
      </Row>
    </>
  );
};

export default Personas;
