import './App.css';
import {InitialDash} from './components/initialdashboard'

function App() {
  return (
    <InitialDash/>
  );
}

export default App;
